
// Item Images
import batImg from "../../../../img/tcg/items/bat.png";
import backpackImg from "../../../../img/tcg/items/backpack.png";
import batteryImg from "../../../../img/tcg/items/battery.png";
import blanketImg from "../../../../img/tcg/items/blanket.png";
import blenderImg from "../../../../img/tcg/items/blender.png";
import bowlImg from "../../../../img/tcg/items/bowl.png";
import brushImg from "../../../../img/tcg/items/brush.png";
import bulbImg from "../../../../img/tcg/items/bulb.png";
import canImg from "../../../../img/tcg/items/can.png";
import chairImg from "../../../../img/tcg/items/chair.png";
import chessImg from "../../../../img/tcg/items/chessboard.png";
import cubeImg from "../../../../img/tcg/items/cube.png";
import coffeeImg from "../../../../img/tcg/items/coffee.png";
import coffeeMugImg from "../../../../img/tcg/items/coffeemug.png";
import cameraImg from "../../../../img/tcg/items/camera.png";
import combImg from "../../../../img/tcg/items/comb.png";
import couchImg from "../../../../img/tcg/items/couch.png";
import dishwasherImg from "../../../../img/tcg/items/dishwasher.png";
import dollarImg from "../../../../img/tcg/items/dollar.png";
import duckImg from "../../../../img/tcg/items/duck.png";
import forkImg from "../../../../img/tcg/items/fork.png";
import fridgeImg from "../../../../img/tcg/items/fridge.png";
import lampImg from "../../../../img/tcg/items/lamp.png";
import microwaveImg from "../../../../img/tcg/items/microwave.png";
import mirrorImg from "../../../../img/tcg/items/mirror.png";
import mopImg from "../../../../img/tcg/items/mop.png";
import newspaper1 from "../../../../img/tcg/items/newspaper.png";
import newspaper3 from "../../../../img/tcg/items/newspaper3.png";
import ovenImg from "../../../../img/tcg/items/oven.png";
import paperTowelImg from "../../../../img/tcg/items/papertowel.png";
import pillowImg from "../../../../img/tcg/items/pillow.png";
import ragImg from "../../../../img/tcg/items/rag.png";
import spatulaImg from "../../../../img/tcg/items/spatula.png";
import spongeImg from "../../../../img/tcg/items/sponge.png";
import spoonImg from "../../../../img/tcg/items/spoon.png";
import shirtImg from "../../../../img/tcg/items/shirt.png";
import sockImg from "../../../../img/tcg/items/sock.png";
import sunglassesImg from "../../../../img/tcg/items/sunglasses.png";
import toasterImg from "../../../../img/tcg/items/toaster.png";
import toasterOvenImg from "../../../../img/tcg/items/toasteroven.png";
import toothbrushImg from "../../../../img/tcg/items/toothbrush.png";
import trashbagImg from "../../../../img/tcg/items/trashbag.png";
import tvImg from "../../../../img/tcg/items/tv.png";
import lightningImage from "../../../../img/tcg/items/lightning.png";
import pianoImg from "../../../../img/tcg/items/piano.png";
import watchImg from "../../../../img/tcg/items/watch.png";

import chickenImg from "../../../../img/tcg/items/chicken.png";
import vhsImage from "../../../../img/tcg/items/vhs.png";
import bigCameraImg from "../../../../img/tcg/items/bigcamera.png";
import tripod from "../../../../img/tcg/items/tripod.png";



//Heal images
import appleImg from "../../../../img/tcg/items/apple.png";
import berriesImg from "../../../../img/tcg/items/berries.png";
import burgerImg from "../../../../img/tcg/items/burger.png";
import pepperImg from "../../../../img/tcg/items/pepper.png";
import bananaImg from "../../../../img/tcg/items/banana.png";



//Sounds
import blanketSound from "../../../../sounds/tcg/items/blanket.mp3";
import bubbleHitSound from "../../../../sounds/tcg/items/bubbleHit.mp3";
import bongHitSound from "../../../../sounds/tcg/items/bongHit.mp3";
import clickHitSound from "../../../../sounds/tcg/items/tinCan.mp3";
import chickenSound from "../../../../sounds/tcg/items/chicken.mp3";
import cameraSound from "../../../../sounds/tcg/items/camera.mp3";
import crashPianoSound from "../../../../sounds/tcg/items/crashPiano.mp3";
import drumSound from "../../../../sounds/tcg/items/drum.mp3";
import trashCanSound from "../../../../sounds/tcg/items/trashCan.mp3";
import punchSound from "../../../../sounds/tcg/items/punch.mp3";
import paperSound from "../../../../sounds/tcg/items/paper.mp3";
import guitarRiffSound from "../../../../sounds/tcg/items/guitarRiff.mp3";
import moneySound from "../../../../sounds/tcg/items/money.mp3";
import healSound from "../../../../sounds/tcg/items/heal.mp3";
import smallKnockSound from "../../../../sounds/tcg/items/smallKnock.mp3";
import sockSound from "../../../../sounds/tcg/items/sock.mp3";
import vinnySpecialSound from "../../../../sounds/tcg/items/vinnySpecial.mp3";
import { Move } from "./TCGTypes";

export const ExtraMoves: Move[] = [
    {
        id: "mop",
        name: "Mop",
        strength: 21,
        imgSrc: mopImg,
        probability: 10,
        accuracy: 98,
        size: "med",
        type: "light",
        soundSrc: bubbleHitSound
    },
    {
        id: "comb",
        name: "Comb",
        strength: 20,
        imgSrc: combImg,
        probability: 15,
        accuracy: 99,
        size: "small",
        type: "light",
        soundSrc: smallKnockSound
    },
    {
        id: "toothbrush",
        name: "Toothbrush",
        strength: 20,
        imgSrc: toothbrushImg,
        probability: 15,
        accuracy: 99,
        size: "small",
        type: "light",
        soundSrc: bubbleHitSound
    },
    {
        id: "rag",
        name: "Rag",
        strength: 24,
        imgSrc: ragImg,
        probability: 20,
        accuracy: 98,
        size: "small",
        type: "light",
        soundSrc: blanketSound
    },
    {
        id: "brush",
        name: "Brush",
        strength: 22,
        imgSrc: brushImg,
        probability: 15,
        accuracy: 98,
        size: "small",
        type: "light",
        soundSrc: bubbleHitSound
    },
    {
        id: "lightbulb",
        name: "Lightbulb",
        strength: 10,
        imgSrc: bulbImg,
        probability: 15,
        accuracy: 98,
        size: "small",
        type: "light",
        soundSrc: punchSound
    },
    {
        id: "battery",
        name: "Battery",
        strength: 19,
        imgSrc: batteryImg,
        probability: 8,
        accuracy: 95,
        size: "small",
        type: "light",
        soundSrc: bubbleHitSound
    },
    {
        id: "lamp",
        name: "Lamp",
        strength: 23,
        imgSrc: lampImg,
        probability: 65,
        accuracy: 98,
        type: "light",
        size: "med",
        soundSrc: punchSound
    },
    {
        id: "toasterOven",
        name: "Oven",
        strength: 35,
        imgSrc: toasterOvenImg,
        probability: 55,
        accuracy: 60,
        size: "large",
        type: "heavy",
        soundSrc: trashCanSound
    },
    {
        id: "blender",
        name: "Blender",
        strength: 31,
        imgSrc: blenderImg,
        probability: 10,
        accuracy: 75,
        size: "large",
        type: "light",
        soundSrc: punchSound
    },
    {
        id: "can",
        name: "Can",
        strength: 35,
        imgSrc: canImg,
        probability: 120,
        accuracy: 60,
        size: "large",
        type: "heavy",
        soundSrc: trashCanSound
    },
    {
        id: "mirror",
        name: "Mirror",
        strength: 33,
        imgSrc: mirrorImg,
        probability: 50,
        accuracy: 63,
        size: "large",
        type: "heavy",
        soundSrc: bongHitSound
    },
    {
        id: "spatula",
        name: "Spatula",
        strength: 18,
        imgSrc: spatulaImg,
        probability: 25,
        accuracy: 97,
        size: "small",
        type: "light",
        soundSrc: smallKnockSound
    },
    {
        id: "sponge",
        name: "Sponge",
        strength: 10,
        imgSrc: spongeImg,
        probability: 15,
        accuracy: 97,
        size: "xs",
        type: "light",
        soundSrc: bubbleHitSound
    },
    {
        id: "bowl",
        name: "Bowl",
        strength: 28,
        imgSrc: bowlImg,
        probability: 15,
        accuracy: 90,
        size: "small",
        type: "light",
        soundSrc: smallKnockSound
    },
    {
        id: "blanket",
        name: "Blanket",
        strength: 20,
        imgSrc: blanketImg,
        probability: 90,
        accuracy: 98,
        size: "large",
        type: "heavy",
        soundSrc: blanketSound
    },
    {
        id: "paperTowel",
        name: "Paper Towel",
        strength: 21,
        imgSrc: paperTowelImg,
        probability: 15,
        accuracy: 98,
        size: "med",
        type: "light",
        soundSrc: blanketSound
    },
    {
        id: "coffeeMug",
        name: "Coffee Maker",
        strength: 24,
        imgSrc: coffeeImg,
        probability: 15,
        accuracy: 97,
        size: "small",
        type: "light",
        soundSrc: bubbleHitSound
    },
    {
        id: "spoon",
        name: "Spoon",
        strength: 10,
        imgSrc: spoonImg,
        probability: 20,
        accuracy: 98,
        size: "xs",
        type: "light",
        soundSrc: smallKnockSound
    },
    {
        id: "oven",
        name: "Double Oven",
        strength: 47,
        imgSrc: ovenImg,
        probability: 40,
        accuracy: 48,
        size: "xl",
        type: "heavy",
        soundSrc: trashCanSound
    },
    {
        id: "toaster",
        name: "Toaster",
        strength: 30,
        imgSrc: toasterImg,
        probability: 130,
        accuracy: 69,
        type: "heavy",
        size: "med",
        soundSrc: trashCanSound
    },
    {
        id: "dollar",
        name: "a Dollar",
        strength: 15,
        imgSrc: dollarImg,
        probability: 1,
        accuracy: 100,
        size: "small",
        type: "light",
        soundSrc: moneySound
    },
]


//GENERAL MOVES
export const LightMoves: Move[] = [
    {
        id: "sock",
        name: "the Smelly Sock",
        strength: 17,
        imgSrc: sockImg,
        probability: 3,
        accuracy: 100,
        size: "small",
        type: "light",
        soundSrc: sockSound
    },
]


export const HeavyMoves: Move[] = [
    {
        id: "trashbag",
        name: "a Flimsy Trashbag",
        strength: 21,
        imgSrc: trashbagImg,
        probability: 1,
        accuracy: 55,
        size: "small",
        type: "heavy",
        soundSrc: sockSound
    },
    {
        id: "chessboard",
        name: "the Chessboard",
        strength: 28,
        imgSrc: chessImg,
        probability: 5,
        accuracy: 85,
        size: "med",
        type: "heavy",
        soundSrc: clickHitSound
    },
    {
        id: "tv",
        name: "the TV",
        strength: 50,
        imgSrc: tvImg,
        probability: 3,
        accuracy: 60,
        size: "xl",
        type: "heavy",
        soundSrc: drumSound
    },
    {
        id: "couch",
        name: "the Couch",
        strength: 55,
        imgSrc: couchImg,
        probability: 2,
        accuracy: 66,
        size: "xxl",
        type: "heavy",
        soundSrc: drumSound
    },
    {
        id: "piano",
        name: "the Piano",
        strength: 55,
        imgSrc: pianoImg,
        probability: 1,
        accuracy: 55,
        size: "xxl",
        type: "heavy",
        soundSrc: crashPianoSound
    },
]



//VINNY MOVES
export const VinnyLightMoves: Move[] = [
    {
        id: "pillow",
        name: "his Sleepy Pillow",
        strength: 20,
        imgSrc: pillowImg,
        probability: 2,
        accuracy: 100,
        size: "med",
        type: "light",
        soundSrc: bongHitSound
    },
    {
        id: "fork",
        name: "a fork",
        strength: 25,
        imgSrc: forkImg,
        probability: 10,
        accuracy: 100,
        size: "xs",
        type: "light",
        soundSrc: smallKnockSound
    },
    {
        id: "watch",
        name: "his Smartwatch",
        strength: 26,
        imgSrc: watchImg,
        probability: 90,
        accuracy: 100,
        size: "xs",
        type: "light",
        soundSrc: clickHitSound
    },
    {
        id: "shirt",
        name: "his Shirt",
        strength: 25,
        imgSrc: shirtImg,
        probability: 150,
        accuracy: 100,
        size: "med",
        type: "light",
        soundSrc: blanketSound
    },

    {
        id: "cube",
        name: "his Puzzle Cube",
        strength: 27,
        imgSrc: cubeImg,
        probability: 60,
        accuracy: 100,
        size: "small",
        type: "light",
        soundSrc: clickHitSound
    },
    {
        id: "camera",
        name: "his Camera",
        strength: 28,
        imgSrc: cameraImg,
        probability: 90,
        accuracy: 100,
        size: "small",
        type: "light",
        soundSrc: cameraSound
    },
]


export const VinnyHeavyMoves: Move[] = [
    {
        id: "microwave",
        name: "a Microwave",
        strength: 33,
        imgSrc: microwaveImg,
        probability: 45,
        accuracy: 85,
        type: "heavy",
        size: "med",
        soundSrc: trashCanSound
    },
    {
        id: "famousMovie",
        name: "Famous Movie",
        strength: 36,
        imgSrc: vhsImage,
        probability: 110,
        accuracy: 85,
        size: "med",
        type: "heavy",
        soundSrc: bongHitSound
    },
    {
        id: "suitcase",
        name: "his Suitcase",
        strength: 38,
        imgSrc: backpackImg,
        probability: 30,
        accuracy: 80,
        size: "med",
        type: "heavy",
        soundSrc: clickHitSound
    },
    {
        id: "chair",
        name: "a Chair",
        strength: 40,
        imgSrc: chairImg,
        probability: 85,
        accuracy: 84,
        size: "xl",
        type: "heavy",
        soundSrc: trashCanSound
    },
    {
        id: "tripod",
        name: "his Tripod",
        strength: 44,
        imgSrc: tripod,
        probability: 30,
        accuracy: 73,
        size: "xl",
        type: "heavy",
        soundSrc: cameraSound
    },
    {
        id: "duck",
        name: "the Duck",
        strength: 50,
        imgSrc: duckImg,
        probability: 2,
        accuracy: 50,
        size: "small",
        type: "heavy",
        soundSrc: chickenSound
    },
];







//GRAM MOVES
export const GramLightMoves: Move[] = [
    {
        id: "sunglasses",
        name: "his Sunglasses",
        strength: 23,
        imgSrc: sunglassesImg,
        probability: 100,
        accuracy: 100,
        size: "xs",
        type: "light",
        soundSrc: punchSound
    },
    {
        id: "newspaper1",
        name: "his Newspaper",
        strength: 25,
        imgSrc: newspaper1,
        probability: 195,
        accuracy: 100,
        size: "med",
        type: "light",
        soundSrc: paperSound
    },
    {
        id: "coffeeMug",
        name: "his Coffee Cup",
        strength: 26,
        imgSrc: coffeeMugImg,
        probability: 90,
        accuracy: 100,
        size: "small",
        type: "light",
        soundSrc: clickHitSound
    },
    {
        id: "bat",
        name: "his Bat",
        strength: 27,
        imgSrc: batImg,
        probability: 100,
        accuracy: 100,
        size: "med",
        type: "light",
        soundSrc: clickHitSound
    },
]

export const GramHeavyMoves: Move[] = [
    {
        id: "famousDocument",
        name: "Famous Document",
        strength: 33,
        imgSrc: newspaper3,
        probability: 125,
        accuracy: 85,
        size: "large",
        type: "light",
        soundSrc: paperSound
    },
    {
        id: "bigbat",
        name: "the Big Bat",
        strength: 36,
        imgSrc: batImg,
        probability: 90,
        accuracy: 78,
        size: "xl",
        type: "light",
        soundSrc: trashCanSound
    },
    {
        id: "fridge",
        name: "the Fridge",
        strength: 40,
        imgSrc: fridgeImg,
        probability: 35,
        accuracy: 68,
        size: "xl",
        type: "heavy",
        soundSrc: trashCanSound
    },
    {
        id: "dishwasher",
        name: "the Dishwasher",
        strength: 42,
        imgSrc: dishwasherImg,
        probability: 15,
        accuracy: 60,
        size: "xl",
        type: "heavy",
        soundSrc: trashCanSound
    },
    {
        id: "chicken",
        name: "the Chicken",
        strength: 50,
        imgSrc: chickenImg,
        probability: 2,
        accuracy: 50,
        size: "small",
        type: "heavy",
        soundSrc: chickenSound
    },
];




export const gramSpecialMove = (): Move => {
    return {
        id: "electricguitar",
        name: "his Electric Guitar",
        strength: 30,
        imgSrc: lightningImage,
        probability: 0,
        accuracy: 100,
        size: "xl",
        type: "gramspecial",
        soundSrc: guitarRiffSound
    }
}

export const vinnySpecialMove = (): Move => {
    return {
        id: "morefunnyvideos",
        name: "More Funny Videos",
        strength: -99,
        imgSrc: bigCameraImg,
        probability: 0,
        accuracy: 100,
        size: "large",
        type: "vinnyspecial",
        soundSrc: vinnySpecialSound
    }
}

export const HealMoves: Move[] = [
    {
        id: "pepper",
        name: "a Hot Pepper",
        strength: 10,
        imgSrc: pepperImg,
        probability: 9,
        accuracy: 100,
        size: "small",
        type: "heal",
        soundSrc: sockSound
    },
    {
        id: "berries",
        name: "some Berries",
        strength: -25,
        imgSrc: berriesImg,
        probability: 35,
        accuracy: 100,
        size: "small",
        type: "heal",
        soundSrc: healSound
    },
    {
        id: "apple",
        name: "an Apple",
        strength: -30,
        imgSrc: appleImg,
        probability: 100,
        accuracy: 100,
        size: "xs",
        type: "heal",
        soundSrc: healSound
    },
    {
        id: "banana",
        name: "a Big Mike Banana",
        strength: -35,
        imgSrc: bananaImg,
        probability: 70,
        accuracy: 100,
        size: "med",
        type: "heal",
        soundSrc: healSound
    },
    {
        id: "cheeseburger",
        name: "a Juicy Cheeseburger",
        strength: -45,
        imgSrc: burgerImg,
        probability: 35,
        accuracy: 100,
        size: "med",
        type: "heal",
        soundSrc: healSound
    },
]