import { useContext } from "react";
import Button from "../../Small/Button";
import "../../Css/JumpGram.css";
import useSound from "use-sound";
import SelectBoop2 from "../../../sounds/selectBoop2.mp3";
import { VolumeContext } from "../../../Routes";

export interface SplashScreenProps {
  name: string;
  img: string;
  children?: any;
  startGame: () => void;
  themeSong?: HTMLAudioElement | undefined;
  midiPlayer?: any;
}


const SplashScreen = ({ name, img, children, startGame, themeSong = undefined, midiPlayer = undefined }: SplashScreenProps) => {
  const globalVolume = useContext(VolumeContext).globalVolume;
  const [playSelectBoop2] = useSound(SelectBoop2, { volume: globalVolume });

  const handleClickStart = () => {
    playSelectBoop2();

    setTimeout(() => {
      if (themeSong !== undefined) {
        themeSong.volume = globalVolume;
        themeSong.currentTime = 0;
        themeSong.loop = true;
        themeSong.play();
      }

      if (midiPlayer !== undefined) {
        midiPlayer.play();
      }
      startGame();
    }, 700);
  };

  return (
    <div>
      <h3 style={{ textAlign: "center" }} className="main-jump-header">
        {name}
      </h3>
      <div className="center-grid">
        <div className="help-container">
          <img
            className="help-img"
            src={img}
            onClick={() => handleClickStart()}
            alt="Start funny web game."
          ></img>
          {children}
          {/* <p>Rated E For Everyone</p> */}
          <div
            className="bottom-section-absolute"
            style={{ alignItems: "center" }}
          >
            <Button
              eventName={`${name} Start Button`}
              variant="primary"
              onClick={() => handleClickStart()}
              style={{
                fontSize: "2rem",
                minWidth: "170px",
                height: "60px",
              }}
            >
              Start
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
