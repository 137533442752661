import "../../Css/JumpGram.css";
import MFVLink from "../TCG/MFVLink";
import couchBg from "../../../img/coffeegram/bg.jpeg";
import NotFoundGram from "../../Small/NotFoundGram";

const NotFoundPage = () => {
  return (
    <div>
      <h3 style={{ textAlign: "center", color: "#f09368"}} className="main-jump-header">
        404 Not found!
      </h3>
      <div className="center-grid">
        <div className="drop-gram-game-container" style={{ height: "310px" }}>
          <div
              className={"jump-gram-background"}
              style={{
                background: `url(${couchBg})`,
                backgroundSize: "cover",
                height: "300px"
              }}
            ></div>
          <NotFoundGram></NotFoundGram>
        </div>
        <div
            className="bottom-section-absolute"
            style={{ alignItems: "center", color: "#f09368" }}
          >
            Gram won't let you do that! Try something else.
            <MFVLink></MFVLink>
          </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
