import MidiPlayer from "midi-player-js";

export const loadMidiFile = (url: string): Promise<Blob> => {
  return fetch(url, {
    headers: new Headers({
      Origin: window.location.origin,
    }),
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      return blob;
    })
    .catch((e) => {
      console.error(e);
      return new Blob([]);
    });
};

export const loadSoundFont = (url: string): Promise<any> => {
    return fetch(url, {
      headers: new Headers({
        Origin: window.location.origin,
      }),
      mode: "cors",
    })
    //   .then((response) => response.blob())
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((e) => {
        console.error(e);
        return "";
      });
  };

export const createPlayerFromUri = (dataUri: string, instrument: any, ac: AudioContext, noteLength: number): any => {
   const player = new MidiPlayer.Player(function (event: any) {
    if (event.name === "Note on" && event.velocity > 0) {
      const note = instrument.play(event.noteName, ac.currentTime, {
        gain: event.velocity / 50,
      });

      setTimeout(() => {
        note.stop();
      }, noteLength);
      console.log(event);
    }
  });

  player.loadDataUri(dataUri);
  return player;
};
