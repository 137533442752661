import { useState } from "react";
import "../../Css/JumpGram.css";
import introScreenBg from "../../../img/tictacgram/Cover.jpeg";
import GuessGramPage from "./GuessGramPage"
import SplashScreen from "../JumpGram/SplashScreen";

// const themeSong = new Audio(BGMusic);

const GuessGramLandingPage = () => {
  const [isGameStarted, setIsGameStarted] = useState<boolean>(false);

  return isGameStarted ? (
    <GuessGramPage></GuessGramPage>
  ) : (
    <SplashScreen
      name="Guess Gram"
      img={introScreenBg}
      startGame={() => setIsGameStarted(true)}
    >
      <p>- Gram will guess items from the pile</p>
      <p>- Agree with him or call him a liar!</p>
    </SplashScreen>
  );
};

export default GuessGramLandingPage;
