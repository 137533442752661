// import './App.css';
import JumpGramLandingPage from "./components/Games/JumpGram/JumpGramLandingPage";
import VideosPage from "./components/VideosPage";
import TCGLandingPage from "./components/Games/TCG/TCGLandingPage";
import StorePage from "./components/StorePage";
import GamesPage from "./components/GamesPage";
import {
  firstTimeLocalStorage,
  getRandomInt,
  globalVolumeLocalStorage,
  isShort,
  monkeyBackgroundStyle,
  setIsFirstTimeFalse,
} from "./utils/Utils";
import HighFive from "./components/HighFive";
import BottomNav from "./components/Small/BottomNav";
import { createContext, useEffect, useState } from "react";
import { Route, Video, VideoResults } from "./utils/Types";
import click from "./sounds/click.mp3";
import useSound from "use-sound";
import {
  DefaultGames,
  DefaultProducts,
  appUrl,
} from "./utils/Constants";
import { DefaultVideos } from "./utils/DefaultVideos";
import DropGramLandingPage from "./components/Games/DropGram/DropGramLandingPage";
import TicTacGramLandingPage from "./components/Games/TicTacGram/TicTacGramLandingPage";
import GuessGramLandingPage from "./components/Games/GuessGram/GuessGramLandingPage";
import CoffeeGramLandingPage from "./components/Games/CoffeeGram/CoffeeGramLandingPage";
import NotFoundPage from "./components/Games/JumpGram/NotFoundPage";
import DisableTrackingPage from "./components/Games/JumpGram/DisableTrackingPage";

interface VolumeContextType {
  globalVolume: number;
  setGlobalVolume: React.Dispatch<React.SetStateAction<number>> | undefined;
}

export const VolumeContext = createContext<VolumeContextType>({
  globalVolume: 0,
  setGlobalVolume: undefined,
});

const Routes = () => {
  const [currentPage, setCurrentPage] = useState<Route | string>("/videos");
  const [globalVolume, setGlobalVolume] = useState<number>(
    Number(globalVolumeLocalStorage)
  );
  const [playClick] = useSound(click, { volume: globalVolume });
  const [videos, setVideos] = useState<Video[]>([]);
  const [isLoadingVideos, setIsLoadingVideos] = useState<boolean>(true);
  const [currentVideo, setCurrentVideo] = useState<Video>(DefaultVideos[0]);
  const [remainingVideos, setRemainingVideos] = useState<Video[]>(videos);
  const [imageIsLoading, setImageIsLoading] = useState<boolean>(true);
  const [imageSrc, setImageSrc] = useState<HTMLImageElement>();

  useEffect(() => {
    localStorage.setItem("globalVolume", globalVolume.toString());
  }, [globalVolume]);

  useEffect(() => {
    const path = window.location.pathname;
    const urlPath = path;
    let urlSplit = urlPath.split("/");

    if (urlSplit.length >= 1) {
      const route = urlSplit[1];
      setCurrentPage("/" + route);
    } else {
      setCurrentPage(path);
    }

  }, []);

  useEffect(() => {
    const cacheStuff = async (srcArray: string[]): Promise<void> => {
      const promises = await srcArray.map((src: string) => {
        return new Promise<void>((resolve, reject) => {
          const img = new Image();
          img.src = src;

          img.onload = (event) => {
            const newImg = event.target as HTMLImageElement;
            setImageSrc(newImg);
            setTimeout(() => {
              setImageIsLoading(false);
            }, 200);
            return resolve();
          };
          img.onerror = () => reject();
        });
      });

      await Promise.all(promises);
    };

    if (currentVideo && !isLoadingVideos) {
      cacheStuff([`http://img.youtube.com/vi/${currentVideo.youtubeId}/maxresdefault.jpg`]);
    }
  }, [currentVideo, isLoadingVideos]);


  //FIRST LOAD EFFECT
  useEffect(() => {
    const getVideos = async () => {
      setIsLoadingVideos(true);
      try {
        const url = `${appUrl}/videos`;
        const results = await fetch(url);
        const resultsObject: VideoResults = await results.json();
        const resultsVideos = resultsObject.videos;

        if (resultsObject.error) {
          throw resultsObject.error;
        }

        if (!resultsObject.videos.length) {
          throw new Error("No videos");
        }
        setVideos(resultsVideos);

        let num = getRandomInt(resultsVideos.length);

        if (firstTimeLocalStorage === "true") {
          console.log(`${DefaultVideos[0].name} : ${DefaultVideos[0].youtubeId}`)
          setCurrentVideo(DefaultVideos[0]);
        } else {
          console.log(`${resultsVideos[num].name} : ${resultsVideos[num].youtubeId}`)
          setCurrentVideo(resultsVideos[num]);
        }
        setRemainingVideos(
          resultsVideos.filter(
            (video) => video.youtubeId !== resultsVideos[num].youtubeId
          )
        );
      } catch (e) {
        setVideos(DefaultVideos);
        setCurrentVideo(DefaultVideos[0]);
        setRemainingVideos(
          DefaultVideos.filter(
            (video) => video.youtubeId !== DefaultVideos[0].youtubeId
          )
        );
      } finally {
        setTimeout(() => setIsLoadingVideos(false), 110);
      }
    };

    getVideos();

    return () => {
      setIsLoadingVideos(false);
    };
  }, []);

  const handleWatchVideo = (video: Video) => {
    playClick();
    setCurrentVideo(video);
  };

  //THE ALGORITHM
  const handleNextVideo = () => {
    if (firstTimeLocalStorage === "true") {
      setIsFirstTimeFalse();
    }
    if (!videos.length) return;
    playClick();
    const videosWithoutCurrentId = remainingVideos.filter(
      (video) => video.youtubeId !== currentVideo.youtubeId
    );

    let newVideos = videosWithoutCurrentId;

    if (newVideos.length > 0) {

      //Never show the same two series in a row
      const keywords = currentVideo.name.split(" ");
      if (keywords.length) {
        const keyPhrases = keywords.filter((word) => word.toLowerCase() !== "gram");
        if (keyPhrases.length) {
          const keyPhrase = keyPhrases[0];

          const newVideosWithoutPhrase = newVideos.filter(
            (video) => !video.name.includes(keyPhrase)
          );

          if (newVideosWithoutPhrase.length) {
            newVideos = newVideosWithoutPhrase;
          }
        }
      }

      //If the current episode is a long episode, show a short next
      if (!isShort(currentVideo.length)) {
        console.log("its a long");
        const shortsOnly = newVideos.filter(
          (video) => isShort(video.length)
        );

        if (shortsOnly.length) {
          newVideos = shortsOnly;
        }
      }


      const randomNumber = getRandomInt(newVideos.length);
      const newVideo = newVideos[randomNumber];
      console.log(`${currentVideo.name} : ${currentVideo.youtubeId}`)
      setCurrentVideo(newVideo);
      setRemainingVideos(videosWithoutCurrentId);
    } else {
      setRemainingVideos(videos);
      const freshFilteredVideos = videos.filter(
        (video) => video.youtubeId !== currentVideo.youtubeId
      );
      const randomNumber = getRandomInt(freshFilteredVideos.length);
      setCurrentVideo(freshFilteredVideos[randomNumber]);
    }
  };

  const handleShowPage = (page: string) => {
    setCurrentPage(page);
  };

  const generatePage = () => {
    switch (currentPage) {
      // case "/umami":
      //   return <DisableTrackingPage></DisableTrackingPage>
      case "/games":
        return <GamesPage games={DefaultGames}></GamesPage>;
      case "/jumpgram":
        return <JumpGramLandingPage></JumpGramLandingPage>;
      case "/dropgram":
        return <DropGramLandingPage></DropGramLandingPage>;
      case "/tictacgram":
        return <TicTacGramLandingPage></TicTacGramLandingPage>;
      case "/guessgram":
        return <GuessGramLandingPage></GuessGramLandingPage>;
      case "/coffeegram":
        return <CoffeeGramLandingPage></CoffeeGramLandingPage>;
      case "/store":
        return <StorePage products={DefaultProducts}></StorePage>;
      case "/tcg":
        return <TCGLandingPage></TCGLandingPage>;
      case "/":
      case "/videos":
        return (
          <VideosPage
            imageIsLoading={imageIsLoading}
            isLoadingVideos={isLoadingVideos}
            videos={videos}
            videoUrl={currentVideo.youtubeId}
            handleWatchVideo={handleWatchVideo}
            lightLink={imageSrc}
          ></VideosPage>
        );
      default:
        return <NotFoundPage></NotFoundPage>;
    }
  };

  return (
    <div style={monkeyBackgroundStyle} className="mainBody">
      <VolumeContext.Provider
        value={{ globalVolume: globalVolume, setGlobalVolume }}
      >
        <div className="main-container">
          <div className="mainContentHeight">{generatePage()}</div>
          {currentPage !== "/tcg" && (
            <BottomNav
              page={currentPage}
              playNextVideo={() => handleNextVideo()}
              showPage={(page) => handleShowPage(page)}
            ></BottomNav>
          )}

          {(currentPage === "/videos" ||
            currentPage === "/" ||
            currentPage === "/games" ||
            currentPage === "/store") && <HighFive></HighFive>}
        </div>
      </VolumeContext.Provider>
    </div>
  );
};

export default Routes;
