import { useState } from "react";
import "../../Css/JumpGram.css";
import introScreenBg from "../../../img/dropgram/Cover.jpeg";
import CoffeeGramPage from "./CoffeeGramPage";
import SplashScreen from "../JumpGram/SplashScreen";
import BGMusic from "../../../sounds/dropgram/Theme.mp3";

const CoffeeGramLandingPage = () => {
  const [isGameStarted, setIsGameStarted] = useState<boolean>(false);
  const [themeSong] = useState<HTMLAudioElement>(BGMusic);

  return isGameStarted ? (
    <CoffeeGramPage themeSong={themeSong}></CoffeeGramPage>
  ) : (
    <SplashScreen
      name="Coffee Gram"
      img={introScreenBg}
      themeSong={themeSong}
      startGame={() => setIsGameStarted(true)}
    >
      <p>- Select your target</p>
      <p>- Prepare Monkey Roast!</p>
      <p>- Keep your friends alive.</p>
    </SplashScreen>
  );
};

export default CoffeeGramLandingPage;
