import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Routes from "./Routes";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

document.addEventListener("gesturestart", function (e) {
  e.preventDefault();
  // special hack to prevent zoom-to-tabs gesture in safari
});

document.addEventListener("gesturechange", function (e) {
  e.preventDefault();
  // special hack to prevent zoom-to-tabs gesture in safari
});

document.addEventListener("gestureend", function (e) {
  e.preventDefault();
  // special hack to prevent zoom-to-tabs gesture in safari
});

root.render(
    <Routes />
);
