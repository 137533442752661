export const GramPhrases = [
    // "Be nice!",
    // "I'm your friend Gram",
    "OH YEAH!!! Got more?",
    "Is that all?",
    "Sweet! Keep em' coming.",
    "Thanks! Got more?",
    "Thank you!",
    "That's groovy! Again?",
    "GREAT WORK! Keep it up!",
    "WOOOO!! Got more?",
    "I love fives!",
    "YEAH!! One more?",
    "Thanks! Again?",
    "Great! Got another?",
    "Rock on!! Any more?",
    "GREAT!! Got more?",
    "OH YEAHH!!! That's great.",
    "WOO! You're a champ!",
    "You got it, buddy.",
    "Thanks. I love this!",
    "Sweet! Another?",
    "WOO!! Do it again!",
    "YEAH! Keep at it.",
    "I felt that one!",
    "Keep it up!",
    "Love your style!",
    "Great! Again!",
    "YEAH!!! More!",
    "Thanks! Another?",
    "Woo! Some more?",
    "That's great! Got more?",
    "YEAH!!!! More!",
    "Thanks buddy!",
    "Let's do more.",
    "That's fun, right?!",
    "Watch some videos!",
    "Check out the games!",
    "Enjoy the site!",
    "Thanks! Keep at it!",
    "Woo! You're a pro at this.!",
    "Thanks! Fives are fun!",
    "That's the stuff!",
    "YEAH! I love those!",
    "Keep em' flowin!",
    "Great! Thanks.",
    "THANKS FOR THAT!",
    "WOO! Like the site?",
    "WOO! GOT MORE??!",
    "YEAHHH!!!!",
    "WOO! THAT'S FRESH.",
    "Cool vibes!",
    "Love the energy.",
    "YEAH! Watch videos!",
    "YEAH!!!!",
    "OH YEAH!!!",
    "WOOO!!!!!",
    "Thank you!",
    "AWESOME! That's great!",
    "OH YEAH! Keep that up.!",
    "Thanks for visiting!",
    "OH YEAH! I love fans.",
    "Woo! Thanks buddy.",
    "Sweet! Thank you!",
    "Thanks!",
    "Woo!!! Do more!",
    "OHHHHHH YEAH!!!!!",
    "Thanks! That's great.",
    "WOO!! Can we reach 100,000?",
    "How high can it go?",
    "YEAH!!! More!",
    "Woo! Love it!",
    "I like your style.",
    "Thanks! You are cool!",
    "Very nice!",
    "Keep up the good work.",
    "Hi friend!",
    "I like your style!",
    "Check out the store!",
    "Play some games!",
    "WOO! Enjoy free content!",
    "OH YEAH!!! THANKS!",
    "COOL!! That feels great!",
    "Love the effort!",
    "Thank you!",
    "Thanks!",
    "Great!",
    "Cool!",
    "Oh yeah!",
    "Woo! That's great!",
    "That's the stuff!",
    "All right!",
    "Not bad!",
    "OH YEAH!!! Love it.",
    "Cool stuff!",
    "I like you!",
    "You're my favorite fan!",
    "Great work!",
    "That's right!",
    "OH YEAH! I love that.",
    "Good stuff!",
    "Nice velocity",
    "Great!! Keep going!",
    "OH YEAH!! Great work.",
    "OH YEAH!! Watch videos!",
    "WOO! Enjoy the videos?",
];