import { useEffect, useState } from 'react';
import '../../../Css/ThrowDisplay.css';
import { SizeType } from './TCGTypes';
import { getRandomInt } from '../../../../utils/Utils';


export interface BlockType {
    id: string;
    x: number;
    y: number;
    horSpeed: number;
    vertSpeed: number;
    isVertical?: boolean;
    onDestroy: () => void;
    image: HTMLImageElement;
    size: SizeType;
    noRotate?: boolean;
}

const rotationSpeed = getRandomInt(16) + 10;

const BlockDisplay = ({ id, x, y, horSpeed, vertSpeed, image, size, onDestroy, noRotate = false }: BlockType) => {
  const [timeTick, setTimeTick] = useState<number>(0);
  const [angle, setAngle] = useState<number>(0);
  const [myX, setMyX] = useState<number>(x);
  const [myY, setMyY] = useState<number>(y);

  const calcSize = () => {
    switch (size) {
      case 'xs':
        return "90px";
      case 'small':
        return "120px";
      case 'med':
        return "155px";
      case 'large':
        return "190px";
      case 'xl':
        return "290px";
      case 'xxl':
        return "500px";
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setTimeTick(prev => prev + 1);

      if (myY < -10) {
        onDestroy();
      }

      if (timeTick > 10) {
        onDestroy();
      }

      setMyY(prev => prev - vertSpeed);
      setMyX(prev => prev - horSpeed);

      if (horSpeed > 0) {
        setAngle(prev => prev - rotationSpeed);
      } else {
        setAngle(prev => prev + rotationSpeed);
      }
    }, 80);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [horSpeed, vertSpeed, timeTick]);
  return (
      <div className='throwdisplay' style={{
          bottom: `${myY}%`, left: `${myX}%`
      }}>
        <img src={image.src} style={{ minHeight: calcSize(), transform: `rotate(${noRotate ? 0 : angle}deg)`, maxWidth: calcSize() }} className="gram-character-img" alt="Gram"></img>
      </div>
  );
}

export default BlockDisplay;
