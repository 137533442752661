import { useContext } from "react";
import "./Css/StorePage.css";
import useSound from "use-sound";
import { Game } from "../utils/Types";
import boop from "../sounds/Game_Select_Boop.mp3";
import { VolumeContext } from "../Routes";

interface GamesPageProps {
  readonly games: Game[];
}

const GamesPage = ({ games }: GamesPageProps) => {
  const globalVolume = useContext(VolumeContext).globalVolume;
  const [playGameBoop] = useSound(boop, { volume: globalVolume });

  const handlePlayGame = (game: Game) => {
    playGameBoop();

    setTimeout(() => {
      window.location.href = `${window.location.origin.toString()}/${game.url}`;
    }, 300);
  };

  return <div className="main-store-container">
    <div className="storeHeight">
      <div className="storeHeader">
        <h1>Game Corner</h1>
      </div>
      <div className="product-grid">
        {games.map((game: Game, i: number) => {
          return (
            <div className="product-container" key={`${game.id}-${i.toString()}`}>
            <img
                className="container-img"
                onClick={() => handlePlayGame(game)}
                alt={game.name}
                src={game.images[0]}
              ></img>
              <h1
                onClick={() => handlePlayGame(game)}
                className="product-name"
              >
                {game.name}
              </h1>
              <a
                data-umami-event="Games Page: Play Link"
                data-umami-name={game.name}
                className="purchase-link"
                href={game.url}
              >
                Play
              </a>
            </div>
          );
        })}
      </div>
    </div>
  </div>
};

export default GamesPage;
