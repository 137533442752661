import newsImg1 from "../img/news/1.jpeg"
import newsImg2 from "../img/news/2.jpeg"
import newsImg3 from "../img/news/3.jpeg"
import newsImg4 from "../img/news/4.jpeg"
import newsImg5 from "../img/news/5.jpeg"
import newsImg6 from "../img/news/6.jpeg"
import newsImg7 from "../img/news/7.jpeg"
import newsImg9 from "../img/news/9.jpeg"
import newsImg10 from "../img/news/10.jpeg"
import newsImg11 from "../img/news/11.jpeg"
import newsImg13 from "../img/news/13.jpeg"
import newsImg14 from "../img/news/14.jpeg"
import newsImg15 from "../img/news/15.jpeg"
import newsImg17 from "../img/news/17.jpeg"
import newsImg18 from "../img/news/18.jpeg"
import newsImg19 from "../img/news/19.jpeg"
import newsImg20 from "../img/news/20.jpeg"
import newsImg21 from "../img/news/21.jpeg"
import newsImg22 from "../img/news/22.jpeg"
import newsImg24 from "../img/news/24.jpeg"
import newsImg26 from "../img/news/26.jpeg"
import newsImg27 from "../img/news/27.jpeg"
import newsImg29 from "../img/news/29.jpeg"
import newsImg31 from "../img/news/31.jpeg"
import newsImg37 from "../img/news/37.jpeg"
import newsImg38 from "../img/news/38.jpeg"
import newsImg39 from "../img/news/39.jpeg"
import newsImg40 from "../img/news/40.jpeg"
import newsImg41 from "../img/news/41.jpeg"
import newsImg42 from "../img/news/42.jpeg"
import newsImg44 from "../img/news/44.jpeg"
import newsImg46 from "../img/news/46.jpeg"
import newsImg47 from "../img/news/47.jpeg"
import newsImg48 from "../img/news/48.jpeg"
import newsImg49 from "../img/news/49.jpeg"
import newsImg50 from "../img/news/50.jpeg"
import newsImg51 from "../img/news/51.jpeg"
import newsImg52 from "../img/news/52.jpeg"
import newsImg53 from "../img/news/53.jpeg"
import newsImg54 from "../img/news/54.jpeg"
import newsImg55 from "../img/news/55.jpeg"
import { Game, NewsArticle, Product } from "./Types"
import GramCard from "../img/store/gram_rounded.png";
import VinnyCard from "../img/store/vinny_rounded.png";
import Sticker from "../img/store/sticker.png";
import Combo from "../img/store/gram_combo_rounded.png";




import ChessGramThumbnail from "../img/store/free/wallpapers/desktop/ChessGram_Thumbnail.jpeg";
import CoffeeGramThumbnail from "../img/store/free/wallpapers/desktop/CoffeeGram_Thumbnail.jpeg";
import GoodnightGramThumbnail from "../img/store/free/wallpapers/desktop/GoodnightGram_Thumbnail.jpeg";
import GramBurgerThumbnail from "../img/store/free/wallpapers/desktop/GramBurger_Thumbnail.jpeg";
import GramsCradleThumbnail from "../img/store/free/wallpapers/desktop/GramsCradle_Thumbnail.jpeg";
import GramTVThumbnail from "../img/store/free/wallpapers/desktop/GramTV_Thumbnail.jpeg";
import PilotGramThumbnail from "../img/store/free/wallpapers/desktop/PilotGram_Thumbnail.jpeg";
import PizzaGramThumbnail from "../img/store/free/wallpapers/desktop/PizzaGram_Thumbnail.jpeg";
import TrashGramThumbnail from "../img/store/free/wallpapers/desktop/TrashGram_Thumbnail.jpeg";



import JumpGramBG from "../img/jumpgram/Cover.jpeg";
import JumpGramImg from "../img/jumpgram/Cover.jpeg";
import DropGramImg from "../img/dropgram/Cover.jpeg";
import TCGImage from "../img/tcg/Cover.jpeg";
import TicTacGramImage from "../img/tictacgram/Cover.jpeg"

export const PILOT_URL = "KzyT0KYpTOw";

export const newsImages = [
  newsImg1,
  newsImg2,
  newsImg3,
  newsImg4,
  newsImg5,
  newsImg6,
  newsImg7,
  newsImg9,
  newsImg10,
  newsImg11,
  newsImg13,
  newsImg14,
  newsImg15,
  newsImg17,
  newsImg18,
  newsImg19,
  newsImg20,
  newsImg21,
  newsImg22,
  newsImg24,
  newsImg26,
  newsImg27,
  newsImg29,
  newsImg31,
  newsImg37,
  newsImg38,
  newsImg39,
  newsImg40,
  newsImg41,
  newsImg42,
  newsImg44,
  newsImg46,
  newsImg47,
  newsImg48,
  newsImg49,
  newsImg50,
  newsImg51,
  newsImg52,
  newsImg53,
  newsImg54,
  newsImg55,
]

// export const appUrl = "https://web-production-6fe3.up.railway.app";
export const appUrl = "https://gram-super-server-production.up.railway.app";

export const NewsArticles: NewsArticle[] = [
  {
    title: "Gram Reads the News",
    subtitle: "Vinny has the sniffles!",
    img: newsImg1,
    date: "1994"
  },
  {
    title: "Gram Thinks up a storm",
    subtitle: "Plans new playroom design",
    img: newsImg2,
    date: "1996"
  },
  {
    title: "Vinny crashes the website!",
    subtitle: "Gram fixes it",
    img: newsImg27,
    date: "February 2024"
  },
  {
    title: "Gram Endorses Monkey Roast",
    subtitle: "Vinny prefers soda",
    img: newsImg3,
    date: "November 12, 2008"
  },
  {
    title: "Gram Sleeps Through the Game",
    subtitle: "Vinny's team loses",
    img: newsImg4,
    date: "2013"
  },
  {
    title: "Gram Gets Fur in his Coffee",
    subtitle: "He drinks it anyways",
    img: newsImg5,
    date: "1998"
  },
  {
    title: "Gram does a crossword puzzle",
    subtitle: "Forgets his buns in the oven...",
    img: newsImg9,
    date: "1988"
  },
  {
    title: "Gram takes a long nap",
    subtitle: "Vinny makes sure not to wake him.",
    img: newsImg10,
    date: "1984"
  },
  {
    title: "Gram Gets a new Grill",
    subtitle: "It's Burger Time!",
    img: newsImg14,
    date: "2001"
  },
  {
    title: "Gram: Legendary Model",
    subtitle: "Gram's award winning photoshoot",
    img: newsImg15,
    date: "2009"
  },
  {
    title: "Gram Walks on the Moon",
    subtitle: "Vinny flies out into space",
    img: newsImg15,
    date: "1851"
  },
  {
    title: "Gram Drinks a Monkeyshake",
    subtitle: "Vinny shakes things up...",
    img: newsImg17,
    date: "2002"
  },
  {
    title: "Vinny mixes it all up",
    subtitle: "Gram's in big trouble...",
    img: newsImg20,
    date: "1999"
  },
  {
    title: "Gram makes two cups of coffee",
    subtitle: "He doesn't share with Vinny",
    img: newsImg21,
    date: "2008"
  },
  {
    title: "Gram Enjoys Burger",
    subtitle: "Vinny tries to steal his soda",
    img: newsImg26,
    date: "2010"
  },
  {
    title: "The First Footstep",
    subtitle: "Gram checks out his new porch",
    img: newsImg29,
    date: "2015"
  },
  {
    title: "Gram on America",
    subtitle: "Gram earns an award.",
    img: newsImg29,
    date: "1968"
  },
  {
    title: "Gram in the big city",
    subtitle: "Vinny stays at home.",
    img: newsImg37,
    date: "1991"
  },
  {
    title: "Gram does sudoku puzzle",
    subtitle: "Vinny scratches his head.",
    img: newsImg39,
    date: "1998"
  },
  {
    title: "Gram makes public appearence",
    subtitle: "Autograph line wraps around the door.",
    img: newsImg41,
    date: "2007"
  },
  {
    title: "Gram eats at a diner",
    subtitle: "Vinny eats a pancake.",
    img: newsImg42,
    date: "2007"
  },
  {
    title: "Gram Treks Home",
    subtitle: "Vinny takes the bus...",
    img: newsImg44,
    date: "1999"
  },
  {
    title: "Gram Drinks Monkey Cola",
    subtitle: "Vinny realy wants some",
    img: newsImg46,
    date: "1993"
  },
  {
    title: "Gram makes massive burgers",
    subtitle: "Not enough for Vinny",
    img: newsImg47,
    date: "1997"
  },
  {
    title: "Gram relaxes in his chair",
    subtitle: "Vinny won't stop making dolphin noises.",
    img: newsImg48,
    date: "2018"
  },
  {
    title: "Gram learns to read",
    subtitle: "He loves the gorilla section",
    img: newsImg49,
    date: "1982"
  },
  {
    title: "Gram tries his new chair",
    subtitle: "Vinny misses the couch...",
    img: newsImg50,
    date: "2016"
  },
  {
    title: "Gram returns his new chair",
    subtitle: "Goes back to the old one",
    img: newsImg51,
    date: "2016"
  },
  {
    title: "Gram enters next plane of existance!",
    subtitle: "Vinny slips on banana peel...",
    img: newsImg52,
    date: "2023"
  },
  {
    title: "Gram takes out the trash",
    subtitle: "Vinny was in the bag...",
    img: newsImg53,
    date: "2010"
  },
  {
    title: "Gram finds inner peace",
    subtitle: "Vinny cleans up the pieces...",
    img: newsImg54,
    date: "2009"
  },
  {
    title: "Gram loses his cool",
    subtitle: "Vinny warms him up...",
    img: newsImg54,
    date: "2004"
  },
]

export const DefaultGames: Game[] = [
    {
      id: "jump-gram",
      releaseDate: new Date("7/1/2023"),
      name: "Jump Gram",
      description: "Tap to jump.",
      purchaseUrl: "",
      images: [JumpGramImg],
      url: "jumpgram",
    },
    {
      id: "tic-tac-gram",
      releaseDate: new Date("8/5/2023"),
      name: "Tic Tac Gram",
      description: "Tic Tac Toe Gram style!",
      purchaseUrl: "",
      images: [TicTacGramImage],
      url: "tictacgram",
    },
    {
      id: "drop-gram",
      releaseDate: new Date("8/2/2023"),
      name: "Drop Gram",
      description: "Dodge falling objects.",
      purchaseUrl: "",
      images: [DropGramImg],
      url: "dropgram",
    },
    {
      id: "gram-tcg",
      releaseDate: new Date("6/1/2023"),
      name: "Gram TCG",
      description:
        "The official holographic Gram Sticker mailed to you! QR code leads to more funny videos. 'Limited print'",
      purchaseUrl: "https://buy.stripe.com/dR63e99dQ3i9dUc8wz",
      images: [TCGImage],
      url: "tcg",
      disabled: true,
    },
  ];
  

export const DefaultProducts: Product[] = [
  {
    id: "gram-combo",
    releaseDate: new Date(Date.now()),
    name: "Gram Combo",
    description:
      "The offical Gram Combo pack mailed to you! Includes one Gram Trading Card, a Vinny Trading Card, and a holographic Gram Sticker! Enjoy playing as both characters in the Web Game. 'Limited print'",
    price: 1.99,
    productUrl: "https://buy.stripe.com/3cs9Cx9dQ1a15nGbIM",
    images: [Combo],
    tags: ["featured", "card"]
  },
  {
    id: "gram-sticker",
    releaseDate: new Date(Date.now()),
    name: "Gram Sticker",
    description:
      "The official holographic Gram Sticker mailed to you! QR code leads to more funny videos. 'Limited print'",
    price: 0.99,
    productUrl: "https://buy.stripe.com/dR63e99dQ3i9dUc8wz",
    images: [Sticker],
    tags: ["featured"]
  },
  {
    id: "gram-card",
    releaseDate: new Date(Date.now()),
    name: "Gram Trading Card",
    price: 0.99,
    images: [GramCard],
    productUrl: "https://buy.stripe.com/3cscOJblY3i9dUc145",
    description:
      "The official Gram Trading Card mailed to you! Card sleeve and QR code for Web Game included. 'Limited print.'",
    tags: ["featured", "card"]
  },
  {
    id: "vinny-card",
    releaseDate: new Date(Date.now()),
    name: "Vinny Trading Card",
    description:
      "The official Vinny Trading Card mailed to you! Card sleeve and QR code for Web Game included. 'Limited print.'",
    price: 0.99,
    productUrl: "https://buy.stripe.com/9AQ4id3TwdWNg2k7su",
    images: [VinnyCard],
    tags: ["featured", "card"]
  },
  {
    id: "chess-gram-bg",
    releaseDate: new Date(Date.now()),
    name: "Chess Gram BG",
    description:
      "1920 × 1080 Chess Gram background for all your favorite screens.",
    price: 0,
    productUrl: `${appUrl}/files/ChessGram.jpeg`,
    mobileProductUrl:`${appUrl}/files/ChessGramMobile.jpeg`,
    images: [ChessGramThumbnail],
    tags: ["free", "wallpaper"]
  },
  {
    id: "coffee-gram-bg",
    releaseDate: new Date(Date.now()),
    name: "Coffee Gram BG",
    description:
      "1920 × 1080 Coffee Gram background for all your favorite screens.",
    price: 0,
    productUrl: `${appUrl}/files/CoffeeGram.jpeg`,
    mobileProductUrl: `${appUrl}/files/CoffeeGramMobile.jpeg`,
    images: [CoffeeGramThumbnail],
    tags: ["free", "wallpaper"]
  },
  {
    id: "jump-gram-ringtone",
    releaseDate: new Date(Date.now()),
    name: "Jump Gram Ringtone",
    description:
      "Jump Gram theme song in high quality mp3 format.",
    price: 0,
    productUrl: `${appUrl}/files/JumpGramTheme_Ringtone.mp3`,
    images: [JumpGramBG],
    tags: ["free", "ringtone"]
  },
  {
    id: "pilot-gram-ringtone",
    releaseDate: new Date(Date.now()),
    name: "Pilot Gram Ringtone",
    description:
      "Musical selection from Pilot Gram in high quality mp3 format.",
    price: 0,
    productUrl: `${appUrl}/files/PilotGramRingtone.mp3`,
    images: [PilotGramThumbnail],
    tags: ["free", "ringtone"]
  },
  {
    id: "goodnight-gram-bg",
    releaseDate: new Date(Date.now()),
    name: "Goodnight Gram BG",
    description:
      "1920 × 1080 Goodnight Gram background for all your favorite screens.",
    price: 0,
    productUrl: `${appUrl}/files/GoodnightGram.jpeg`,
    mobileProductUrl: `${appUrl}/files/GoodnightGramMobile.jpeg`,
    images: [GoodnightGramThumbnail],
    tags: ["free", "wallpaper"]
  },
  {
    id: "gram-burger-bg",
    releaseDate: new Date(Date.now()),
    name: "Gram Burger BG",
    description:
      "1920 × 1080 Gram Burger background for all your favorite screens.",
    price: 0,
    productUrl: `${appUrl}/files/GramBurger.jpeg`,
    mobileProductUrl: `${appUrl}/files/GramBurgerMobile.jpeg`,
    images: [GramBurgerThumbnail],
    tags: ["free", "wallpaper"]
  },
  {
    id: "goodnight-gram-ringtone",
    releaseDate: new Date(Date.now()),
    name: "Goodnight Gram Ringtone",
    description:
      "Musical selection from Goodnight Gram in high quality mp3 format.",
    price: 0,
    productUrl: `${appUrl}/files/GoodnightGramRingtone.mp3`,
    images: [GoodnightGramThumbnail],
    tags: ["free", "ringtone"]
  },
  {
    id: "gram-burger-ringtone",
    releaseDate: new Date(Date.now()),
    name: "Gram Burger Ringtone",
    description:
      "Musical selection from Gram Burger in high quality mp3 format.",
    price: 0,
    productUrl: `${appUrl}/files/GramBurgerRingtone.mp3`,
    images: [GramBurgerThumbnail],
    tags: ["free", "ringtone"]
  },
  {
    id: "gram-tv-bg",
    releaseDate: new Date(Date.now()),
    name: "Gram TV BG",
    description:
      "1920 × 1080 Gram TV background for all your favorite screens.",
    price: 0,
    productUrl: `${appUrl}/files/GramTV.jpeg`,
    mobileProductUrl: `${appUrl}/files/GramTVMobile.jpeg`,
    images: [GramTVThumbnail],
    tags: ["free", "wallpaper"]
  },
  {
    id: "pilot-gram-bg",
    releaseDate: new Date(Date.now()),
    name: "Pilot Gram BG",
    description:
      "1920 × 1080 Pilot Gram background for all your favorite screens.",
    price: 0,
    productUrl: `${appUrl}/files/PilotGram.jpeg`,
    mobileProductUrl: `${appUrl}/files/PilotGramMobile.jpeg`,
    images: [PilotGramThumbnail],
    tags: ["free", "wallpaper"]
  },
  {
    id: "chess-gram-ringtone",
    releaseDate: new Date(Date.now()),
    name: "Chess Gram Ringtone",
    description:
      "Musical selection from Chess Gram in high quality mp3 format.",
    price: 0,
    productUrl: `${appUrl}/files/ChessGramRingtone.mp3`,
    images: [ChessGramThumbnail],
    tags: ["free", "ringtone"]
  },
  {
    id: "pizza-gram-bg",
    releaseDate: new Date(Date.now()),
    name: "Pizza Gram BG",
    description:
      "1920 × 1080 Pizza Gram background for all your favorite screens.",
    price: 0,
    productUrl: `${appUrl}/files/PizzaGram.jpeg`,
    mobileProductUrl: `${appUrl}/files/PizzaGramMobile.jpeg`,
    images: [PizzaGramThumbnail],
    tags: ["free", "wallpaper"]
  },
  {
    id: "trash-gram-bg",
    releaseDate: new Date(Date.now()),
    name: "Trash Gram BG",
    description:
      "1920 × 1080 Trash Gram background for all your favorite screens.",
    price: 0,
    productUrl: `${appUrl}/files/TrashGram.jpeg`,
    mobileProductUrl: `${appUrl}/files/TrashGramMobile.jpeg`,
    images: [TrashGramThumbnail],
    tags: ["free", "wallpaper"]
  },
  {
    id: "grams-cradle-bg",
    releaseDate: new Date(Date.now()),
    name: "Gram's Cradle BG",
    description:
      "1920 × 1080 Gram's Cradle background for all your favorite screens.",
    price: 0,
    productUrl: `${appUrl}/files/GramsCradle.jpeg`,
    mobileProductUrl: `${appUrl}/files/GramsCradleMobile.jpeg`,
    images: [GramsCradleThumbnail],
    tags: ["free", "wallpaper"]
  },
];


