import { useEffect, useState } from "react";
import "../../Css/JumpGram.css";
import introScreenBg from "../../../img/jumpgram/Cover.jpeg";
import JumpGramPage from "./JumpGramPage";
import SplashScreen from "./SplashScreen";
import midiFile from '../../../sounds/midi/jumpgram.mid';
import { instrument } from "soundfont-player";
import { loadMidiFile, createPlayerFromUri } from "../../../utils/MidiUtils";
import { blobToDataURL } from "../../../utils/Utils";

var ac = new AudioContext();

const JumpGramLandingPage = () => {
  const [isGameStarted, setIsGameStarted] = useState<boolean>(false);
  const [midiPlayer, setMidiPlayer] = useState<any>();

  useEffect(() => {
    loadMidiFile(midiFile).then((blob: Blob) => {
      blobToDataURL(blob, (dataUri: string) => {
        instrument(ac, 'lead_1_square').then((inst: any) => {
          const newMidiPlayer = createPlayerFromUri(dataUri, inst, ac, 140);
          setMidiPlayer(newMidiPlayer)
        });
      })
    });
  }, []);

  return isGameStarted ? (
    <JumpGramPage midiPlayer={midiPlayer}></JumpGramPage>
  ) : (
    <SplashScreen
      name="Jump Gram"
      img={introScreenBg}
      startGame={() => setIsGameStarted(true)}
      midiPlayer={midiPlayer}
    >
      <p>- Jump as many times as you can!!</p>
      <p>- Newspapers power you up</p>
    </SplashScreen>
  );
};

export default JumpGramLandingPage;
