import { useRef } from 'react';
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';
import papertexture from "../../img/papertextureSmall.png";
import '../Css/Modal.css';

interface ModalProps {
    onClose: () => void;
    isVisible: boolean;
    children?: any;
    top?: string;
}

const Modal = ({ top = "230px", onClose, isVisible, children }: ModalProps) => {
  const modalRef = useRef(null);
  useOutsideAlerter(modalRef, () => {
    if (isVisible) {
      onClose()
    }
  });

  return (
    <div className='modal-container' style={{
        top: top,
        backgroundImage: `url(${papertexture})`,
        backgroundRepeat: `round`,
        opacity: isVisible ? '1' : '0',
        pointerEvents: isVisible ? "auto" : "none",
    }} ref={modalRef}>
        <div className='modal-margin'>
          {isVisible && children} 
        </div>
    </div>
  );
}

export default Modal;
