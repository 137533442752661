/* eslint-disable @typescript-eslint/no-unused-vars */
import { LogVariant, StateString, Story } from "./TCGTypes";

//BGs
import bg3CouchBack from "../../../../img/tcg/backgrounds/bg3.jpeg"
import bgDayVhs from "../../../../img/tcg/backgrounds/bgday_vhs.jpeg"
import bg5GameCabinet from "../../../../img/tcg/backgrounds/bg5.jpeg"
import bg6CouchFront from "../../../../img/tcg/backgrounds/bg6.jpeg"
import bgOutsideTree from "../../../../img/tcg/backgrounds/bgOutsideTree.jpeg"

import bgRoof from "../../../../img/tcg/backgrounds/bgroof.jpeg"
import bg8Roof from "../../../../img/tcg/backgrounds/bg8_roof.jpeg"
import bgGrill from "../../../../img/tcg/backgrounds/bg9_grill.jpeg"
import bgTreehouse from "../../../../img/tcg/backgrounds/bg10_treehouse.jpeg"

import bgHouse from "../../../../img/tcg/backgrounds/bghouse.jpeg"
import bgHouseNight from "../../../../img/tcg/backgrounds/bghouse_night.jpeg"

// Story Backgrounds
import coffee1 from "../../../../img/tcg/stories/Coffee1.jpeg"
import coffee2 from "../../../../img/tcg/stories/Coffee2.jpeg"
import coffee3 from "../../../../img/tcg/stories/Coffee3.jpeg"

import goodnight1 from "../../../../img/tcg/stories/Goodnight1.jpeg"
import goodnight2 from "../../../../img/tcg/stories/Goodnight2.jpeg"
import goodnight4 from "../../../../img/tcg/stories/Goodnight4.jpeg"

import chess1 from "../../../../img/tcg/stories/Chess1.jpeg"
import chess3 from "../../../../img/tcg/stories/Chess3.jpeg"
import chess4 from "../../../../img/tcg/stories/Chess4.jpeg"

import cradle1 from "../../../../img/tcg/stories/Cradle1.jpeg"
import cradle2 from "../../../../img/tcg/stories/Cradle2.jpeg"
import cradle3 from "../../../../img/tcg/stories/Cradle3.jpeg"


import pizza1 from "../../../../img/tcg/stories/Pizza1.jpeg"
import pizza2 from "../../../../img/tcg/stories/Pizza2.jpeg"
import pizza4 from "../../../../img/tcg/stories/Pizza4.jpeg"


import trash1 from "../../../../img/tcg/stories/Trash1.jpeg"
import trash2 from "../../../../img/tcg/stories/Trash2.jpeg"
import trash3 from "../../../../img/tcg/stories/Trash3.jpeg"

import tv1 from "../../../../img/tcg/stories/TV1.jpeg"
import tv2 from "../../../../img/tcg/stories/TV2.jpeg"
import tv3 from "../../../../img/tcg/stories/TV3.jpeg"

export const LogStrings: LogVariant | any = {
    None: "None",
    Gram: "Gram",
    Vinny: "Vinny"
}

// ALL STATES IN TCG
export const StateStrings: StateString | any = {
    Idle: "idle",
    Throw: "throw",
    Hurt: "hurt",
    Lose: "lose",
    Win: "win",
    Special: "special",
    Heal: "heal"
}


export const Stories: Story[] = [
    {
        name: "Coffee Gram",
        frames: [
        {
            text: "Vinny is tired",
            img: coffee1
        },
        {
            text: "He drinks some coffee.",
            img: coffee2
        },
        {
            text: "Gram ruined the roast!!",
            img: coffee3
        }],
        backgrounds: [
            bg3CouchBack,
            bgDayVhs,
            bg5GameCabinet,
            bg6CouchFront,
        ],
    },
    {
        name: "Goodnight Gram",
        frames: [
        {
            text: "Gram is sleeping.",
            img: goodnight1
        },
        {
            text: "Vinny turns off his tv.",
            img: goodnight2
        },
        {
            text: "Gram is angry!",
            img: goodnight4
        }],
        backgrounds: [
            bgHouseNight,
            bgOutsideTree,
        ],
    },
    {
        name: "Chess Gram",
        frames: [
        {
            text: "Gram and Vinny are playing chess.",
            img: chess1
        },
        {
            text: "Gram makes a move.",
            img: chess3
        },
        {
            text: "Vinny calls him a cheater!",
            img: chess4
        }],
        backgrounds: [
            bg3CouchBack,
            bgDayVhs,
            bg5GameCabinet,
            bg6CouchFront,
        ],
    },
    {
        name: "Gram's Cradle",
        frames: [
        {
            text: "Vinny is watching the cradle.",
            img: cradle1
        },
        {
            text: "He is in a trance.",
            img: cradle2
        },
        {
            text: "Gram steals his dollar!",
            img: cradle3
        }],
        backgrounds: [
            bgDayVhs,
            bg5GameCabinet,
            bg6CouchFront,
        ],
    },
    {
        name: "Pizza Gram",
        frames: [
        {
            text: "Vinny is SO hungry.",
            img: pizza1
        },
        {
            text: "Gram orders a pizza.",
            img: pizza2
        },
        {
            text: "Gram eats it all!!!",
            img: pizza4
        }],
        backgrounds: [
            bg3CouchBack,
            bgDayVhs,
            bg5GameCabinet,
            bg6CouchFront,
        ],
    },
    {
        name: "Trash Gram",
        frames: [
        {
            text: "Gram is relaxing.",
            img: trash1
        },
        {
            text: "...Gram didn't do his chores....",
            img: trash2
        },
        {
            text: "Vinny is furious!!",
            img: trash3
        }],
        backgrounds: [
            bgGrill,
            bgHouse,
            bgRoof,
            bg8Roof
        ],
    },
    {
        name: "Tv Gram",
        frames: [
        {
            text: "Gram is relaxing.",
            img: tv1
        },
        {
            text: "Vinny is inside the TV",
            img: tv2
        },
        {
            text: "Gram throws it out the window!",
            img: tv3
        }],
        backgrounds: [
            bgHouse,
            bgTreehouse,
            bgRoof,
            bg8Roof
        ],
    },
]


export const Backgrounds = [
    bg3CouchBack,
    bgDayVhs,
    bg5GameCabinet,
    bg6CouchFront,
    bgGrill,
    bgHouse,
    bgTreehouse,
    bgRoof,
]

export const EndPhrases = [
    'and he started crying.',
    'and he gave up.',
    'and he sighs in defeat',
    'and he goes back to watching TV',
    'and he wipes the tears out of his eyes.',
    'and he concedes the fight.',
    'and he admits he is wrong.',
    'and he says sorry.',
    'and he leaves the playroom',
    'and sweat came out of his ears',
    'and he took a break from games'
  ]

export const CRIT_MULTIPLIER = 1.312

export const GRAM_CRIT_MULTIPLIER = 1.5;

export const ATTACK_WEAKENER = 10;

export const FamousDocuments = [
    "The Magna Carta",
    "The Declaration of Gramdependance",
    "The Gramstitution",
    "The Treaty of Gram",
    "The Code of Grammurabi",
    "The Gram Times",
    "The Great Gramsby",
    "Gram Quixote",
    "The Grams of Wrath"
]

export const FamousMovies = [
    '"Citizen Gram"',
    '"The Gramfather"',
    '"Gram-Hur"',
    '"Gramblanca"',
    '"Grammin in the Rain"',
    '"Gramfellas"',
    '"Gramplane!"',
    '"Silence of the Grams"',
    '"Wizard of Gram"'
]