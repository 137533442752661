import { useCallback, useContext, useEffect, useState } from "react";
import "./Css/StorePage.css";
import useSound from "use-sound";
import click from "../sounds/click.mp3";
import { Product } from "../utils/Types";
import { downloadResource } from "../utils/Utils";
import { VolumeContext } from "../Routes";
import StoreDetails from "./StoreDetails";
import NotFoundPage from "./Games/JumpGram/NotFoundPage";

type FilterType = "featured" | "wallpaper" | "all" | "ringtone" | "free";

interface StorePageProps {
  readonly products: Product[];
}

// const urlPath = window.location.pathname;
// // let params = new URLSearchParams(search);
// console.log(urlPath);
// let urlSplit = urlPath.split("/");

// if (urlSplit.length === 3) {
//   let apiRoute = urlSplit[1] ?? "";
//   console.log(apiRoute);

//   let apiSubRoute = "";

//   if (urlSplit.length > 2) {
//     apiSubRoute = urlSplit[2];
//     console.log(apiSubRoute);
//   }
// }

let path = window.location.pathname;
// console.log("PATH: " + window.location.pathname);

// console.log("EFFECT ON PATH: " + window.location.pathname)

const urlPath = path;
let urlSplit = urlPath.split("/");

let STARTING_ROUTE = "/";

if (urlSplit.length === 3) {
  let apiSubRoute = "";

  if (urlSplit.length > 2) {
    apiSubRoute = urlSplit[2];
    STARTING_ROUTE = apiSubRoute;
  }
}

const StorePage = ({ products }: StorePageProps) => {
  const globalVolume = useContext(VolumeContext).globalVolume;
  const [filter, setFilter] = useState<FilterType>("featured");
  const [currentPage, setCurrentPage] = useState<string>(STARTING_ROUTE);
  const [playClick] = useSound(click, { volume: globalVolume });
  // const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();
  const handleSetFilter = (value: FilterType) => {
    playClick();
    setFilter(value);
  };

  const filteredProducts = useCallback(() => {
    if (filter === "all") return products;
    return products.filter((product) => {
      return product.tags.includes(filter);
    });
  }, [products, filter]);

  const handleShowDetails = (product: Product) => {
    playClick();
    window.history.pushState(
      { urlPath: `/store/${product.id}` },
      "",
      `/store/${product.id}`
    );
    setCurrentPage(product.id);
  };

  const handleCloseDetails = () => {
    playClick();
    window.history.pushState(
      { urlPath: `/store` },
      "",
      `/store`
    );
    setCurrentPage("/");
  }

  const generateShopPage = () => {
    return (
      <div className="main-store-container">
        <div className="storeHeight">
          <div className="storeHeader">
            <h1>Gram Store</h1>
          </div>
          <div className="store-filter">
            <div className="filter-toptions-container">
              <p
                className={
                  filter === "featured"
                    ? "filter-link-highlighted"
                    : "filter-link"
                }
                onClick={() => handleSetFilter("featured")}
              >
                Featured
              </p>
              <p
                className={
                  filter === "free" ? "filter-link-highlighted" : "filter-link"
                }
                onClick={() => handleSetFilter("free")}
              >
                Free Stuff
              </p>
            </div>
          </div>
          <div className="product-grid">
            {filteredProducts().map((product: Product, i: number) => {
              return (
                <div className="product-container" key={`${product.id}-${i.toString()}`}>
                  <img
                    className={
                      product.tags.includes("ringtone")
                        ? "round-img"
                        : "container-img"
                    }
                    onClick={() => handleShowDetails(product)}
                    alt={product.name}
                    src={product.images[0]}
                  ></img>
                  <h1
                    onClick={() => handleShowDetails(product)}
                    className="product-name"
                  >
                    {product.name}
                  </h1>
                  <h2
                    onClick={() => handleShowDetails(product)}
                    className="product-price"
                  >
                    {product.price === 0 ? "" : `$${product.price.toString()}`}
                  </h2>
                  {product.price === 0 ? (
                    <p
                      style={{ marginBottom: "18px" }}
                      className="purchase-link"
                      data-umami-event="Store: Free Download Link"
                      data-umami-name={product.name}
                      onClick={() =>
                        handleStartDownload(product.productUrl, product.id)
                      }
                    >
                      Free Download
                    </p>
                  ) : (
                    <a
                      style={{ marginBottom: "18px" }}
                      className="purchase-link"
                      href={product.productUrl}
                      data-umami-event="Store: Purchase Link"
                      data-umami-name={product.name}
                    >
                      Purchase
                    </a>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const handleStartDownload = (
    downloadUrl: string | undefined,
    filename: string
  ) => {
    if (!downloadUrl) return;
    downloadResource(downloadUrl, filename);
    playClick();
  };

  const generatePage = () => {
    if (currentPage === "/") {
      return generateShopPage();
    } else {
      const selectedProduct = products.find((product) => {
        return product.id === currentPage;
      });

      if (!selectedProduct) return <NotFoundPage></NotFoundPage>

      return (
        <StoreDetails
          product={selectedProduct}
          onClose={() => handleCloseDetails()}
          startDownload={handleStartDownload}
        ></StoreDetails>
      );
    }
  };
  return generatePage();
};

export default StorePage;
