import couchImg from "../../../img/jumpgram/Couch_v2.png";
import suitCaseImg from "../../../img/tcg/items/backpack.png";
import toasterImg from "../../../img/tcg/items/toaster.png";
import dishwasherImg from "../../../img/tcg/items/dishwasher.png";
import chairImg from "../../../img/tcg/items/chair.png";
import newsImg from "../../../img/tcg/items/newspaper.png";
import { HighScore } from "../../../utils/Types";

export interface WindowBlockData {
    name: string;
    imgSrc: string;
    speed: number;
    topOffset: number;
    leftBounds: number;
    rightBounds: number;
    topBounds: number;
    bottomBounds: number;
    type: string;
}


export const FLOOR_HEIGHT = 272;
export const CEILING_HEIGHT = 0;
export const LEFT_BORDER = 0;
export const RIGHT_BORDER = 250;

export const GRAM_LEFT = 148;
export const JUMP_HEIGHT = -125;
export const SUPER_JUMP_HEIGHT = -165;
export const JUMP_LENGTH = 485;
export const SUPER_JUMP_LENGTH = 510;
export const JUMP_AGAIN_TIME = 108;
export const SUPER_JUMP_AGAIN_TIME = 217;
export const BLOCK_X = 212;
export const MIN_TICK_TIME = 40;
export const MAX_OBJECT_SPEED = 93;
export const NEWSPAPER_GAP_TIME = 70;
export const STARTING_TIME_LAG = 4;
export const TIME_DIVISOR = 1000;
export const STARTING_NEWSPAPERS = 0;
export const STARTING_NAME = "YOU";
export const STARTING_SCORE = 0;
export const LATE_NEWS_CHANCE = 81;
export const EARLY_NEWS_CHANCE = 39;
export const FALLING_SPEED = 24;

//Smaller number is more left
// export const COLLIDE_VALUE = -615;
export const GRAM_MOVE_SPEED = 50;

export const DefaultHighScores: HighScore[] = [
    {
        name: "SHWAN",
        value: 66,
        time: new Date(),
        gameLength: 100,
        id: `SHWAN-${new Date().toTimeString()}-${6}`
    },
    {
        name: "DOG",
        value: 1,
        time: new Date(),
        gameLength: 100,
        id: `DOG-${new Date().toTimeString()}-${12}`
    },
]


export const WindowGramBlocks: { [key: string]: WindowBlockData } = {
    "dishwasher": {
        name: "Dishwasher",
        imgSrc: dishwasherImg,
        speed: FALLING_SPEED,
        topOffset: 58,
        leftBounds: 118,
        rightBounds: 73,
        topBounds: 90,
        bottomBounds: 90,
        type: 'block'
    },
    "chair": {
        name: "Chair",
        imgSrc: chairImg,
        speed: FALLING_SPEED,
        topOffset: 58,
        leftBounds: 118,
        rightBounds: 73,
        topBounds: 90,
        bottomBounds: 90,
        type: 'block'
    },
    "suitcase": {
        name: "Suitcase",
        imgSrc: suitCaseImg,
        speed: FALLING_SPEED,
        topOffset: 58,
        leftBounds: 118,
        rightBounds: 73,
        topBounds: 90,
        bottomBounds: 90,
        type: 'block'
    },
    "toaster": {
        name: "Toaster",
        imgSrc: toasterImg,
        speed: FALLING_SPEED,
        topOffset: 58,
        leftBounds: 118,
        rightBounds: 73,
        topBounds: 90,
        bottomBounds: 90,
        type: 'block'
    },
    "couch": {
        name: "Couch",
        imgSrc: couchImg,
        speed: FALLING_SPEED,
        topOffset: 58,
        leftBounds: 118,
        rightBounds: 73,
        topBounds: 90,
        bottomBounds: 90,
        type: 'block'
    },
}

export const WindowGramPowerUps: { [key: string]: WindowBlockData } = {
    "newspaper": {
        name: "Newspaper Piece",
        imgSrc: newsImg,
        speed: FALLING_SPEED,
        topOffset: -115,
        leftBounds: 120,
        rightBounds: 160,
        topBounds: 100,
        bottomBounds: 100,
        type: 'powerup'
    },
}
