import "../Css/Newspaper.css";
import paperImg from "../../img/news.png";

interface NewspaperProps {
  onClick: () => void;
}

const Newspaper = (props: NewspaperProps) => {
  const handleClickNewspaper = () => {
    props.onClick();
  };

  return (
    <div className="newspaper-container">
      <div className={"newspaper"}>
        <img
          data-umami-event="Newspaper Button"
          src={paperImg}
          alt="New funny videos and games."
          onClick={() => handleClickNewspaper()}
        ></img>
      </div>
    </div>
  );
};

export default Newspaper;
