/* eslint-disable */
import { useEffect, useState } from "react";
import "./CoffeeGramBlock.css";
import { VinnyGramType } from "../../../utils/Types";

export interface CoffeeGramBlockType {
  id: string;
  x: number;
  y: number;
  speed: number;
  onDestroy: () => void;
  image: string;
  updateBlockLocation: (
    x: number,
    y: number,
    type: string,
    target: VinnyGramType,
    damage: number
  ) => void;
  type: string;
  target: VinnyGramType;
  damage: number;
}

const CoffeeGramBlock = ({
  id,
  x,
  y,
  speed,
  image,
  onDestroy,
  updateBlockLocation,
  type,
  target,
  damage
}: CoffeeGramBlockType) => {
  const [timeTick, setTimeTick] = useState<number>(0);
  const [myY, setMyY] = useState<number>(y);
  const [myX, setMyX] = useState<number>(x);
  const [timers, setTimers] = useState<NodeJS.Timeout[]>([]);
  const [isHidden, setIsHidden] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setTimeTick((prev) => prev + 1);

      if (timeTick > 60) {
        setIsHidden(true);
        return;
      } else {
        setTimeTick((prev) => prev + 1);
        updateBlockLocation(myX, myY + speed, type, target, damage);
        setMyY((prev) => prev + speed);
      }
    }, 100);
  }, [speed, timeTick]);

  return (
    <div
      className="coffee-block"
      key={id}
      style={{
        left: `${x}px`,
        top: `${myY}px`,
      }}
    >
      {!isHidden && (
        <img
          src={image}
          className="gram-character-img"
          alt="Gram"
          style={{
            filter: "drop-shadow(1px 4px 1px rgba(0, 0, 0, 0.282))",
          }}
        ></img>
      )}
    </div>
  );
};

export default CoffeeGramBlock;
