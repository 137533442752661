import "../../Css/tcg/MFV.css";

const MFVLink = () => {
  return (
    <div className="more-funny-videos-link">
      {/* <p className="more-funny-videos">Check out</p> */}
      {/* <p className="game-corner-link">
        <a href="/games">game corner</a>
      </p> */}
      {/* <p className="more-funny-videos">and</p> */}
      <p>
        <a data-umami-event="More Funny Videos Link" href="/">
          more funny videos!
        </a>
      </p>
    </div>
  );
};

export default MFVLink;
