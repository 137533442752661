/* eslint-disable */
import { VinnyGramType } from "../../../utils/Types";
import RisingText from "../TCG/utils/RisingText";
import { StateString } from "../TCG/utils/TCGTypes";
import { calcGramImg, calcVinnyImg } from "../TCG/utils/TCGUtils";
import "./CoffeeGramCharacter.css";

import { useEffect, useState } from "react";

interface DropGramProps {
  x: number;
  y: number;
  ref?: React.RefObject<HTMLDivElement>;
  state: StateString;
  isFacingRight: boolean;
  isStandingStill: boolean;
  playerName: VinnyGramType;
  isSelected: boolean;
  onClick: () => void;
  risingText?: string | undefined;
  risingIcon?: string | undefined;
}

const CoffeeGramCharacter = ({
  x,
  y,
  ref,
  state,
  isFacingRight,
  isStandingStill,
  playerName,
  isSelected,
  risingText,
  risingIcon,
  onClick
}: DropGramProps) => {
  const [animationIndex, setAnimationIndex] = useState<number>(0);
  const [timeTick, setTimeTick] = useState<number>(0);
  const [timers, setTimers] = useState<NodeJS.Timeout[]>([]);

  useEffect(() => {
    // if (isActive) {
    setTimers([
      ...timers,
      setTimeout(() => {
        setTimeTick((prev) => prev + 1);

        if (!isStandingStill) {
          setAnimationIndex((prev) => prev + 1);
        }
      }, 180),
    ]);

    // }
  }, [timeTick]);

  const clearTimers = () => {
    timers.forEach((timer) => {
      clearTimeout(timer);
    });
  };

  useEffect(() => {
    setAnimationIndex(0);
  }, [state]);

  const calcPlayerImg = (
    name: string,
    state: StateString,
    animationIndex: number
  ) => {
    switch (name) {
      case "Gram":
        return calcGramImg(state, animationIndex);
      case "Vinny":
        return calcVinnyImg(state, animationIndex);
      default:
        return calcVinnyImg(state, animationIndex);
    }
  };

  return (
    <div
      className="coffee-gram-character"
      onClick={onClick}
      ref={ref}
      style={{
        top: `${y}px`,
        left: `${x}px`,
      }}
    >
      <RisingText value={risingText}></RisingText>
      <RisingText value={risingIcon} isIcon></RisingText>
      <img
        height="125px"
        src={calcPlayerImg(playerName, state, animationIndex)}
        className={isSelected ? "coffee-gram-character-selected" : `coffee-gram-character-img`}
        alt="Gram"
      ></img>
    </div>
  );
};

export default CoffeeGramCharacter;
