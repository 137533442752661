import { useContext, useEffect, useState } from "react";
import "./Css/VideosPage.css";
import useSound from "use-sound";
import click from "../sounds/click.mp3";
import paper from "../sounds/paper.mp3";
import {
  getNewsArticle, sortByReleaseDate,
} from "../utils/Utils";
import { Game, NewsArticle, Video } from "../utils/Types";
import Modal from "./Small/Modal";
import Newspaper from "./Small/Newspaper";
import PlayButtonImg from "../img/playButton.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import boop from "../sounds/GAMES_BOOP.mp3";
import { DefaultGames } from "../utils/Constants";
import ReactPlayer from "react-player";
import { VolumeContext } from "../Routes";
import VolumeControl from "./VolumeControl";
import LoadingBanana from "./Small/LoadingBanana";

const defaultArticle = getNewsArticle();

interface VideoPageProps {
  readonly videos: Video[];
  readonly isLoadingVideos: boolean;
  readonly videoUrl: string;
  readonly handleWatchVideo: (video: Video) => void;
  readonly imageIsLoading: boolean;
  readonly lightLink: HTMLImageElement | undefined;
}

const VideosPage = ({ videos, isLoadingVideos, videoUrl, handleWatchVideo, imageIsLoading, lightLink }: VideoPageProps) => {
  const globalVolume = useContext(VolumeContext).globalVolume;
  const setGlobalVolume = useContext(VolumeContext).setGlobalVolume;
  const [isShowingVideosModal, setIsShowingVideosModal] =
    useState<boolean>(false);
  const [playClick] = useSound(click, { volume: globalVolume });
  const [playNews] = useSound(paper, { volume: globalVolume });
  const [playGamesBoop] = useSound(boop, { volume: (globalVolume / 2) });
  const [newsArticle, setNewsArticle] = useState<NewsArticle>(defaultArticle);

  const handleWatchVideoWrapper = (video: Video) => {
    setIsShowingVideosModal(false);
    handleWatchVideo(video);
  }

  useEffect(() => {
    var monitor = setInterval(function () {
      var elem = document.activeElement;
      if (elem && elem.tagName === "IFRAME") {
        clearInterval(monitor);
        setIsShowingVideosModal(false);
      }
    }, 100);
  }, [isShowingVideosModal]);

  videos.sort(function (a: Video, b: Video) {
    const aReleaseDate = a.releaseDate ?? new Date();
    const bReleaseDate = b.releaseDate ?? new Date();

    return new Date(bReleaseDate).getTime() - new Date(aReleaseDate).getTime();
  });

  const newsVideos = videos.slice(0, 99);

  const handleGoToUrl = (url: string) => {
    if (url === "store") {
      playClick();
    } else if (url === "games") {
      playClick();
    } else {
      playClick();
    }

    setTimeout(() => {
      window.location.href = `${window.location.origin.toString()}/${url}`;
    }, 410);
  };

  const handleCloseNews = () => {
    setIsShowingVideosModal(false);
    setNewsArticle(getNewsArticle());
  };

  const generateNewsModal = () => {
    return (
      <Modal isVisible={isShowingVideosModal} onClose={() => handleCloseNews()}>
        <div className="news-row">
          <h1 className="news-header">
            {newsArticle.title}
            <h1 className="news-sub-header">{newsArticle.subtitle}</h1>
          </h1>
          <FontAwesomeIcon
            className="news-close"
            icon={faX}
            onClick={() => handleCloseNews()}
          />
        </div>
        <hr></hr>

        <div className="news-picture-row">
          <div className="left-side-grid">
            <img className="gram-img" src={newsArticle.img} alt="Watch funny videos!"></img>
            <p className="cursive-gram">Gram {newsArticle.date}</p>
            <div className="games-scrollbox">
              <h1 className="little-news-header">FUN GAMES</h1>
              {DefaultGames.filter((game) => !game.hidden).sort(sortByReleaseDate).map((game: Game, index: number) => {
                return (
                  <div key={`game-released-${game.id}-${index}`}>
                    <h2
                      className="video-link"
                      onClick={() => handleGoToUrl(game.url)}
                    >
                      {game.name}
                    </h2>
                    <p className="release-text">
                      {game.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="videos-scrollbox">
            <h1 className="little-news-header">NEW VIDEOS</h1>
            {newsVideos.map((video) => {
              return (
                <div key={`news-video-${video.youtubeId}`}>
                  <h2
                    className="video-link"
                    onClick={() => handleWatchVideoWrapper(video)}
                  >
                    {video.name}
                  </h2>
                  <p className="release-text">
                    {video.releaseDate
                      ? new Date(video.releaseDate).toLocaleDateString()
                      : ""}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    );
  };

  const playButton = () => {
    if (imageIsLoading) return;
    return <img className="play-button-img" alt="Start watching funny videos now!" src={PlayButtonImg} />;
  };

  const handleMute = () => {
    if (!setGlobalVolume) return;
    if (globalVolume === 0) {
      setGlobalVolume(0.24);
    } else {
      setGlobalVolume(0);
    }
  };

  const generateLight = () => {
    return lightLink ? lightLink.src : <LoadingBanana></LoadingBanana>;
  }

  return (
    <div style={{ height: "100%", userSelect: "none" }}>
      {!isLoadingVideos && videos.length > 4 && generateNewsModal()}
      <ReactPlayer
        width={"100%"}
        controls={true}
        playing={true}
        autoPlay={true}
        light={generateLight()}
        muted={globalVolume === 0}
        playIcon={playButton()}
        height={"100%"}
        url={`https://www.youtube.com/embed/${videoUrl}?autoplay=1showinfo=0&modestbranding=1`}
      />
      <VolumeControl topRight onClick={() => handleMute()}></VolumeControl>
      <Newspaper
        onClick={() => {
          setIsShowingVideosModal(true);
          playNews();
        }}
      ></Newspaper>
    </div>
  );
};

export default VideosPage;
