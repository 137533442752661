import '../Css/LoadingBanana.css';
import banana from "../../img/bananaSmall.png";
import { monkeyBackgroundStyle } from '../../utils/Utils';


const LoadingBanana = () => {
  return (
    <div className='loading-container' style={monkeyBackgroundStyle}>
      <img className='loading-banana' src={banana} alt="Loading funny videos and games!"></img>
      <header title="FUNNY VIDEOS AND GAMES" className='loading-text'>Loading</header>
    </div>
  );
}

export default LoadingBanana;
