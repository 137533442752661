import { VinnyGramUndefinedType } from "../../../utils/Types";

export interface GameCell {
    state: VinnyGramUndefinedType;
    name: string;
    index: number;
    isLitUp: boolean;
}

export const DefaultGameCells: GameCell[] = [
        {
            name: "1A",
            state: undefined,
            index: 0,
            isLitUp: false,
        },
        {
            name: "1B",
            state: undefined,
            index: 1,
            isLitUp: false,
        },
        {
            name: "1C",
            state: undefined,
            index: 2,
            isLitUp: false,
        },
        {
            name: "2A",
            state: undefined,
            index: 3,
            isLitUp: false,
        },
        {
            name: "2C",
            state: undefined,
            index: 4,
            isLitUp: false,
        },
        {
            name: "2C",
            state: undefined,
            index: 5,
            isLitUp: false,
        },
        {
            name: "3A",
            state: undefined,
            index: 6,
            isLitUp: false,
        },
        {
            name: "3B",
            state: undefined,
            index: 7,
            isLitUp: false,
        },
        {
            name: "3C",
            state: undefined,
            index: 8,
            isLitUp: false,
        }
]
