import { useEffect, useState } from "react";
import "./DropGramBlock.css";

export interface DropGramBlockType {
  id: string;
  x: number;
  y: number;
  speed: number;
  onDestroy: () => void;
  image: string;
  updateBlockLocation: (
    x: number,
    y: number,
    leftBounds: number,
    rightBounds: number,
    type: string
  ) => void;
  leftBounds: number;
  rightBounds: number;
  topBounds: number;
  bottomBounds: number;
  type: string;
}

const DropGramBlock = ({
  id,
  x,
  y,
  leftBounds,
  rightBounds,
  speed,
  image,
  updateBlockLocation,
  type,
}: DropGramBlockType) => {
  const [timeTick, setTimeTick] = useState<number>(0);
  const [myY, setMyY] = useState<number>(y);
  const [isHidden, setIsHidden] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setTimeTick((prev) => prev + 1);

      if (timeTick > 60) {
        setIsHidden(true);
        return;
      } else {
        setTimeTick((prev) => prev + 1);
        updateBlockLocation(x, myY + speed, leftBounds, rightBounds, type);
        setMyY((prev) => prev + speed);
      }
    }, 100);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speed, timeTick]);

  return (
    <div
      className="window-block"
      key={id}
      style={{
        left: `${x}px`,
        top: `${myY}px`,
      }}
    >
      {!isHidden && (
        <img
          src={image}
          className="gram-character-img"
          alt="Gram"
          style={{
            filter: "drop-shadow(1px 4px 1px rgba(0, 0, 0, 0.282))",
          }}
        ></img>
      )}
    </div>
  );
};

export default DropGramBlock;
