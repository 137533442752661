import { GameCell } from "../components/Games/TicTacGram/TicTacGramConstants";
import backgroundImg from "../img/backgroundSmall.jpg";
import { NewsArticles } from "./Constants";
import { VinnyGramUndefinedType } from "./Types";

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const devMode = localStorage["devMode"] ?? "false";

export const isOnVideosPage = (page: string) => {
  return page === "/videos" || page === "/" ? true : false;
};

export const isShort = (length: number) => {
  return length < 30 ? true : false;
};

export const firstTimeLocalStorage = localStorage["firstTime"] ?? "true";

export const setIsFirstTimeFalse = () => {
  localStorage.setItem("firstTime", "false");
};

export const globalVolumeLocalStorage = localStorage["globalVolume"] ?? "0.24";

export const trackingLocalStorage = localStorage["umami.disabled"] ?? "false";

export const firstTimeLocalStorageTCG = localStorage["firstTimeTcg"] ?? "true";

// export const volumeLocalStorage = localStorage['volume'] ?? "0.5";
export const volumeLocalStorage = 1.0;

export const skipStoriesLocalStorage = localStorage["skipStories"] ?? "false";

export const accessTokenLocalStorage = localStorage["accessToken"] ?? "";

export const monkeyBackgroundStyle: React.CSSProperties = {
  backgroundSize: "cover",
  background: `linear-gradient(
  rgba(0, 0, 0, 0.0), 
  rgba(0, 0, 0, 0.1)
), url(${backgroundImg})`,
};

export const GLOBAL_TIME_TICK = 300;

export const getRowBackgroundColor = (index: number) => {
  return index % 2 !== 0 ? "rgba(232, 228, 47, 0)" : "rgb(43, 146, 255, 0.215)";
};

export const calcBgImage = (bgImage: string) => {
  return `linear-gradient(
    rgba(0, 0, 0, 0.1), 
    rgba(0, 0, 0, 0.7)
  ), url(${bgImage})`;
};

export const goHome = () => (document.location.href = "/");

export const sortByValue = (a: any, b: any) => {
  if (a.value < b.value) {
    return 1;
  }
  if (a.value > b.value) {
    return -1;
  }
  return 0;
};

export const sortByName = (a: any, b: any) => {
  var collator = new Intl.Collator([], {
    caseFirst: "upper",
    numeric: true,
    sensitivity: "variant",
  });
  return collator.compare(a.name, b.name);
};

export const sortByReleaseDate = (a: any, b: any) => {
  if (a.releaseDate < b.releaseDate) {
    return 1;
  }
  if (a.releaseDate > b.releaseDate) {
    return -1;
  }
  return 0;
};

export const getNewsArticle = () => {
  const rand = getRandomInt(NewsArticles.length);
  return NewsArticles[rand];
};

const forceDownload = (blob: any, filename: string) => {
  var a = document.createElement("a");
  a.download = filename;
  a.href = blob;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  a.remove();
};

// Current blob size limit is around 500MB for browsers
export const downloadResource = (url: string, filename: string) => {
  fetch(url, {
    headers: new Headers({
      Origin: window.location.origin,
    }),
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
    })
    .catch((e) => console.error(e));
};


export const lighUpCells = (
  stateToCheck: VinnyGramUndefinedType,
  cells: GameCell[]
): GameCell[] => {
  const newCells = coolClone(cells);
  if (
    newCells[0].state === stateToCheck &&
    newCells[1].state === stateToCheck &&
    newCells[2].state === stateToCheck
  ) {
    newCells[0].isLitUp = true;
    newCells[1].isLitUp = true;
    newCells[2].isLitUp = true;
  }

  if (
    newCells[3].state === stateToCheck &&
    newCells[4].state === stateToCheck &&
    newCells[5].state === stateToCheck
  ) {
    newCells[3].isLitUp = true;
    newCells[4].isLitUp = true;
    newCells[5].isLitUp = true;
  }

  if (
    newCells[6].state === stateToCheck &&
    newCells[7].state === stateToCheck &&
    newCells[8].state === stateToCheck
  ) {
    newCells[6].isLitUp = true;
    newCells[7].isLitUp = true;
    newCells[8].isLitUp = true;
  }

  //0 1 2
  //3 4 5
  //6 7 8
  //Columns
  if (
    newCells[0].state === stateToCheck &&
    newCells[3].state === stateToCheck &&
    newCells[6].state === stateToCheck
  ) {
    newCells[0].isLitUp = true;
    newCells[3].isLitUp = true;
    newCells[6].isLitUp = true;
  }

  if (
    newCells[1].state === stateToCheck &&
    newCells[4].state === stateToCheck &&
    newCells[7].state === stateToCheck
  ) {
    newCells[1].isLitUp = true;
    newCells[4].isLitUp = true;
    newCells[7].isLitUp = true;
  }

  if (
    newCells[2].state === stateToCheck &&
    newCells[5].state === stateToCheck &&
    newCells[8].state === stateToCheck
  ) {
    newCells[2].isLitUp = true;
    newCells[5].isLitUp = true;
    newCells[8].isLitUp = true;
  }

  //Diagonals
  if (
    newCells[0].state === stateToCheck &&
    newCells[4].state === stateToCheck &&
    newCells[8].state === stateToCheck
  ) {
    newCells[0].isLitUp = true;
    newCells[4].isLitUp = true;
    newCells[8].isLitUp = true;
  }
  if (
    newCells[2].state === stateToCheck &&
    newCells[4].state === stateToCheck &&
    newCells[6].state === stateToCheck
  ) {
    newCells[2].isLitUp = true;
    newCells[4].isLitUp = true;
    newCells[6].isLitUp = true;
  }
  return newCells;
};

export const checkTicTacGramWin = (
  stateToCheck: VinnyGramUndefinedType,
  cells: GameCell[]
) => {
  //0 1 2
  //3 4 5
  //6 7 8

  //All rows
  if (
    cells[0].state === stateToCheck &&
    cells[1].state === stateToCheck &&
    cells[2].state === stateToCheck
  ) {
    return true;
  }

  if (
    cells[3].state === stateToCheck &&
    cells[4].state === stateToCheck &&
    cells[5].state === stateToCheck
  ) {
    return true;
  }

  if (
    cells[6].state === stateToCheck &&
    cells[7].state === stateToCheck &&
    cells[8].state === stateToCheck
  ) {
    return true;
  }

  //0 1 2
  //3 4 5
  //6 7 8
  //Columns
  if (
    cells[0].state === stateToCheck &&
    cells[3].state === stateToCheck &&
    cells[6].state === stateToCheck
  ) {
    return true;
  }

  if (
    cells[1].state === stateToCheck &&
    cells[4].state === stateToCheck &&
    cells[7].state === stateToCheck
  ) {
    return true;
  }

  if (
    cells[2].state === stateToCheck &&
    cells[5].state === stateToCheck &&
    cells[8].state === stateToCheck
  ) {
    return true;
  }

  //Diagonals
  if (
    cells[0].state === stateToCheck &&
    cells[4].state === stateToCheck &&
    cells[8].state === stateToCheck
  ) {
    return true;
  }
  if (
    cells[2].state === stateToCheck &&
    cells[4].state === stateToCheck &&
    cells[6].state === stateToCheck
  ) {
    return true;
  }
};

export const coolClone = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

// export const cacheImages = async (srcArray: string[]): Promise<void> => {
//   const promises = await srcArray.map((src: string) => {
//     return new Promise<void>((resolve, reject) => {
//       const img = new Image();
//       img.src = src;

//       img.onload = () => resolve();
//       img.onerror = () => reject();
//     })
//   })

//   await Promise.all(promises);
// }

//**dataURL to blob**
// function dataURLtoBlob(dataurl: string) {
//   var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
//       bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
//   while(n--){
//       u8arr[n] = bstr.charCodeAt(n);
//   }
//   return new Blob([u8arr], {type:mime});
// }

//**blob to dataURL**
export const blobToDataURL = (blob: any, callback: Function) => {
  var a = new FileReader();
  a.onload = function(e: any) {callback(e.target.result);}
  a.readAsDataURL(blob);
}