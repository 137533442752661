import { useEffect, useState } from "react";
import "./JumpBlock.css";

export interface JumpBlockType {
  id: string;
  x: number;
  y: number;
  speed: number;
  onDestroy: () => void;
  image: string;
  updateX: (
    x: number,
    leftBounds: number,
    rightBounds: number,
    type: string
  ) => void;
  leftBounds: number;
  rightBounds: number;
  type: string;
  // size: SizeType;
}

const JumpBlock = ({
  id,
  x,
  y,
  leftBounds,
  rightBounds,
  speed,
  image,
  onDestroy,
  updateX,
  type,
}: JumpBlockType) => {
  const [timeTick, setTimeTick] = useState<number>(0);
  const [myX, setMyX] = useState<number>(x);
  const [isHidden, setIsHidden] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setTimeTick((prev) => prev + 1);

      if (timeTick > 40) {
        setIsHidden(true);
        return;
      } else {
        setTimeTick((prev) => prev + 1);
        updateX(myX - speed, leftBounds, rightBounds, type);
        setMyX((prev) => prev - speed);
      }
    }, 100);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speed, timeTick]);

  return (
    <div
      className="block"
      style={{
        top: `${y}px`,
        left: `${myX + 210}px`,
      }}
    >
      {!isHidden && (
        <img
          src={image}
          className="gram-character-img"
          alt="Gram"
          style={{
            filter: "drop-shadow(1px 4px 1px rgba(0, 0, 0, 0.282))",
          }}
        ></img>
      )}
    </div>
  );
};

export default JumpBlock;
