import { useContext, useState } from "react";
import { Story } from "./utils/TCGTypes";
import Button from "../../Small/Button";
import "../../Css/StoryDisplay.css";
import styled from "styled-components";
import useSound from "use-sound";
import clickSound from "../../../sounds/click.mp3";
import note2 from "../../../sounds/tcg/stories/note2.mp3";
import note3 from "../../../sounds/tcg/stories/note3.mp3";
import note4 from "../../../sounds/tcg/stories/note4.mp3";
import { VolumeContext } from "../../../Routes";

export interface StoryDisplayProps {
  story: Story;
  images: HTMLImageElement[];
  onFinish: () => void;
}

interface StyledDivProps {
  images: HTMLImageElement[];
}

const StoryDisplay = ({ story, images, onFinish }: StoryDisplayProps) => {
  const globalVolume = useContext(VolumeContext).globalVolume;
  const [progress, setProgress] = useState<number>(0);
  const [playClick] = useSound(clickSound, { volume: globalVolume });
  const [playNote2] = useSound(note2, { volume: globalVolume });
  const [playNote3] = useSound(note3, { volume: globalVolume });
  const [playNote4] = useSound(note4, { volume: globalVolume });

  const StyleDiv = styled.div`
    display: grid;
    height: 100%;
    overflow: hidden;
    position: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    justify-self: center;
    max-width: 760px;
    grid-template-rows: 70% 20px 50px;
    background-position: center;
    background-size: 100% 100%;
    background-image: url(${(props: StyledDivProps) =>
      props.images ? props.images[progress].src : ""});
  `;

  const calcSound = () => {
    switch (progress) {
      case 0:
        playNote2();
        return;
      case 1:
        playNote3();
        return;
      case 2:
        playNote3();
        return;
    }
  };

  const handleNextFrame = () => {
    playClick();
    if (progress === story.frames.length - 1) {
      playNote4();
      onFinish();
    } else {
      calcSound();
      setProgress((prev) => prev + 1);
    }
  };

  const handleSkip = () => {
    playClick();
    playNote4();
    onFinish();
  };

  return (
    <div style={{ display: "grid", gridTemplateRows: "2fr 10fr" }}>
      <StyleDiv images={images}>
        <div></div>
        <h1 className="giant-text">{story.frames[progress].text}</h1>
        <div
          className="story-bottom-display"
        >
          <Button
            eventName={"Gram TCG Story Next Button"}
            variant="primary"
            style={{ padding: "12px 96px", fontSize: '1.5rem', boxShadow: '6px 4px 12px black' }}
            onClick={() => handleNextFrame()}
          >
            Next
          </Button>
        </div>
      </StyleDiv>
    </div>
  );
};

export default StoryDisplay;
