import { useEffect, useRef } from 'react';
import '../../../Css/LogContainer.css';
import { Log } from './TCGTypes';
import { LogStrings } from './TCGConstants';

export interface LogContainerProps {
    log: Log[];
}

const LogContainer = ({ log }: LogContainerProps) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView();
          }
    }, [log])


    const getStyle = (logMessage: Log) => {
      let color = "white";
      let fontFamily = "Arial"

      switch (logMessage.variant) {
        case LogStrings.Vinny:
          color = "lightblue";
          fontFamily = 'sans-serif';
          break;
        case LogStrings.Gram:
          color = "#DFC523";
          fontFamily = 'Lucida Grande';
          break;
        default:
          color = "white";
          fontFamily = "'Jumpman', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif"
          break;
      }



      return {
        color,
        fontFamily,
        maxWidth: '500px',
        fontWeight: 500,
        alignText: 'center',
        padding: '4px',
        zIndex: 3,
        opacity: 1
      }
    }


    const generateLogMessage = (logMessage: Log, i: number) => {
      return <h1 
        key={`${i.toString()}-log-message`}
        style={getStyle(logMessage)}
        className='log-message'>
          {logMessage.value}
      </h1>
    }

  return (
    <div className='log-container'>
        {log.map((logMessage: Log, i: number) => {
          return generateLogMessage(logMessage, i)
        })}
        <div className='log-chaser' ref={ref}></div>
    </div>
  );
}

export default LogContainer;
