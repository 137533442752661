import "./Css/StorePage.css";
import { Product } from "../utils/Types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

interface StoreDetailsProps {
  readonly product: Product;
  readonly onClose: () => void;
  readonly startDownload: (downloadUrl: string | undefined, filename: string) => void;
}

const StoreDetails = ({ product, onClose, startDownload }: StoreDetailsProps) => {
  return (
    <div className="main-store-container">
      <div className="storeHeight">
        <div className="storeHeader">
          <h1>{product.name}</h1>
          <div className="cartButtonContainer">
            <FontAwesomeIcon
              className="video-row-icon-delete"
              style={{ color: "white", fontSize: "1.3rem", marginTop: "1px", alignSelf: "center" }}
              icon={faX}
              onClick={() => onClose()}
            />
          </div>
        </div>
        <div className="product-details-container">
          <img alt={product.name} src={product.images[0]}></img>
          <h2 className="product-description">{`${product.description}`}</h2>
          {product.price === 0 ? (
            <p
              className="purchase-link"
              onClick={() =>
                startDownload(product.productUrl, product.id)
              }
              data-umami-event="Store Details Page: Free Download Link"
              data-umami-name={product.name}
            >
              Free Download
            </p>
          ) : (
            <a
              className="purchase-link"
              href={product.productUrl}
              data-umami-event="Store Details Page: Purchase Link"
              data-umami-name={product.name}
            >
              Purchase - ${product.price.toString()}
            </a>
          )}
          {product.mobileProductUrl?.length && (
            <p
              className="purchase-link"
              onClick={() =>
                startDownload(product.mobileProductUrl, product.id)
              }
              data-umami-event="Store Details Page: Mobile Download Link"
              data-umami-name={product.name}
            >
              Free Mobile Version
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoreDetails;
