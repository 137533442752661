/* eslint-disable react-hooks/exhaustive-deps */
import { gramLoseImages } from "../Games/TCG/utils/TCGUtils";
import "../Games/JumpGram/GramCharacter.css";

import { useEffect, useState } from "react";
import { getRandomInt } from "../../utils/Utils";

const NotFoundGram = () => {
  const [animationIndex, setAnimationIndex] = useState<number>(0);
  const [timeTick, setTimeTick] = useState<number>(0);
  const [timers, setTimers] = useState<NodeJS.Timeout[]>([]);

  useEffect(() => {
    const randTime = getRandomInt(200);

    setTimers([
      ...timers,
      setTimeout(() => {
        setTimeTick((prev) => prev + 1);
        setAnimationIndex((prev) => prev + 1);
      }, 340 + randTime),
    ]);
  }, [timeTick]);

  useEffect(() => {
    if (animationIndex >= gramLoseImages.length) {
      const randTime = getRandomInt(1200);

      setTimeout(() => {
        setAnimationIndex(0);
      }, 700 + randTime);
    }
  }, [animationIndex])

  return (
    <div
      className="gram-character"
      style={{
        top: `52px`,
        left: `109px`,
      }}
    >
      <img
        height={"230px"}
        src={
          gramLoseImages[
            animationIndex >= gramLoseImages.length
              ? gramLoseImages.length - 1
              : animationIndex
          ]
        }
        className={"gram-character-img"}
        alt="Gram"
      ></img>
    </div>
  );
};

export default NotFoundGram;
