/* eslint-disable react-hooks/exhaustive-deps */
import "./GramCharacter.css";
import run1 from "../../../img/jumpgram/gram/run1.png";
import run2 from "../../../img/jumpgram/gram/run2.png";
import run3 from "../../../img/jumpgram/gram/run3.png";

import jump1 from "../../../img/jumpgram/gram/jump1.png";
import jump2 from "../../../img/jumpgram/gram/jump2.png";
import jump3 from "../../../img/jumpgram/gram/jump3.png";

import trip1 from "../../../img/jumpgram/gram/trip1.png";
import trip2 from "../../../img/jumpgram/gram/trip2.png";
import trip3 from "../../../img/jumpgram/gram/trip3.png";

import newsImg from "../../../img/tcg/items/newspaper.png";

import { useEffect, useState } from "react";

const gramRunImages = [run1, run2, run3, run2];

const gramJumpImages = [jump3, jump2, jump1];

const gramTripImages = [trip3, trip1, trip2];

interface GramProps {
  x: number;
  y: number;
  ref?: React.RefObject<HTMLDivElement>;
  state: "run" | "jump" | "lose";
  hasShield: boolean;
  isSuper: boolean;
  height?: string;
}

const JumpGramCharacter = ({ x, y, ref, state, hasShield, isSuper, height = "125px" }: GramProps) => {
  const [animationIndex, setAnimationIndex] = useState<number>(0);
  const [timeTick, setTimeTick] = useState<number>(0);
  const [timers, setTimers] = useState<NodeJS.Timeout[]>([]);

  useEffect(() => {
    // if (isActive) {
    setTimers([
      ...timers,
      setTimeout(() => {
        setTimeTick((prev) => prev + 1);
        setAnimationIndex((prev) => prev + 1);
      }, 180),
    ]);

    // }
  }, [timeTick]);

  const clearTimers = () => {
    timers.forEach((timer) => {
      clearTimeout(timer);
    });
  };

  useEffect(() => {
    if (
      state === "lose" &&
      animationIndex % gramTripImages.length === gramTripImages.length
    ) {
      clearTimers();
    }
  }, [state]);

  useEffect(() => {
    setAnimationIndex(0);
  }, [state]);

  const calcPlayerImg = (state: string, animationIndex: number) => {
    switch (state) {
      case "run":
        return gramRunImages[animationIndex % gramRunImages.length];
      case "jump":
        return gramJumpImages[
          animationIndex >= gramJumpImages.length
            ? gramJumpImages.length - 1
            : animationIndex
        ];
      case "lose":
        return gramTripImages[
          animationIndex >= gramTripImages.length
            ? gramTripImages.length - 1
            : animationIndex
        ];
      default:
        return gramRunImages[animationIndex % gramRunImages.length];
    }
  };

  return (
    <div
      className="gram-character"
      ref={ref}
      style={{
        top: `${y}px`,
        left: `${x}px`
      }}
    >
      <img
        style={{
          filter: `${
            isSuper
              ? "saturate(100%) contrast(100%) brightness(800%) invert(90%) sepia(80%) drop-shadow(10px 10px 20px rgb(255, 196, 18))"
              : "none"
          }`,
        }}
        height={height}
        src={calcPlayerImg(state, animationIndex)}
        className={`${
          state === "jump" ? "gram-character-img-jump" : "gram-character-img"
        }`}
        alt="Gram"
      ></img>
      {hasShield && (
        <img
          height="80px"
          src={newsImg}
          className={`${"gram-character-paper-img"}`}
          alt="Gram's Newspaper Shield"
          style={{
            filter: `${
              isSuper
                ? "saturate(100%) contrast(100%) brightness(130%)"
                : "none"
            }`,
          }}
        ></img>
      )}
    </div>
  );
};

export default JumpGramCharacter;
