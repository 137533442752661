import { VinnyGramType } from '../../../../utils/Types';
import '../../../Css/tcg/HealthBar.css'

export interface HealthBarProps {
  value: number;
  name: VinnyGramType;
  style?: React.CSSProperties;
  maxWidth?: number;
  height?: string;
}

const HealthBar = ({ value, style, name, maxWidth=100, height="34px" }: HealthBarProps) => {
  const calcBgColor = () => {
    switch (name) {
      case "Vinny":
        return "lightblue";
      case "Gram":
        return "#DFC523";
      default: 
        return "green"
    }
  }

  const calcReverseBgColor = () => {
    switch (name) {
      case "Gram":
        return "lightblue";
      case "Vinny":
        return "#DFC523";
      default: 
        return "red"
    }
  }

  return (
    <div className='player-hp-container' style={style}>
      <div style={{
        transition: 'width 0.8s ease-out',
        backgroundColor: calcBgColor(),
        width: `${value}%`,
        boxShadow: '2px 0 2px rgba(20, 19, 19, 0.452) ',
        display: 'grid',
        height: height
      }}>
       <h1 className='player-hp' style={{
        color: calcReverseBgColor(),
       }}>{`${value.toString()}hp`}</h1>
      </div>
    </div>
  );
}

export default HealthBar;
