import { faVolumeMute, faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Css/VolumeControl.css";
import { useContext } from "react";
import { VolumeContext } from "../Routes";

export interface VolumeControlProps {
  onClick: () => void;
  topRight?: boolean;
}

const VolumeControl = ({ onClick, topRight }: VolumeControlProps) => {
  const globalVolume = useContext(VolumeContext).globalVolume;

  return (
    <FontAwesomeIcon
      className={topRight ? "mute-button-videos-page" : "mute-button"}
      icon={globalVolume === 0 ? faVolumeMute : faVolumeHigh}
      onClick={() => onClick()}
    />
  );
};

export default VolumeControl;
