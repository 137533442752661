import { useEffect, useState } from "react";
import "../../Css/JumpGram.css";
import introScreenBg from "../../../img/dropgram/Cover.jpeg";
import DropGramPage from "./DropGramPage";
import SplashScreen from "../JumpGram/SplashScreen";
import BGMusic from "../../../sounds/dropgram/Theme.mp3";



const DropGramLandingPage = () => {
  const [isGameStarted, setIsGameStarted] = useState<boolean>(false);
  const [themeSong] = useState<HTMLAudioElement>(new Audio(BGMusic));

  return isGameStarted ? (
    <DropGramPage themeSong={themeSong}></DropGramPage>
  ) : (
    <SplashScreen
      name="Drop Gram"
      img={introScreenBg}
      themeSong={themeSong}
      startGame={() => setIsGameStarted(true)}
    >
      <p>- Move left and right</p>
      <p>- Collect falling newspapers!</p>
      <p>- Avoid everything else!</p>
    </SplashScreen>
  );
};

export default DropGramLandingPage;
