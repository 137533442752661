/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { coolClone, getRandomInt, sortByValue } from "../../../utils/Utils";
import Button from "../../Small/Button";
import JumpGramCharacter from "./JumpGramCharacter";

import "../../Css/JumpGram.css";
import JumpBlock, { JumpBlockType } from "./JumpBlock";
import useSound from "use-sound";
import gameBackground from "../../../img/jumpgram/bgs/Wall.jpeg";
import carpetBackground from "../../../img/jumpgram/bgs/Carpet.jpeg";
import stuffBackground from "../../../img/jumpgram/bgs/StuffLarge.png";

import paperPiece1 from "../../../img/jumpgram/paperPieces/1.png";
import paperPiece2 from "../../../img/jumpgram/paperPieces/2.png";
import paperPiece3 from "../../../img/jumpgram/paperPieces/3.png";
import paperPiece4 from "../../../img/jumpgram/paperPieces/4.png";

import bongHitSound from "../../../sounds/tcg/items/bongHit.mp3";
import paperSound from "../../../sounds/tcg/items/paper.mp3";
import specialSound from "../../../sounds/tcg/items/guitarRiff.mp3";
import loseSound from "../../../sounds/tcg/items/drum.mp3";

import Modal from "../../Small/Modal";
import MFVLink from "../TCG/MFVLink";
import {
  BLOCK_X,
  BlockData,
  COLLIDE_VALUE,
  DEFAULT_GRAM_TOP,
  DefaultHighScores,
  EARLY_NEWS_CHANCE,
  GRAM_LEFT,
  JUMP_AGAIN_TIME,
  JUMP_HEIGHT,
  JUMP_LENGTH,
  JumpGramBlocks,
  JumpGramPowerUps,
  LATE_NEWS_CHANCE,
  MAX_OBJECT_SPEED,
  MIN_TICK_TIME,
  NEWSPAPER_GAP_TIME,
  STARTING_NAME,
  STARTING_NEWSPAPERS,
  STARTING_SCORE,
  STARTING_TIME_LAG,
  SUPER_JUMP_AGAIN_TIME,
  SUPER_JUMP_HEIGHT,
  SUPER_JUMP_LENGTH,
  TIME_DIVISOR,
} from "./JumpGramConstants";
import { HighScore, HighScoreResults } from "../../../utils/Types";
import clickSound from "../../../sounds/click.mp3";
import EndGameMusic from "../../../sounds/jumpgram/GameEnd.mp3";
import { appUrl } from "../../../utils/Constants";
import { VolumeContext } from "../../../Routes";

interface JumpGramProps {
  midiPlayer: any;
}

const JumpGramPage = ({ midiPlayer }: JumpGramProps) => {
  const globalVolume = useContext(VolumeContext).globalVolume;
  const [isJumping, setIsJumping] = useState<boolean>(false);
  const [canJumpAgain, setCanJumpAgain] = useState<boolean>(true);
  const [playerState, setPlayerState] = useState<"run" | "jump" | "lose">(
    "run"
  );
  const [showGameOverModal, setShowGameOverModal] = useState<boolean>(false);
  const [charX, setCharX] = useState<number>(GRAM_LEFT);
  const [charY, setCharY] = useState<number>(DEFAULT_GRAM_TOP);
  const [blocks, setBlocks] = useState<JumpBlockType[]>([]);
  const [timeTick, setTimeTick] = useState<number>(0);
  const [timeSinceNewspaper, setTimeSinceNewspaper] = useState<number>(0);
  const [isGameOver, setIsGameOver] = useState<boolean>(false);
  const [yourHighScore, setYourHighScore] = useState<HighScore | undefined>(
    undefined
  );
  const [timers, setTimers] = useState<NodeJS.Timeout[]>([]);
  const [newspapers, setNewspapers] = useState<number>(STARTING_NEWSPAPERS);
  const [highscores, setHighScores] = useState<HighScore[]>(DefaultHighScores);
  const [serverHighscores, setServerhighscores] =
    useState<HighScore[]>(DefaultHighScores);
  const [shouldJump, setShouldJump] = useState<boolean>(false);
  const [highScoreName, setUsername] = useState<string>(STARTING_NAME);
  const [playJumpSound] = useSound(bongHitSound, { volume: globalVolume });
  const [playSpecialSound] = useSound(specialSound, { volume: globalVolume });
  const [playLoseSound] = useSound(loseSound, { volume: globalVolume });
  const [playPaperSound] = useSound(paperSound, { volume: globalVolume });
  const [playClick] = useSound(clickSound, { volume: globalVolume });
  const [playEndGameSong] = useSound(EndGameMusic, { volume: globalVolume });
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [score, setScore] = useState<number>(STARTING_SCORE);
  const isSuper = newspapers === 8;
  const hasOvershield = newspapers >= 4;

  const postHighScore = async (hScore: HighScore): Promise<HighScore[]> => {
    const rawResponse = await fetch(`${appUrl}/jumpgramscores`, {
      method: "POST",
      body: JSON.stringify({ highscore: hScore }),
    });
    const postResponse = await rawResponse.json();

    if (!postResponse.error && postResponse.highscores) {
      return postResponse.highscores;
    }
    return [];
  };

  useEffect(() => {
    const getHighScores = async () => {
      const response = await fetch(`${appUrl}/jumpgramscores`);
      const obj: HighScoreResults = await response.json();
      if (obj.highscores) {
        setHighScores(obj.highscores);
        setServerhighscores(obj.highscores);
      }
    };

    if (!isGameOver) {
      getHighScores();
    }
  }, [isGameOver]);

  const jump = () => {
    if (!isJumping && canJumpAgain && !isGameOver) {
      setIsJumping(true);
      setPlayerState("jump");
      setCanJumpAgain(false);
      playJumpSound();
      const initialVal = charY;

      if (isSuper) {
        setCharY(charY + SUPER_JUMP_HEIGHT);

        setTimers([
          ...timers,
          setTimeout(() => {
            setCharY(initialVal);
            setScore((prev) => prev + 1);
            setPlayerState("run");
            setIsJumping(false);

            setTimers([
              ...timers,
              setTimeout(() => {
                setCanJumpAgain(true);
                setShouldJump(false);
              }, SUPER_JUMP_AGAIN_TIME),
            ]);
            //Allow next jump
          }, SUPER_JUMP_LENGTH),
        ]);
      } else {
        setCharY(charY + JUMP_HEIGHT);

        setTimers([
          ...timers,
          setTimeout(() => {
            setCharY(initialVal);
            setScore((prev) => prev + 1);
            setPlayerState("run");
            setIsJumping(false);

            setTimers([
              ...timers,
              setTimeout(() => {
                setCanJumpAgain(true);
                setShouldJump(false);
              }, JUMP_AGAIN_TIME),
            ]);
            //Allow next jump
          }, JUMP_LENGTH),
        ]);
      }
    }
  };

  const clearTimers = () => {
    timers.forEach((timer) => {
      clearTimeout(timer);
    });
    setTimers([]);
  };

  const cleanUpGame = () => {
    midiPlayer.stop();
    // themeSong.pause();
    setBlocks([]);
    setYourHighScore(undefined);
    setTimeSinceNewspaper(0);
    setCharX(GRAM_LEFT);
    setCharY(DEFAULT_GRAM_TOP);
    setScore(STARTING_SCORE);
    setIsJumping(false);
    setShouldJump(false);
    setCanJumpAgain(true);
    setNewspapers(0);
    clearTimers();
    setTimers([]);
    setPlayerState("run");
    setShowGameOverModal(false);
  };

  const startNewGame = () => {
    playClick();
    setShowGameOverModal(false);
    cleanUpGame();
    setIsGameOver(false);
    setTimeTick(0);
    // themeSong.volume = globalVolume;
    // themeSong.currentTime = 0;
    // themeSong.loop = true;
   midiPlayer.play();
    // themeSong.play();
  };

  useEffect(() => {
    // themeSong.volume = globalVolume;
  }, [globalVolume, midiPlayer]);

  useEffect(() => {
    const endGameFunction = async () => {
      if (isGameOver && !showGameOverModal) {
        // themeSong.pause();
       midiPlayer.stop();
        playLoseSound();
        clearTimers();
        setBlocks([]);
        setCharY(DEFAULT_GRAM_TOP);
        setPlayerState("lose");

        let isOnTopTen = false;

        //If user places in the top ten
        highscores.forEach((highscore: HighScore) => {
          if (score > highscore.value) {
            isOnTopTen = true;
            return;
          }
        });

        if (isOnTopTen) {
          playSpecialSound();
          //Then show the prompt to enter name and submit
          //Show them on the board

          const time = new Date(Date.now());

          const hScore: HighScore = {
            name: highScoreName,
            value: score,
            time,
            gameLength: timeTick,
            id: `${time.toTimeString()}-${score}`
          };

          const newHighscores = [...serverHighscores, hScore];
          const sortedScores = newHighscores.sort(sortByValue).slice(0, 7);

          setHighScores(sortedScores);
          setYourHighScore(hScore);
        }

        setTimeout(() => {
          playEndGameSong();
          setShowGameOverModal(true);
        }, 700);
      }
    };

    endGameFunction();
  }, [isGameOver]);

  const summonBlock = (block: BlockData) => {
    const id = `${block.type}-${new Date(Date.now()).toString()}`;
    const newBlock: JumpBlockType = {
      x: BLOCK_X,
      y: DEFAULT_GRAM_TOP + block.topOffset,
      id: id,
      speed: block.speed,
      image: block.imgSrc,
      leftBounds: block.leftBounds,
      rightBounds: block.rightBounds,
      type: block.type,
      onDestroy: () => {},
      updateX: () => {},
    };
    setBlocks((prev) => [...prev, newBlock]);
  };

  const summonNewspaper = (block: BlockData) => {
    setTimeSinceNewspaper(0);
    const id = `${block.name}-${timeTick.toString()}`;
    const newBlock: JumpBlockType = {
      x: BLOCK_X,
      y: DEFAULT_GRAM_TOP + block.topOffset,
      id: id,
      speed: block.speed,
      image: block.imgSrc,
      leftBounds: block.leftBounds,
      rightBounds: block.rightBounds,
      type: block.type,
      onDestroy: () => {},
      updateX: () => {},
    };
    setBlocks((prev) => [...prev, newBlock]);
  };

  const calcTickTime = () => {
    const RAND_BONUS = getRandomInt(150);
    const tickMod = RAND_BONUS - Math.ceil(timeTick / TIME_DIVISOR);
    if (tickMod > 0) {
      return MIN_TICK_TIME + tickMod;
    }
    return MIN_TICK_TIME;
  };

  useEffect(() => {
    setTimers([
      ...timers,
      setTimeout(() => {
        setTimeTick((prev) => prev + 1);
        setTimeSinceNewspaper((prev) => prev + 1);
      }, calcTickTime()),
    ]);
  }, [timeTick]);

  useEffect(() => {
    if (!isGameOver && timeTick > STARTING_TIME_LAG) {
      const randHundredOne = getRandomInt(100);

      //Once the game has started, on a regular interval:
      if (timeTick % 10 === 0) {
        if (randHundredOne > 5) {
          const randHundred = getRandomInt(100);

          let block: BlockData = JumpGramBlocks.couch;

          if (randHundred > 70 && timeTick > 80) {
            block = JumpGramBlocks.tv;
          } else if (randHundred > 50) {
            block = JumpGramBlocks.chair;
          }

          summonBlock(block);
        }
      }

      if (timeTick % 10 === 0 && timeSinceNewspaper > NEWSPAPER_GAP_TIME) {
        const randHundredTwo = getRandomInt(100);

        if (hasOvershield) {
          if (randHundredTwo > LATE_NEWS_CHANCE) {
            const powerUp = JumpGramPowerUps.newspaper;
            summonNewspaper(powerUp);
          }
        } else {
          if (randHundredTwo > EARLY_NEWS_CHANCE) {
            const powerUp = JumpGramPowerUps.newspaper;
            summonNewspaper(powerUp);
          }
        }
      }

      if (timeTick === 100) {
        summonNewspaper(JumpGramPowerUps.newspaper);
      }
    }

    if (timeTick === 4 && !isGameOver) {
      summonNewspaper(JumpGramPowerUps.newspaper);
    }
  }, [timeTick, isGameOver]);

  const handleDestroyBlock = (blockId: string) => {
    const index = blocks.findIndex((block) => block.id === blockId);

    if (index > -1 && blocks.length) {
      const newBlocks = [...blocks];
      newBlocks.splice(index, 1);
      setBlocks(newBlocks);
    }
  };

  useEffect(() => {
    window.addEventListener("keypress", (e) => {
      if (e.key === " " && !shouldJump) {
        e.stopPropagation();
        e.preventDefault();
        setShouldJump(true);
      }
    });
  }, []);

  useEffect(() => {
    if (!isJumping && canJumpAgain && !isGameOver && shouldJump) {
      jump();
    }
  }, [shouldJump]);

  const handleUpdateBlockX = (
    key: string,
    value: number,
    leftBounds: number,
    rightBounds: number,
    type: string
  ) => {
    if (type === "block") {
      if (
        value < COLLIDE_VALUE + leftBounds &&
        value > COLLIDE_VALUE - rightBounds &&
        !isJumping &&
        !isGameOver
      ) {
        if (hasOvershield) {
          handleDestroyBlock(key);
          playPaperSound();
          setNewspapers(0);
        } else {
          setIsGameOver(true);
        }
      }
    }

    if (type === "powerup") {
      if (
        value < COLLIDE_VALUE + leftBounds &&
        value > COLLIDE_VALUE - rightBounds &&
        isJumping &&
        !isGameOver
      ) {
        collectNewspaper();
        handleDestroyBlock(key);
      }
    }
  };

  const collectNewspaper = () => {
    playPaperSound();
    setTimeSinceNewspaper(0);

    if (newspapers === 7) {
      playSpecialSound();
    }

    if (newspapers >= 8) {
      setScore((prev) => prev + 2);
    } else {
      setNewspapers((prev) => prev + 1);
    }
  };

  const userAlreadyExists = (name: string, score: number) => {
    let foundName = false;
    serverHighscores.forEach((serverScore) => {
      if (name.toLowerCase() === serverScore.name.toLowerCase()) {
        if (serverScore.value > score) {
          foundName = true;
        }
        return;
      }
    });
    return foundName;
  };

  const updateUsername = async (value: string) => {
    if (highScoreName.length >= 5) {
      value = value.substring(0, 5);
    }
    setUsername(value.toUpperCase());

    if (yourHighScore) {
      const hScore: HighScore = {
        ...yourHighScore,
        name: value,
      };

      const newHighscores = [...coolClone(serverHighscores), hScore];
      setYourHighScore(hScore);
      const sortedScores = newHighscores.sort(sortByValue).slice(0, 7);
      setHighScores(sortedScores);
    }
  };

  const generateHighScores = () => {
    // if (!yourHighScore) return;
    const yourId = yourHighScore ? yourHighScore.id : "";

    return highscores.map((highscore, i) => {
      const row = (
        <div className="highscore-row">
          <p
            className={
              highscore.id === yourId
                ? `you-text`
                : ""
            }
          >{`${i + 1}: ${highscore.name}`}</p>
          <p className="highscore-value">{highscore.value}</p>
        </div>
      );

      return row;
    });
  };

  const submitHighScore = async (yourScore: HighScore) => {
    if (yourHighScore?.name.length) {
      const res = await postHighScore(yourScore);

      if (res) {
        setIsSubmitted(true);
        setYourHighScore(undefined);
      }
    }
  };

  const generatePaperIcon = (paper: number) => {
    switch (paper) {
      case 1:
        return paperPiece1;
      case 2:
        return paperPiece2;
      case 3:
        return paperPiece3;
      case 4:
        return paperPiece4;
      default:
        return paperPiece4;
    }
  };

  const calcSpeed = (speed: number): number => {
    let speedVal = speed + Math.ceil(timeTick / TIME_DIVISOR);
    if (speedVal >= MAX_OBJECT_SPEED) {
      speedVal = MAX_OBJECT_SPEED;
    }
    return speedVal;
  };

  return (
    <div className="main-container">
      <div style={{ display: "grid" }}>
        <h3 style={{ textAlign: "center" }} className="main-jump-header">
          Jump Gram
        </h3>
        <div className="game-container" onClick={() => jump()}>
          <div
            className={"sliding-background"}
            style={{
              backgroundSize: "fit-content",
              background: `url(${gameBackground}) repeat-x`,
              animationPlayState: isGameOver ? "paused" : "running",
            }}
          ></div>
          <div
            className={"sliding-carpet-background"}
            style={{
              background: `url(${carpetBackground}) repeat-x`,
              animationPlayState: isGameOver ? "paused" : "running",
            }}
          ></div>
          <div
            className={"sliding-stuff-background"}
            style={{
              // backgroundSize: "auto 75%",
              background: `url(${stuffBackground}) repeat-x`,
              animationPlayState: isGameOver ? "paused" : "running",
            }}
          ></div>
          <div className="top-jump-bar"></div>
          <h3 className="jump-score">{`${score}`}</h3>
          {/* <h3 className="news-score">{`${timeTick}`}</h3> */}
          <div className="paper-icon-container">
            {newspapers > 4 && (
              <img
                className="second-paper"
                src={generatePaperIcon(newspapers - 4)}
                alt={"Newspaper icon"}
              ></img>
            )}
            {newspapers > 0 && (
              <img
                src={generatePaperIcon(newspapers)}
                alt={"Newspaper icon"}
              ></img>
            )}
          </div>
          <JumpGramCharacter
            x={charX}
            y={charY}
            state={playerState}
            hasShield={hasOvershield}
            isSuper={isSuper}
          ></JumpGramCharacter>
          {blocks.map((block: JumpBlockType, i) => {
            return (
              <JumpBlock
                key={block.id}
                id={block.id}
                x={block.x}
                y={block.y}
                speed={calcSpeed(block.speed)}
                image={block.image}
                leftBounds={block.leftBounds}
                rightBounds={block.rightBounds}
                type={block.type}
                updateX={(
                  value: number,
                  leftBounds: number,
                  rightBounds: number,
                  type: string
                ) =>
                  handleUpdateBlockX(
                    block.id,
                    value,
                    leftBounds,
                    rightBounds,
                    type
                  )
                }
                onDestroy={() => handleDestroyBlock(block.id)}
              ></JumpBlock>
            );
          })}
        </div>
        <div className="jump-div" onClick={() => jump()}></div>
        <Modal
          isVisible={showGameOverModal}
          onClose={() => {
            setIsGameOver(false);
          }}
        >
          <h1 className="victory-text">{`Top Scores:`}</h1>
          <div className="highscores-grid">{generateHighScores()}</div>
          {yourHighScore && (
            <div style={{ display: "grid", gridGap: "4px" }}>
              {/* <h1 className="victory-text">{`Your Name: `}</h1> */}
              <div className="highscore-row">
                <input
                  className="gram-input"
                  value={highScoreName}
                  onChange={(e) => updateUsername(e.target.value)}
                ></input>
                <p className="highscore-value">{score}</p>
              </div>
              <Button
                eventName={"Jump Gram Submit Score Button"}
                variant="primary"
                disabled={
                  highScoreName.length < 3 ||
                  highScoreName === "YOU" ||
                  userAlreadyExists(highScoreName, yourHighScore.value)
                }
                onClick={() => {
                  submitHighScore(yourHighScore);
                }}
              >
                Submit Score
              </Button>
            </div>
          )}
          {!yourHighScore && !isSubmitted && (
            <div className="highscore-row">
              <p className="you-text">{`......You:`}</p>
              <p className="highscore-value">{score}</p>
            </div>
          )}
          {!yourHighScore && isSubmitted && (
            <h1 className="victory-text">{`Thanks!`}</h1>
          )}
          <Button
            eventName={"Jump Gram Play Again Button"}
            variant="primary"
            onClick={() => {
              startNewGame();
            }}
          >
            Play Again
          </Button>
          <MFVLink />
        </Modal>
      </div>
    </div>
  );
};

export default JumpGramPage;