import { useContext, useEffect, useState } from "react";
import Button from "../../Small/Button";

import "../../Css/TicTacGram.css";
import useSound from "use-sound";
import gameBackground from "../../../img/jumpgram/bgs/Wall.jpeg";

import bubbleSound from "../../../sounds/tcg/items/bubbleHit.mp3";
import chickenSound from "../../../sounds/tcg/items/chicken.mp3";
import smallKnock from "../../../sounds/tcg/items/smallKnock.mp3";
import fartSound2 from "../../../sounds/tictacgram/fart2.mp3";

import winImg from "../../../img/news/46.jpeg";
import tieImg from "../../../img/news/24.jpeg";
import loseImg from "../../../img/news/27.jpeg";

import Modal from "../../Small/Modal";
import MFVLink from "../TCG/MFVLink";
import {
  DefaultGameCells,
  GameCell,
} from "./TicTacGramConstants";
import clickSound from "../../../sounds/click.mp3";
import { VolumeContext } from "../../../Routes";

import gramGuitar from "../../../img/tcg/gram/Special/GramSpecial3.png";

import vinnyImg from "../../../img/tcg/vinny/Win/VinnyWin3.png";
import { checkTicTacGramWin, coolClone, getRandomInt, lighUpCells } from "../../../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { VinnyGramUndefinedType, WinnerType } from "../../../utils/Types";

interface TicTacGramProps {
  themeSong?: HTMLAudioElement;
}

const TicTacGramPage = ({ themeSong }: TicTacGramProps) => {
  const globalVolume = useContext(VolumeContext).globalVolume;
  const [showGameOverModal, setShowGameOverModal] = useState<boolean>(false);
  const [isVinnyTurn, setIsVinnyTurn] = useState<boolean>(false);
  const [tookVinnyTurn, setTookVinnyTurn] = useState<boolean>(false);
  const [timers, setTimers] = useState<NodeJS.Timeout[]>([]);
  const [playClick] = useSound(clickSound, { volume: globalVolume });
  const [playFart2] = useSound(fartSound2, { volume: globalVolume });
  const [gameCells, setGameCells] = useState<GameCell[]>(coolClone(DefaultGameCells));
  const [winner, setWinner] = useState<WinnerType>(undefined);
  const [vinnyWins, setVinnyWins] = useState<number>(0);
  const [gramWins, setGramWins] = useState<number>(0);

  const [playBubbleSound] = useSound(bubbleSound, { volume: globalVolume });
  const [playChickenSound] = useSound(chickenSound, { volume: globalVolume });
  const [playKnockSound] = useSound(smallKnock, { volume: globalVolume });

  const isGameOver = winner !== undefined;

  const generateCell = (cell: GameCell) => {
    switch (cell.state) {
      case undefined:
        return;
      case "Gram":
        return (
          <img className="cell-img" src={gramGuitar} alt="Gram Guitar"></img>
        );
      case "Vinny":
        return <img className="cell-img" src={vinnyImg} alt="Vinny Img"></img>;
      default:
        return (
          <img className="cell-img" src={gramGuitar} alt="Gram Guitar"></img>
        );
    }
  };

  const clearTimers = () => {
    timers.forEach((timer) => {
      clearTimeout(timer);
    });
    setTimers([]);
  };

  const clearGameCells = () => {
    const newGameCells = coolClone(DefaultGameCells);
    newGameCells.forEach((cell: GameCell) => {
      cell.state = undefined;
      cell.isLitUp = false;
    });

    setGameCells(newGameCells);
    debugger
  };

  const cleanUpGame = () => {
    // themeSong.pause();
    clearGameCells();
    clearTimers();
    setTimers([]);
    setIsVinnyTurn(false);
    setTookVinnyTurn(false);
    setShowGameOverModal(false);
  };

  const startNewGame = () => {
    cleanUpGame();
    playClick();
    setShowGameOverModal(false);
    setWinner(undefined);
    // themeSong.currentTime = 0;
    // themeSong.play();
  };

  const checkWinner = (cells: GameCell[]): VinnyGramUndefinedType => {
    const vinnyWin = checkTicTacGramWin("Vinny", cells);
    if (vinnyWin) {
      return "Vinny";
    }
    const gramWin = checkTicTacGramWin("Gram", cells);
    if (gramWin) {
      return "Gram";
    }
    return undefined;
  };

  useEffect(() => {
    const endGameFunction = async () => {
      if (isGameOver && !showGameOverModal) {
        // themeSong.pause();
        clearTimers();

        if (winner === "Vinny") {
          setVinnyWins(prev => prev + 1);
        } else if (winner === "Gram") {
          playFart2();
          setGramWins(prev => prev + 1);
        } else if (winner === "tie") {

        }
        // SHOW A LOSE ANIMATION HERE

        setTimeout(() => {
          setShowGameOverModal(true);
        }, 1500);
      }
    };

    endGameFunction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGameOver]);

  useEffect(() => {
    const checkAllMoves = (
      emptyCells: GameCell[],
      charToCheck: VinnyGramUndefinedType
    ): number[] => {
      const moves: number[] = [];
      emptyCells.forEach((emptyCell, i) => {
        const boardClone = coolClone(gameCells);
        const cellIndex = emptyCell.index;
        boardClone[cellIndex].state = charToCheck;
        const winner = checkWinner(boardClone);
  
        if (winner === charToCheck) {
          moves.push(emptyCell.index);
        }
      });
  
      return moves;
    };

    const vinnyBotTurn = () => {
      const emptyCells = gameCells.filter((cell: GameCell) => {
        return cell.state === undefined;
      });

      if (emptyCells.length === 0) return;

      playClick();
      //Take random move
      const rand = getRandomInt(emptyCells.length);
      let bestMoveIndex = emptyCells[rand].index;
      let winningMoves: number[] = [];
      let lossPreventionMoves: number[] = [];

      //Check for losing moves
      if (emptyCells.length < 7) {
        winningMoves = checkAllMoves(emptyCells, "Vinny");

        if (!winningMoves.length) {
          lossPreventionMoves = checkAllMoves(emptyCells, "Gram");
        }
      }

      // Try to win first!
      if (winningMoves.length) {
        bestMoveIndex = winningMoves[0];
        playChickenSound();
      } else if (lossPreventionMoves.length) {
        bestMoveIndex = lossPreventionMoves[0];
        playKnockSound();
      }

      const newCells: GameCell[] = [...gameCells];
      newCells[bestMoveIndex].state = "Vinny";

      handleChangeTurn(newCells);
    };

    if (isVinnyTurn && !tookVinnyTurn && !isGameOver) {
      setTookVinnyTurn(true);

      const randTime = 400 + getRandomInt(500);
      setTimeout(() => {
        //VINNY BOT LOGIC TURN
        if (!isGameOver) {
          vinnyBotTurn();
        }
      }, randTime);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gameCells,
    isGameOver,
    isVinnyTurn,
    playClick,
    tookVinnyTurn,
  ]);

  const handleClickCell = (cell: GameCell) => {
    if (cell.state !== undefined || isGameOver || isVinnyTurn) return;
    const newGameCells = [...gameCells];
    const newGameCell = newGameCells[cell.index];
    playClick();

    if (isVinnyTurn) {
      newGameCell.state = "Vinny";
    } else {
      newGameCell.state = "Gram";
    }
    newGameCells[cell.index] = newGameCell;
    setTookVinnyTurn(false);
    handleChangeTurn(newGameCells);
  };

  const handleChangeTurn = (cells: GameCell[]) => {
    setGameCells(cells);
    const emptyCells = cells.filter((cell: GameCell) => {
      return cell.state === undefined;
    });

    if (emptyCells.length === 0) {
      // TIE GAME
      playBubbleSound();
      setWinner("tie");
    }


    const didWin = checkWinner(cells);
    if (didWin) {
      setWinner(didWin);
      lightUpWinningCells(didWin);
    } else {
      setIsVinnyTurn(prev => !prev);
    }
  }

  const lightUpWinningCells = (winnr: VinnyGramUndefinedType) => {
    const newCells = lighUpCells(winnr, gameCells);
    setGameCells(newCells);
  }

  const calcWinText = () => {
    switch (winner) {
      case "tie":
        return "TIE GAME!"
      case "Gram":
        return  `You win!`
      case "Vinny":
        return  `You Lose!`
      default:
        return ""
    }
  }

  const calcVictoryImg = () => {
    switch (winner) {
      case "tie":
        return tieImg;
      case "Gram":
        return winImg;
      case "Vinny":
        return loseImg;
      default:
        return ""
    }
  }

  return (
    <div className="main-container">
      <div style={{ display: "grid" }}>
        <h3 style={{ textAlign: "center" }} className="main-jump-header">
          Tic Tac Gram
        </h3>
        <div className="tic-tac-gram-game-container">
          <div
            className={"tic-tac-gram-background"}
            style={{
              background: `url(${gameBackground})`,
              backgroundSize: "cover",
            }}
          ></div>
          <div className="tic-tac-grid">
            {gameCells.map((gameCell: GameCell) => {
              return (
                <div
                  style={{ cursor: isVinnyTurn ? "initial" : "pointer" }}
                  className={gameCell.isLitUp ? "game-cell-lit" : "game-cell"}
                  onClick={() => handleClickCell(gameCell)}
                >
                  {generateCell(gameCell)}
                </div>
              );
            })}
          </div>
          <div className="top-jump-bar"></div>
          <h3
            style={{
              fontSize: "2rem",
              padding: "4px",
              color: isVinnyTurn ? "#2b8ff4" : "#2b8ff4",
            }}
            className="tic-tac-score"
          >
            {isGameOver
              ? `${winner === "tie" ? "TIE GAME" : "WINNER!"}`
              : `${isVinnyTurn ? "Vinny's Turn" : "Gram's Turn"}`}
          </h3>
          <div className="tic-tac-refresh-container">
            <Button
              eventName={"Tic Tac Gram Refresh Button"}
              variant="primary"
              disabled={isGameOver}
              onClick={() => {
                cleanUpGame();
              }}
            >
              <FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon>
            </Button>
          </div>
        </div>
        <Modal isVisible={showGameOverModal} onClose={() => {}}>
          <h1 className="victory-text">{calcWinText()}</h1>
          <img src={calcVictoryImg()} className="victory-img" alt="Win"></img>
          <p className="victory-text-small">Vinny: {vinnyWins}</p>
          <p className="victory-text-small">Gram: {gramWins}</p>
          <Button
            eventName={"Tic Tac Gram Play Again Button"}
            variant="primary"
            onClick={() => {
              startNewGame();
            }}
          >
            Play Again
          </Button>
          <MFVLink />
        </Modal>
      </div>
    </div>
  );
};

export default TicTacGramPage;
