/* eslint-disable  */
import { useCallback, useContext, useEffect, useState } from "react";
import { coolClone, getRandomInt, sortByValue } from "../../../utils/Utils";
import Button from "../../Small/Button";

import "../../Css/games/CoffeeGram.css";
import { CoffeeGramBlockType } from "./CoffeeGramBlock";
import useSound from "use-sound";
import gameBackground from "../../../img/coffeegram/bg.jpeg";

import vinnyThrow1 from "../../../img/tcg/vinny/Throw/VinnyThrow1.png";
import vinnyThrow2 from "../../../img/tcg/vinny/Throw/VinnyThrow2.png";
import vinnyThrow3 from "../../../img/tcg/vinny/Throw/VinnyThrow3.png";

import paperSound from "../../../sounds/tcg/items/paper.mp3";
import specialSound from "../../../sounds/tcg/items/guitarRiff.mp3";
import loseSound from "../../../sounds/tcg/items/drum.mp3";
import throwSound from "../../../sounds/tcg/items/blanket.mp3";

import Modal from "../../Small/Modal";
import MFVLink from "../TCG/MFVLink";
import {
  MoveData,
  FLOOR_HEIGHT,
  CEILING_HEIGHT,
  DefaultHighScores,
  GRAM_STARTING_LEFT,
  CoffeeGramMoves,
  MIN_TICK_TIME,
  STARTING_NAME,
  STARTING_TIME_LAG,
  STARTING_SCORE,
  HEALTH_BAR_TOP,
  VINNY_STARTING_LEFT,
  VINNY_STARTING_TOP,
  GRAM_STARTING_TOP,
  MAX_HEALTH,
  Heal,
  CoffeeGramHeals,
  GLOBAL_COOLDOWN,
} from "./CoffeeGramConstants";
import {
  HighScore,
  VinnyGramType,
  VinnyGramUndefinedType,
} from "../../../utils/Types";
import clickSound from "../../../sounds/click.mp3";
import EndGameMusic from "../../../sounds/dropgram/Lose.mp3";
import { VolumeContext } from "../../../Routes";
import CoffeeGramBlock from "./CoffeeGramBlock";
import { appUrl } from "../../../utils/Constants";
import { FALLING_SPEED } from "../DropGram/DropGramConstants";
import CoffeeGramCharacter from "./CoffeeGramCharacter";
import HealthBar from "../TCG/utils/HealthBar";

const vinnyThrowImages = [vinnyThrow1, vinnyThrow2, vinnyThrow3];

interface CoffeeGramProps {
  themeSong: HTMLAudioElement;
}

const CoffeeGramPage = ({ themeSong }: CoffeeGramProps) => {
  const globalVolume = useContext(VolumeContext).globalVolume;
  const [showGameOverModal, setShowGameOverModal] = useState<boolean>(false);
  const [vinnyX, setVinnyX] = useState<number>(VINNY_STARTING_LEFT);
  const [charY, setCharY] = useState<number>(FLOOR_HEIGHT);
  const [gramX, setGramX] = useState<number>(GRAM_STARTING_LEFT);
  const [vinnyHealth, setVinnyHealth] = useState<number>(1);
  const [gramHealth, setGramHealth] = useState<number>(100);
  const [blocks, setBlocks] = useState<CoffeeGramBlockType[]>([]);
  const [timeTick, setTimeTick] = useState<number>(0);
  const [isGameOver, setIsGameOver] = useState<boolean>(false);
  const [yourHighScore, setYourHighScore] = useState<HighScore | undefined>(
    undefined
  );
  const [timers, setTimers] = useState<NodeJS.Timeout[]>([]);
  const [highscores, setHighScores] = useState<HighScore[]>(DefaultHighScores);
  const [serverHighscores, setServerhighscores] =
    useState<HighScore[]>(DefaultHighScores);
  // const [hasKeyedUp, setHasKeyedUp] = useState<boolean>(true);
  const [isStandingStill, setIsStandingStill] = useState<boolean>(true);
  const [isGlobalCooldown, setIsGlobalCooldown] = useState<boolean>(false);
  const [isCasting, setIsCasting] = useState<boolean>(false);

  const [highScoreName, setUsername] = useState<string>(STARTING_NAME);
  const [playSpecialSound] = useSound(specialSound, { volume: globalVolume });
  const [playLoseSound] = useSound(loseSound, { volume: globalVolume });
  const [playThrowSound] = useSound(throwSound, { volume: globalVolume });
  const [playPaperSound] = useSound(paperSound, { volume: globalVolume });
  const [playClick] = useSound(clickSound, { volume: globalVolume });
  const [playEndGameSong] = useSound(EndGameMusic, { volume: globalVolume });
  const [isFacingRight, setIsFacingRight] = useState<boolean>(true);
  const [castProgress, setCastProgress] = useState<number>(1);

  const [vinnyRisingText, setVinnyRisingText] = useState<string>("");
  const [vinnyRisingIcon, setVinnyRisingIcon] = useState<string>("");
  const [gramRisingText, setGramRisingText] = useState<string>("");
  const [gramRisingIcon, setGramRisingIcon] = useState<string>("");

  const [selectedCharacter, setSelectedCharacter] =
    useState<VinnyGramUndefinedType>(undefined);

  const [score, setScore] = useState<number>(STARTING_SCORE);

  const clearTimers = () => {
    timers.forEach((timer) => {
      clearTimeout(timer);
    });
    setTimers([]);
  };

  const cleanUpGame = () => {
    themeSong.pause();
    setBlocks([]);
    setYourHighScore(undefined);
    // setVinnyX(VINNY_STARTING_LEFT);
    setCharY(FLOOR_HEIGHT);
    setScore(STARTING_SCORE);
    setVinnyHealth(1);
    setGramHealth(100);
    setSelectedCharacter(undefined);
    clearTimers();
    setTimers([]);
    setShowGameOverModal(false);
  };

  const startNewGame = () => {
    playClick();
    setShowGameOverModal(false);
    cleanUpGame();
    setIsGameOver(false);
    setTimeTick(0);
    themeSong.loop = true;
    themeSong.currentTime = 0;
    // themeSong.play();
  };

  useEffect(() => {
    setTimeout(() => {
      setTimeTick((prev) => prev + 1);
    }, 130);
  }, [timeTick]);

  useEffect(() => {
    const endGameFunction = async () => {
      if (isGameOver && !showGameOverModal) {
        themeSong.pause();
        playLoseSound();
        clearTimers();
        setBlocks([]);
        // setCharY(FLOOR_HEIGHT);
        // setPlayerState("lose");

        setTimeout(() => {
          playEndGameSong();
          setShowGameOverModal(true);
        }, 700);
      }
    };

    endGameFunction();
  }, [isGameOver]);

  const summonBlock = (block: MoveData) => {
    let blockTarget: VinnyGramType = "Vinny";
    let blockX = vinnyX;
    const coinFlip = getRandomInt(5);

    if (coinFlip < 4 && gramHealth > 0) {
      blockTarget = "Gram";
      blockX = gramX;
    }

    playThrowSound();

    const id = `${block.type}`;
    const newBlock: CoffeeGramBlockType = {
      x: blockX,
      y: CEILING_HEIGHT - 30,
      id: id,
      speed: block.speed,
      image: block.imgSrc,
      damage: block.damage,
      target: blockTarget,
      type: block.type,
      onDestroy: () => {},
      updateBlockLocation: () => {},
    };
    setBlocks((prev) => [...prev, newBlock]);
  };

  const calcTickTime = () => {
    const RAND_BONUS = getRandomInt(1500);
    return RAND_BONUS + MIN_TICK_TIME;
  };

  useEffect(() => {
    if (!isGameOver && timeTick > STARTING_TIME_LAG) {
      //Once the game has started, on a regular interval:
      if (timeTick % 25 === 0) {
        const randHundred = getRandomInt(100);

        let block: MoveData = CoffeeGramMoves.toaster;

        if (randHundred > 60) {
          block = CoffeeGramMoves.sock;
        } else if (randHundred > 30) {
          block = CoffeeGramMoves.toaster;
        } else if (randHundred > 10) {
          block = CoffeeGramMoves.dishwasher;
        } 
        // else if (randHundred > 40) {
        //   block = CoffeeGramMoves.tv;
        // }

        if (randHundred > 30) {
          summonBlock(block);
        }
      }
    }
  }, [timeTick, isGameOver]);

  const handleDestroyBlock = (blockId: string) => {
    // const index = blocks.findIndex((block) => block.id === blockId);

    const newBlocks = blocks.filter((block) => {
      return block.id !== blockId;
    });

    setBlocks(newBlocks);

    // if (index > -1 && blocks.length) {
    //   const newBlocks = [...blocks];
    //   newBlocks.splice(index, 1);
    //   setBlocks(newBlocks);
    // }
  };

  const updateHealth = (
    target: VinnyGramType,
    amount: number,
    currentValue: number
  ) => {
    let newVal = currentValue + amount;

    if (newVal > MAX_HEALTH) {
      newVal = MAX_HEALTH;
    }

    if (newVal < 0) {
      newVal = 0;
    }

    if (target === "Vinny") {
      setVinnyHealth(newVal);
      setVinnyRisingText(`${amount > 0 ? "+" : ""}${amount}`);
    } else if (target === "Gram") {
      setGramHealth(newVal);
      setGramRisingText(`${amount > 0 ? "+" : ""}${amount}`);
    }
  };

  useEffect(() => {
    if (vinnyHealth < 1 && gramHealth < 1) {
      setIsGameOver(true);
    }
  }, [vinnyHealth, gramHealth]);

  const applyHealOnTarget = useCallback(
    (heal: Heal) => {
      if (selectedCharacter === "Vinny" && vinnyHealth > 0) {
        updateHealth("Vinny", heal.value, vinnyHealth);
      }

      if (selectedCharacter === "Gram" && gramHealth > 0) {
        updateHealth("Gram", heal.value, gramHealth);
      }
    },
    [gramHealth, selectedCharacter, vinnyHealth]
  );

  useEffect(() => {
    if (isCasting) {
      setCastProgress(prev => prev + 1);
    }

    if (castProgress > 100) {
      setIsCasting(false);
      setCastProgress(1);
    }
  }, [isCasting, timeTick])

  const handleUseRoast = useCallback(
    (heal: Heal) => {
      if (isGameOver) return;
      setIsCasting(true);

      setTimeout(() => {
        applyHealOnTarget(heal);
        setIsGlobalCooldown(true);
        setIsCasting(false);
        setCastProgress(0);


        setTimeout(() => {
          setIsGlobalCooldown(false);
        }, GLOBAL_COOLDOWN);
      }, heal.castTime);
    },
    [isGameOver, applyHealOnTarget]
  );

  const handleCalcDamage = (target: VinnyGramType, value: number) => {
    if (target === "Vinny") {
      updateHealth("Vinny", value, vinnyHealth);
    }

    if (target === "Gram") {
      updateHealth("Gram", value, gramHealth);
    }
  };

  const handleUpdateBlockY = (
    key: string,
    yValue: number,
    xValue: number,
    type: string,
    target: VinnyGramType,
    damage: number
  ) => {
    // DAMAGE LOGIC!!!!
    const blockIsLowEnough = yValue > CEILING_HEIGHT + 182;
    if (blockIsLowEnough && !isGameOver) {
      playPaperSound();
      handleCalcDamage(target, -damage);
      handleDestroyBlock(key);
    }
  };

  const generateBlocks = () => {
    return blocks.map((block: CoffeeGramBlockType, i) => {
      return (
        <CoffeeGramBlock
          target={block.target}
          key={`${block.id}-${i}`}
          id={`${block.id}-${i}`}
          x={block.x}
          y={block.y}
          speed={FALLING_SPEED}
          image={block.image}
          damage={block.damage}
          type={block.type}
          updateBlockLocation={(
            xValue: number,
            yValue: number,
            type: string,
            target: VinnyGramType,
            damage: number
          ) =>
            handleUpdateBlockY(block.id, yValue, xValue, type, target, damage)
          }
          onDestroy={() => handleDestroyBlock(block.id)}
        ></CoffeeGramBlock>
      );
    });
  };

  const generateEndGameModal = () => {
    return (
      <Modal
        isVisible={showGameOverModal}
        onClose={() => {
          setIsGameOver(false);
        }}
      >
        <h1 className="victory-text">{`Not enough Coffee!`}</h1>
        <Button
          eventName={"Coffee Gram Play Again Button"}
          variant="primary"
          onClick={() => {
            startNewGame();
          }}
        >
          Play Again
        </Button>
        <MFVLink />
      </Modal>
    );
  };

  const controlsDisabled =
    isCasting ||
    isGameOver ||
    isGlobalCooldown ||
    selectedCharacter === undefined ||
    vinnyHealth < 1;

  const generateControls = () => {
    return (
      <div className="coffee-gram-controls">
        <div className="coffee-gram-controls-grid">
        <Button
          disabled={controlsDisabled}
          variant="primary"
          onClick={() => handleUseRoast(CoffeeGramHeals.lightRoast)}
        >
          Light
        </Button>
        <Button
          disabled={controlsDisabled}
          variant="orange"
          onClick={() => handleUseRoast(CoffeeGramHeals.heavyRoast)}
        >
          Heavy
        </Button>
        <Button
          disabled={controlsDisabled}
          variant="secondary"
          onClick={() => handleUseRoast(CoffeeGramHeals.decaf)}
        >
          Decaf
        </Button>
      </div>
      <div className="coffee-gram-castbar">
          <HealthBar value={castProgress} maxWidth={100} style={{ width: "100px" }} name={"Vinny"}></HealthBar>
        </div>
      </div>
      
    );
  };

  const generateHealthBars = () => {
    return (
      <>
        <HealthBar
          name="Vinny"
          value={vinnyHealth}
          maxWidth={90}
          style={{
            position: "absolute",
            top: HEALTH_BAR_TOP,
            left: vinnyX,
            width: "90px",
            height: "24px",
          }}
          height="24px"
        ></HealthBar>
        <HealthBar
          name="Gram"
          value={gramHealth}
          maxWidth={90}
          style={{
            position: "absolute",
            top: HEALTH_BAR_TOP,
            left: gramX,
            width: "90px",
            height: "24px",
          }}
          height="24px"
        ></HealthBar>
      </>
    );
  };

  const handleSelectVinny = () => {
    if (vinnyHealth > 0) {
      playClick();
      setSelectedCharacter("Vinny");
    }
  };

  const handleSelectGram = () => {
    if (gramHealth > 0) {
      playClick();
      setSelectedCharacter("Gram");
    }
  };

  const calcVinnyState = () => {
    if (vinnyHealth === 0) return "lose"
    if (isCasting) return "special"
    if (isGlobalCooldown) return "throw"
    return "idle"
  }

  return (
    <div className="main-container">
      <div style={{ display: "grid" }}>
        <h3 style={{ textAlign: "center" }} className="main-jump-header">
          Coffee Gram
        </h3>
        <div className="coffee-gram-game-container">
          <div
            className={"coffee-gram-background"}
            style={{
              background: `url(${gameBackground})`,
              backgroundSize: "cover",
            }}
          ></div>
          <div className="top-jump-bar"></div>
          {generateHealthBars()}
          <CoffeeGramCharacter
            playerName="Vinny"
            x={vinnyX}
            y={VINNY_STARTING_TOP}
            isStandingStill={false}
            state={calcVinnyState()}
            isFacingRight={isFacingRight}
            isSelected={selectedCharacter === "Vinny"}
            onClick={() => handleSelectVinny()}
            risingText={vinnyRisingText}
            risingIcon={vinnyRisingIcon}
          ></CoffeeGramCharacter>
          <CoffeeGramCharacter
            playerName="Gram"
            x={gramX}
            y={GRAM_STARTING_TOP}
            isStandingStill={false}
            state={gramHealth > 0 ? "idle" : "lose"}
            isFacingRight={isFacingRight}
            isSelected={selectedCharacter === "Gram"}
            onClick={() => handleSelectGram()}
            risingText={gramRisingText}
            risingIcon={gramRisingIcon}
          ></CoffeeGramCharacter>
          {generateBlocks()}
        </div>
        {generateControls()}
        {generateEndGameModal()}
      </div>
    </div>
  );
};

export default CoffeeGramPage;
