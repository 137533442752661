import "../Css/BottomNav.css";
import Button from "./Button";
import useSound from "use-sound";
// import boop from "../../sounds/GAMES_BOOP.mp3";
import laugh from "../../sounds/gramlaugh1.mp3";
import insta from "../../img/insta2Small.png";
import tiktok from "../../img/tiktok.png";
import youtube from "../../img/youtubeSmall.png";
// import { Route } from "../../utils/Types";
import click from "../../sounds/click.mp3";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VolumeControl from "../VolumeControl";
import { useContext, useEffect } from "react";
import { VolumeContext } from "../../Routes";
import { firstTimeLocalStorage, isOnVideosPage } from "../../utils/Utils";

interface BottomNavProps {
  page: string;
  children?: any;
  showPage: (page: string) => void;
  playNextVideo: () => void;
}

const BottomNav = ({
  page,
  children,
  showPage,
  playNextVideo,
}: BottomNavProps) => {
  const globalVolume = useContext(VolumeContext).globalVolume;
  const setGlobalVolume = useContext(VolumeContext).setGlobalVolume;
  const [playLaugh] = useSound(laugh, { volume: globalVolume });
  const [playClick] = useSound(click, { volume: globalVolume });

  const handlePlayNextVideo = () => {
    playNextVideo();
  };

  const handleGoBackToVideos = () => {
    playClick();

    setTimeout(() => {
      window.history.pushState({ urlPath: "/" }, "", "/");
      showPage("/videos");
    }, 300);
  };

  const handleGotoStore = () => {
    playClick();

    setTimeout(() => {
      window.history.pushState({ urlPath: "/store" }, "", "/store");
      showPage("/store");
    }, 310);
  };

  const handleGotoGames = () => {
    playClick();

    setTimeout(() => {
      window.history.pushState({ urlPath: "/games" }, "", "/games");
      showPage("/games");
    }, 310);
  };

  const handleMute = () => {
    if (!setGlobalVolume) return;
    if (globalVolume === 0) {
      setGlobalVolume(0.24);
    } else {
      setGlobalVolume(0);
    }

    playClick();
  };

  return (
    <div className="bottomGridContainer">
      <div
        style={{
          justifySelf: "start",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "2px",
        }}
      >
        <Button
          eventName={"Games Button"}
          style={{
            justifySelf: "end",
            borderRadius: "6px 6px 6px 0",
            filter: page === "/games" ? "opacity(60%)" : "",
          }}
          variant="orange"
          onClick={handleGotoGames}
        >
          Games
        </Button>
        <Button
          eventName={"Store Button"}
          style={{
            justifySelf: "start",
            borderRadius: "6px 0 6px 6px",
            filter: page === "/store" ? "opacity(60%)" : "",
          }}
          variant="secondary"
          onClick={handleGotoStore}
        >
          Store
        </Button>
      </div>
      <div className="center-container">
        <a
          onClick={() => playLaugh()}
          className="followGramLink"
          href="https://www.youtube.com/channel/UCWAuhU21divh947XCNU4kFQ?sub_confirmation=1"
          target="_blank"
          rel="noreferrer noopener"
          data-umami-event="Follow Gram Link"
        >
          <span className="followGramText">Follow Gram</span>
        </a>
        <div className="iconsContainer">
          <a
            href="https://www.youtube.com/channel/UCWAuhU21divh947XCNU4kFQ"
            target="_blank"
            rel="noreferrer noopener"
            data-umami-event="Youtube Link"
          >
            <img
              src={youtube}
              alt={"Funny Videos on Youtube"}
              height="29px"
              width="29px"
              style={{ marginTop: "4px" }}
            ></img>
          </a>
          <a
            href="https://www.tiktok.com/@vinnyviti"
            target="_blank"
            rel="noreferrer noopener"
            data-umami-event="TikTok Link"
          >
            <img
              src={tiktok}
              alt={"Funny videos on Tiktok"}
              height="30px"
              width="30px"
              style={{ marginLeft: "6px", marginTop: "3px" }}
            ></img>
          </a>
          <a
            href="https://www.instagram.com/vinnyviti/"
            target="_blank"
            rel="noreferrer noopener"
            data-umami-event="Instagram Link"
          >
            <img
              src={insta}
              alt={"Funny videos on Instagram"}
              height="38px"
              width="38px"
            ></img>
          </a>
        </div>
      </div>
      <div className={"bottom-nav-volume-button-grid-videos"}>
        <div className="volume-icon">
            <VolumeControl
              topRight={page === "/videos" || page === "/"}
              onClick={() => handleMute()}
            ></VolumeControl>
        </div>
        <Button
          eventName={isOnVideosPage(page) ? "Next Video Button" : "Videos Button"}
          variant="primary"
          style={{
            width: "124px",
            borderRadius: "0 6px 6px 6px",
          }}
          onClick={
            isOnVideosPage(page) ? handlePlayNextVideo : handleGoBackToVideos
          }
        >
          {isOnVideosPage(page) ? `Next Video` : `Videos`}
          <FontAwesomeIcon
            className="arrow-icon"
            icon={faArrowRight}
          ></FontAwesomeIcon>
        </Button>
      </div>
    </div>
  );
};

export default BottomNav;
