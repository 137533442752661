/* eslint-disable react-hooks/exhaustive-deps */
import "./DropGramCharacter.css";
import run1 from "../../../img/jumpgram/gram/run1.png";
import run2 from "../../../img/jumpgram/gram/run2.png";
import run3 from "../../../img/jumpgram/gram/run3.png";

import trip1 from "../../../img/jumpgram/gram/trip1.png";
import trip2 from "../../../img/jumpgram/gram/trip2.png";
import trip3 from "../../../img/jumpgram/gram/trip3.png";

import { useEffect, useState } from "react";

const gramRunImages = [run1, run2, run3, run2];
const gramTripImages = [trip3, trip1, trip2];

interface DropGramProps {
  x: number;
  y: number;
  ref?: React.RefObject<HTMLDivElement>;
  state: "run" | "lose";
  isFacingRight: boolean;
  isStandingStill: boolean;
}

const DropGramCharacter = ({
  x,
  y,
  ref,
  state,
  isFacingRight,
  isStandingStill,
}: DropGramProps) => {
  const [animationIndex, setAnimationIndex] = useState<number>(0);
  const [timeTick, setTimeTick] = useState<number>(0);
  const [timers, setTimers] = useState<NodeJS.Timeout[]>([]);

  useEffect(() => {
    // if (isActive) {
    setTimers([
      ...timers,
      setTimeout(() => {
        setTimeTick((prev) => prev + 1);

        if (!isStandingStill) {
          setAnimationIndex((prev) => prev + 1);
        }
      }, 180),
    ]);

    // }
  }, [timeTick]);

  const clearTimers = () => {
    timers.forEach((timer) => {
      clearTimeout(timer);
    });
  };

  useEffect(() => {
    if (
      state === "lose" &&
      animationIndex % gramTripImages.length === gramTripImages.length
    ) {
      clearTimers();
    }
  }, [state]);

  useEffect(() => {
    setAnimationIndex(0);
  }, [state]);

  const calcPlayerImg = (state: string, animationIndex: number) => {
    switch (state) {
      case "run":
        return gramRunImages[animationIndex % gramRunImages.length];
      case "lose":
        return gramTripImages[
          animationIndex >= gramTripImages.length
            ? gramTripImages.length - 1
            : animationIndex
        ];
      default:
        return gramRunImages[animationIndex % gramRunImages.length];
    }
  };

  return (
    <div
      className="drop-gram-character"
      ref={ref}
      style={{
        top: `${y}px`,
        left: `${x}px`,
      }}
    >
      <img
        height="125px"
        src={calcPlayerImg(state, animationIndex)}
        style={{ transform: isFacingRight ? "scaleX(-1)" : "scaleX(1)" }}
        className={`drop-gram-character-img`}
        alt="Gram"
      ></img>
    </div>
  );
};

export default DropGramCharacter;
