import { Video } from "./Types";

  export const DefaultVideos: Video[] = [
    {
      name: "Gram Pilot",
      youtubeId: "KzyT0KYpTOw",
      releaseDate: new Date(),
      length: 60,
      likes: 0,
      views: 0,
      comments: 0,
    },
  ]