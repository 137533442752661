import "../../../Css/PlayerDisplay.css";
import Button from "../../../Small/Button";
import { CardModel, StateString } from "./TCGTypes";
import HealthBar from "./HealthBar";
import RisingText from "./RisingText";
import { getRandomInt } from "../../../../utils/Utils";
import { useEffect, useState } from "react";
import { calcAnimeGramImg, calcGramImg, calcVinnyImg, getCritChanceText } from "./TCGUtils";
import { VinnyGramType } from "../../../../utils/Types";

const calcPlayerImg = (
  name: string,
  state: StateString,
  animationIndex: number
) => {
  switch (name) {
    case "Gram":
      return calcGramImg(state, animationIndex);
    case "Vinny":
      return calcVinnyImg(state, animationIndex);
    case "Anime Gram":
      return calcAnimeGramImg(state, animationIndex);
    default:
      return calcVinnyImg(state, animationIndex);
  }
};

export interface PlayerDisplayProps {
  card: CardModel;
  isActive: boolean;
  onClickLightAttack: () => void;
  onClickHeavyAttack: () => void;
  onClickHeal: () => void;
  children?: any;
  img?: string;
  state: StateString;
  isFacingLeft?: boolean;
  onUseAbility?: () => void;
  risingText?: string | undefined;
  risingIcon?: string | undefined;
  winTally: number;
  isBot: boolean;
  timesEaten: number;
  hideHealthBar?: boolean;
}

const randomBobSpeed = () => (getRandomInt(20) + 5) / 15;

const PlayerDisplay = ({
  card,
  isActive,
  onClickLightAttack,
  onClickHeavyAttack,
  onClickHeal,
  children,
  img,
  state,
  isFacingLeft,
  risingText,
  risingIcon,
  winTally,
  isBot,
  timesEaten,
  onUseAbility,
  hideHealthBar,
}: PlayerDisplayProps) => {
  const [bobSpeed, setBobSpeed] = useState<number>(randomBobSpeed());
  const [animationIndex, setAnimationIndex] = useState<number>(0);
  const [timeTick, setTimeTick] = useState<number>(0);

  useEffect(() => {
    // if (isActive) {
    setTimeout(() => {
      setTimeTick((prev) => prev + 1);
      setAnimationIndex((prev) => prev + 1);
    }, 800);
    // }
  }, [timeTick]);

  useEffect(() => {
    setBobSpeed(randomBobSpeed());
  }, [isActive]);

  useEffect(() => {
    setBobSpeed(randomBobSpeed());
  }, [isActive]);

  useEffect(() => {
    setAnimationIndex(0);
  }, [state]);

  const handleUseAbility = () => {
    if (onUseAbility) {
      setBobSpeed(randomBobSpeed());
      onUseAbility();
    }
  };

  const handleClickHeavy = () => {
    onClickHeavyAttack();
    setBobSpeed(randomBobSpeed());
  };

  const handleClickLight = () => {
    onClickLightAttack();
    setBobSpeed(randomBobSpeed());
  };

  const handleClickImg = () => {
    // if (isActive) {
    //     onClickLightAttack();
    // }
  };

  const calcNameColor = () => {
    switch (card.name) {
      case "Gram":
        return "#DFC523";
      case "Vinny":
        return "lightblue";
      default:
        return "white";
    }
  };

  return (
    <div
      className="player-container"
      style={{
        cursor: "pointer",
      }}
    >
      <div className="player-vertical-grid">
        {!hideHealthBar && (
          <div className="horizontal-grid">
            <h1
              className="player-name"
              style={{
                justifyItems: isFacingLeft ? "end" : "start",
                justifyContent: isFacingLeft ? "end" : "start",
                color: calcNameColor(),
                textAlign: isFacingLeft ? "end" : "start",
              }}
            >
              {card.name} {isBot && `CPU`} {winTally > 0 && `(${winTally})`}
            </h1>
            <HealthBar
              name={card.name as VinnyGramType}
              value={card.hp}
              maxWidth={100}
              // style={{ justifySelf: isFacingLeft ? "start" : "none", position: "relative" }}
            ></HealthBar>
          </div>
        )}
        <RisingText value={risingText}></RisingText>
        <RisingText value={risingIcon} isIcon></RisingText>
        <div className={isFacingLeft ? `slide-in-right` : "slide-in-left"}>
          <div
            style={{
              animation:
                isActive && state === "idle"
                  ? `shaking ${bobSpeed}s infinite ease-in-out`
                  : "none",
            }}
          >
            <img
              className="player-img"
              alt="Gram in Jail"
              src={calcPlayerImg(card.name, state, animationIndex)}
              onClick={handleClickImg}
              style={{
                transform: isFacingLeft ? "scaleX(1)" : "scaleX(-1)",
              }}
            ></img>
          </div>
        </div>
      </div>
      {!isBot && (
        <div className="button-grid">
          <h1 className="crit-text">
            {getCritChanceText(card.hp)} crit chance
          </h1>
          <Button
            eventName={"Gram TCG Ability Button"}
            variant="secondary"
            disabled={card.abilityId === 0 || state !== "idle" || !isActive}
            onClick={() => handleUseAbility()}
            style={{ width: "120px", height: "36px" }}
          >
            Ability
          </Button>
          <Button
            eventName={"Gram TCG Heal Button"}
            variant="green"
            disabled={state !== "idle" || card.hp > 98 || !isActive}
            onClick={onClickHeal}
            style={{ width: "120px", height: "36px" }}
          >
            Heal
          </Button>
          <Button
            eventName={"Gram TCG Light Attack Button"}
            variant="primary"
            disabled={state !== "idle" || !isActive}
            onClick={handleClickLight}
            style={{ width: "120px", height: "36px" }}
          >
            Light Attack
          </Button>
          <Button
            eventName={"Gram TCG Heavy Attack Button"}
            variant="orange"
            disabled={state !== "idle" || !isActive}
            onClick={handleClickHeavy}
            style={{ width: "120px", height: "36px" }}
          >
            Heavy Attack
          </Button>
        </div>
      )}
      {children}
    </div>
  );
};

export default PlayerDisplay;
