/* eslint-disable react-hooks/exhaustive-deps */
import { coolClone, getRandomInt } from "../../../utils/Utils";
import "../../Css/TCGPage.css";
import Button from "../../Small/Button";
import clickSound from "../../../sounds/click.mp3";
import missSound from "../../../sounds/tcg/items/miss.mp3";
import hungOnSound from "../../../sounds/tcg/items/crit.mp3";
import critSound from "../../../sounds/tcg/items/crit.mp3";
import winMusic from "../../../sounds/tcg/WinGameOST.mp3";
import loseMusic from "../../../sounds/tcg/LoseGameOST.mp3";
import useSound from "use-sound";
import { useContext, useEffect, useState } from "react";
import {
  BlockType,
  Log,
  Move,
  MoveType,
  Player,
  StateString,
  Turn,
} from "./utils/TCGTypes";
import Modal from "../../Small/Modal";
import {
  calcEndPhrase,
  calcLogStringTypeFromCard,
  calcMarkFromCard,
  calcStartingPlayer,
  getChanceToBeat,
  getStartingLog,
  getWeightedMoves,
  logMessage,
} from "./utils/TCGUtils";
import PlayerDisplay from "./utils/PlayerDisplay";
import TCGBlock from "./utils/TCGBlock";
import {
  ATTACK_WEAKENER,
  CRIT_MULTIPLIER,
  FamousDocuments,
  FamousMovies,
  GRAM_CRIT_MULTIPLIER,
  LogStrings,
  StateStrings,
} from "./utils/TCGConstants";
import styled from "styled-components";
import LogContainer from "./utils/LogContainer";
import {
  GramHeavyMoves,
  GramLightMoves,
  HealMoves,
  HeavyMoves,
  LightMoves,
  VinnyHeavyMoves,
  VinnyLightMoves,
  gramSpecialMove,
  vinnySpecialMove,
} from "./utils/TCGMoves";
import MFVLink from "./MFVLink";
import { VolumeContext } from "../../../Routes";

interface StyledDivProps {
  bg: string;
}

const StyleDiv = styled.div`
  display: grid;
  overflow: hidden;
  position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  justify-self: center;
  max-width: 760px;
  height: 556px;
  background-position: center;
  background-size: 100% 100%;
  background-image: url(${(props: StyledDivProps) => props.bg});
  backdrop-filter: brightness(150%);
`;

export interface TradingCardGameProps {
  player1: Player;
  player2: Player;
  background: HTMLImageElement;
  moveImages: HTMLImageElement[];
  // onFinished: () => void;
  onRestartGame: () => void;
}

const TradingCardGame = ({
  player1,
  player2,
  background,
  // onFinished,
  onRestartGame,
  moveImages,
}: TradingCardGameProps) => {
  const globalVolume = useContext(VolumeContext).globalVolume;
  const [playClick] = useSound(clickSound, { volume: globalVolume });
  const [playMiss] = useSound(missSound, { volume: globalVolume });
  const [playCrit] = useSound(critSound, { volume: globalVolume });
  const [playHungOn] = useSound(hungOnSound, { volume: globalVolume });
  const [playWinMusic] = useSound(winMusic, { volume: globalVolume });
  const [playLoseMusic] = useSound(loseMusic, { volume: globalVolume });

  const [log, setLog] = useState<Log[]>(getStartingLog());
  const [turns, setTurns] = useState<Turn[]>([]);
  const [showGameOverModal, setShowGameOverModal] = useState<boolean>(false);
  const [isGameOver, setIsGameOver] = useState<boolean>(false);
  const [hasSeenModal, setHasSeenModal] = useState<boolean>(false);
  const [isGameStarted, setIsGameStarted] = useState<boolean>(true);
  const [isTakingTurn, setIsTakingTurn] = useState<boolean>(false);
  const [blocks, setBlocks] = useState<BlockType[]>([]);
  const [playerOne, setPlayerOne] = useState<Player>(coolClone(player1));
  const [playerTwo, setPlayerTwo] = useState<Player>(coolClone(player2));

  const [risingTextLeft, setRisingTextLeft] = useState<string>("");
  const [risingTextRight, setRisingTextRight] = useState<string>("");
  const [risingIconLeft, setRisingIconLeft] = useState<string>("");
  const [risingIconRight, setRisingIconRight] = useState<string>("");

  const isPlayerOneTurn = turns.length
    ? !turns[turns.length - 1].isPlayerOne
    : calcStartingPlayer(playerOne);

  const onSetLog = (newLog: Log) => {
    setLog((prev) => [...prev, newLog]);
  };

  useEffect(() => {
    // ETHAN, create some kind of visible log here
    if (log.length) {
      console.log(log);
      // alert(log[log.length].value);
    }
  }, [log]);

  const calcDamage = (turn: Turn, isMiss: boolean, isCrit: boolean) => {
    if (!isGameStarted || !turn.move) {
      return false;
    }

    let moveName = turn.move.name;
    if (turn.move.id === "famousDocument") {
      const rand = getRandomInt(FamousDocuments.length);
      moveName = FamousDocuments[rand];
    }

    if (turn.move.id === "famousMovie") {
      const rand = getRandomInt(FamousMovies.length);
      moveName = FamousMovies[rand];
    }

    // Logging
    switch (turn.move.type) {
      case "gramspecial":
        logMessage(
          <p>
            <mark className="gram-mark">Gram</mark> shreds his guitar!
          </p>,
          onSetLog,
          LogStrings.Gram
        );
        break;
      case "vinnyspecial":
        logMessage(
          <p>
            <mark className="vinny-mark">Vinny</mark> likes funny videos!
          </p>,
          onSetLog,
          LogStrings.Vinny
        );
        break;
      case "heal":
        logMessage(
          <p>
            <mark className={calcMarkFromCard(turn.source.card)}>
              {turn.source.card.name}
            </mark>{" "}
            eats {moveName}.
          </p>,
          onSetLog,
          calcLogStringTypeFromCard(turn.source.card)
        );
        break;
      default:
        logMessage(
          <p>
            <mark className={calcMarkFromCard(turn.source.card)}>
              {turn.source.card.name}
            </mark>{" "}
            threw {moveName}!!
          </p>,
          onSetLog,
          calcLogStringTypeFromCard(turn.source.card)
        );
    }

    let damage = turn.move.strength;
    let sourceCard = { ...turn.source.card };
    let targetCard = { ...turn.target.card };

    const healRand = getRandomInt(-damage / 2);

    //Diminishing returns on heal amount
    let healAmount = -damage;
    if (turn.move.type === "heal") {
      healAmount = Math.floor(
        (-damage + healRand) / (turn.source.timesEaten * 1.5 + 1)
      );
    }

    if (turn.move.type === "heal" || turn.move.type === "vinnyspecial") {
      // Heal Crit logic
      if (
        isCrit &&
        turn.move.type !== "vinnyspecial" &&
        turn.move.id !== "pepper"
      ) {
        damage = Math.ceil(damage * CRIT_MULTIPLIER);
        // Todo: crit heal sound?
        // playCrit();
      }

      const sound = new Audio(turn.move?.soundSrc);
      sound.volume = globalVolume;
      sound.play();
      let newHealth = sourceCard.hp + healAmount;
      if (newHealth > 100) {
        logMessage(
          <p>
            <mark className={calcMarkFromCard(turn.source.card)}>
              {turn.source.card.name}
            </mark>{" "}
            ate too much.
          </p>,
          onSetLog,
          calcLogStringTypeFromCard(turn.source.card)
        );
        newHealth = 100;
      }
      sourceCard.hp = newHealth;
      if (healAmount > 0) {
        logMessage(
          <p>
            <mark className={calcMarkFromCard(turn.source.card)}>
              {turn.source.card.name}
            </mark>{" "}
            healed for{" "}
            <mark className={isCrit ? "green-mark" : "green-mark-dark"}>
              {healAmount}
              {isCrit && " Extra"}
            </mark>
            !
          </p>,
          onSetLog,
          calcLogStringTypeFromCard(turn.source.card),
          100
        );
      } else {
        logMessage(
          <p>
            <mark className={calcMarkFromCard(turn.source.card)}>
              {turn.source.card.name}
            </mark>{" "}
            took{" "}
            <mark className={isCrit ? "red-mark" : "red-mark-dark"}>
              {healAmount}
              {isCrit && " Crit"} damage
            </mark>
            !
          </p>,
          onSetLog,
          calcLogStringTypeFromCard(turn.source.card),
          100
        );
      }
    } else {
      // Miss logic
      if (isMiss) {
        damage = 0;
        logMessage(
          <p>
            It <mark className="miss-mark">missed!</mark>
          </p>,
          onSetLog,
          calcLogStringTypeFromCard(turn.source.card),
          600
        );
        playMiss();
      } else {
        // Crit logic
        if (isCrit && turn.move.type === "gramspecial") {
          damage = Math.ceil(damage * CRIT_MULTIPLIER * GRAM_CRIT_MULTIPLIER);
        } else if (isCrit) {
          damage = Math.ceil(damage * CRIT_MULTIPLIER);
          playCrit();
        }
        logMessage(
          <p>
            <mark className={calcMarkFromCard(turn.target.card)}>
              {turn.target.card.name}
            </mark>{" "}
            took{" "}
            <mark className={isCrit ? "red-mark" : "red-mark-dark"}>
              {damage}
              {isCrit && " Crit"}
            </mark>{" "}
            damage!
          </p>,
          onSetLog,
          calcLogStringTypeFromCard(turn.target.card),
          600
        );
        const sound = new Audio(turn.move?.soundSrc);
        sound.volume = globalVolume;
        sound.play();
      }

      // Normal damage logic
      targetCard.hp = targetCard.hp - damage;
    }

    //HUNG ON LOGIC
    //can survive if non crit
    let rollToSurvive = 90;

    if (isCrit) {
      rollToSurvive = 85;
    }

    if (turn.target.card.hp === 1) {
      rollToSurvive = 96;
    }
    let isStillAlive = false;

    // Super secret Vinny Special
    if (turn.target.card.abilityId === 1 && !isCrit) {
      rollToSurvive = 0;
    }

    //For the hot pepper at 1 health
    if (sourceCard.hp < 0) {
      sourceCard.hp = 0;
    }

    if (targetCard.hp < 0) {
      targetCard.hp = 0;
      let lastStand = getRandomInt(100);

      if (lastStand > rollToSurvive) {
        isStillAlive = true;
        targetCard.hp = 1;
        playHungOn();
        logMessage(
          <p>
            <mark className={calcMarkFromCard(turn.target.card)}>
              {turn.target.card.name}
            </mark>{" "}
            <mark className="orange-mark">hung on with 1HP!</mark>
          </p>,
          onSetLog,
          calcLogStringTypeFromCard(turn.target.card),
          500
        );
      }
    }

    const isVinnySpecial = turn.move.type === "vinnyspecial";

    if (turn.move.type === "heal" || isVinnySpecial) {
      const healText = `${healAmount > 0 ? "+" : ""}${healAmount.toString()}`;

      if (isVinnySpecial) {
        sourceCard.attack = healAmount;
        sourceCard.abilityId = 0;
      }

      if (turn.target === playerTwo) {
        setRisingTextLeft(healText);

        if (isMiss) {
          setRisingIconLeft("miss");
        } else if (isStillAlive) {
          setRisingIconLeft("stillAlive");
        } else if (isCrit && healAmount > 0) {
          setRisingIconLeft("extra");
        } else if (isCrit && healAmount < 1) {
          setRisingIconLeft("crit");
        }

        setPlayerOne((prev) => {
          return {
            name: prev.name,
            card: sourceCard,
            state: prev.state,
            isBot: prev.isBot,
            timesEaten: isVinnySpecial ? prev.timesEaten : prev.timesEaten + 1,
            winTally: prev.winTally,
          };
        });
      } else {
        setRisingTextRight(healText);
        if (isMiss) {
          setRisingIconRight("miss");
        } else if (isStillAlive) {
          setRisingIconRight("stillAlive");
        } else if (isCrit && healAmount > 0) {
          setRisingIconRight("extra");
        } else if (isCrit && healAmount < 1) {
          setRisingIconRight("crit");
        }

        setPlayerTwo((prev) => {
          return {
            name: prev.name,
            card: sourceCard,
            state: prev.state,
            isBot: prev.isBot,
            timesEaten: isVinnySpecial ? prev.timesEaten : prev.timesEaten + 1,
            winTally: prev.winTally,
          };
        });
      }
    } else {
      // If damage, set text
      const damageText = `-${damage.toString()}`;

      if (turn.target === playerOne) {
        if (isMiss) {
          setRisingIconLeft("miss");
        } else if (isCrit) {
          setRisingIconLeft("crit");
        } else if (isStillAlive) {
          setRisingIconLeft("stillAlive");
        }

        if (!isMiss) {
          setRisingTextLeft(damageText);
        }

        setPlayerOne((prev) => {
          return {
            name: prev.name,
            card: targetCard,
            state: damage > 0 ? StateStrings.Hurt : StateStrings.Idle,
            timesEaten: prev.timesEaten,
            isBot: prev.isBot,
            winTally: prev.winTally,
          };
        });

        if (turn.move.type === "gramspecial") {
          sourceCard.abilityId = 0;
          setPlayerTwo((prev) => {
            return {
              name: prev.name,
              card: sourceCard,
              state: prev.state,
              timesEaten: prev.timesEaten,
              isBot: prev.isBot,
              winTally: prev.winTally,
            };
          });
        }
      } else {
        if (isMiss) {
          setRisingIconRight("miss");
        } else if (isCrit) {
          setRisingIconRight("crit");
        } else if (isStillAlive) {
          setRisingIconRight("stillAlive");
        }

        if (!isMiss) {
          setRisingTextRight(damageText);
        }

        setPlayerTwo((prev) => {
          return {
            name: prev.name,
            card: targetCard,
            state: damage > 0 ? StateStrings.Hurt : StateStrings.Idle,
            timesEaten: prev.timesEaten,
            isBot: prev.isBot,
            winTally: prev.winTally,
          };
        });
        if (turn.move.type === "gramspecial") {
          sourceCard.abilityId = 0;
          setPlayerOne((prev) => {
            return {
              name: prev.name,
              card: sourceCard,
              state: prev.state,
              timesEaten: prev.timesEaten,
              isBot: prev.isBot,
              winTally: prev.winTally,
            };
          });
        }
      }
    }

    // LOSE GAME LOGIC
    if (targetCard.hp === 0 || sourceCard.hp === 0) {
      setBlocks([]);
      setIsGameOver(true);
      setTimeout(() => {
        // setTimeout(() => {
        setShowGameOverModal(true);
        setHasSeenModal(true);
        // }, 1500)
      }, 1800);
      if (turn.target === playerOne) {
        if (turn.move.type === "heal") {
          //LOSE FROM PEPPER LOGIC
          playWinMusic();
          setPlayerOne((prev) => {
            return {
              name: prev.name,
              card: targetCard,
              state: StateStrings.Win,
              timesEaten: prev.timesEaten,
              isBot: prev.isBot,
              winTally: prev.winTally + 1,
            };
          });
          setPlayerTwo((prev) => {
            return {
              name: prev.name,
              card: sourceCard,
              state: StateStrings.Lose,
              isBot: prev.isBot,
              timesEaten: prev.timesEaten,
              winTally: prev.winTally,
            };
          });
        } else {
          // NORMAL LOSE LOGIC
          playLoseMusic();
          setPlayerOne((prev) => {
            return {
              name: prev.name,
              card: targetCard,
              state: StateStrings.Lose,
              timesEaten: prev.timesEaten,
              isBot: prev.isBot,
              winTally: prev.winTally,
            };
          });
          setPlayerTwo((prev) => {
            return {
              name: prev.name,
              card: sourceCard,
              state: StateStrings.Win,
              timesEaten: prev.timesEaten,
              isBot: prev.isBot,
              winTally: prev.winTally + 1,
            };
          });
        }

        logMessage(
          <p>
            <mark className={calcMarkFromCard(turn.target.card)}>
              {turn.target.card.name}
            </mark>{" "}
            ran out of health {calcEndPhrase()}
          </p>,
          onSetLog,
          calcLogStringTypeFromCard(turn.target.card),
          600
        );
      } else {
        if (turn.move.type === "heal") {
          // LOSE FROM PEPPER LOGIC
          playLoseMusic();
          setPlayerOne((prev) => {
            return {
              name: prev.name,
              card: sourceCard,
              state: StateStrings.Lose,
              timesEaten: prev.timesEaten,
              isBot: prev.isBot,
              winTally: prev.winTally,
            };
          });
          setPlayerTwo((prev) => {
            return {
              name: prev.name,
              card: targetCard,
              state: StateStrings.Win,
              timesEaten: prev.timesEaten,
              isBot: prev.isBot,
              winTally: prev.winTally + 1,
            };
          });
          logMessage(
            <p>
              <mark className={calcMarkFromCard(turn.source.card)}>
                {turn.source.card.name}
              </mark>{" "}
              ate too much, {calcEndPhrase()}
            </p>,
            onSetLog,
            calcLogStringTypeFromCard(turn.target.card),
            600
          );
        } else {
          // NORMAL LOSE LOGIC
          playWinMusic();
          setPlayerOne((prev) => {
            return {
              name: prev.name,
              card: sourceCard,
              state: StateStrings.Win,
              timesEaten: prev.timesEaten,
              isBot: prev.isBot,
              winTally: prev.winTally + 1,
            };
          });
          setPlayerTwo((prev) => {
            return {
              name: prev.name,
              card: targetCard,
              state: StateStrings.Lose,
              timesEaten: prev.timesEaten,
              isBot: prev.isBot,
              winTally: prev.winTally,
            };
          });
          logMessage(
            <p>
              <mark className={calcMarkFromCard(turn.target.card)}>
                {turn.target.card.name}
              </mark>{" "}
              ran out of health {calcEndPhrase()}
            </p>,
            onSetLog,
            calcLogStringTypeFromCard(turn.target.card),
            600
          );
        }
      }
      return true;
    }
    return false;
  };

  const calcMove = (moveType: MoveType): Move => {
    let source = playerTwo;
    if (isPlayerOneTurn) {
      source = playerOne;
    }

    let moves: Move[] = [];
    switch (moveType) {
      case "light":
        if (source.card.name === "Vinny") {
          moves = [...LightMoves, ...VinnyLightMoves];
        }

        if (source.card.name === "Gram") {
          moves = [...LightMoves, ...GramLightMoves];
        }
        break;
      case "heavy":
        if (source.card.name === "Vinny") {
          moves = [...HeavyMoves, ...VinnyHeavyMoves];
        }

        if (source.card.name === "Gram") {
          moves = [...HeavyMoves, ...GramHeavyMoves];
        }
        break;
      case "gramspecial":
        moves = [gramSpecialMove()];
        break;
      case "vinnyspecial":
        moves = [vinnySpecialMove()];
        break;
      case "heal":
        moves = [...HealMoves];
        break;
      default:
        moves = [...LightMoves];
        break;
    }

    if (moves.length === 0) {
      return LightMoves[0];
    }

    if (moves.length === 1) {
      return moves[0];
    } else {
      const weightedMoves = getWeightedMoves(source.card, moves);
      const randIndex = getRandomInt(weightedMoves.length);
      const move: Move = weightedMoves[randIndex];
      return move;
    }
  };

  const calcMissVertSpeed = () => {
    return -5;
  };

  const calcMoveImg = (move: Move): HTMLImageElement => {
    let imgSrc = move.imgSrc;

    for (let i = 0; i < moveImages.length; i++) {
      const moveImg = moveImages[i];
      if (moveImg.src.includes(imgSrc)) {
        return moveImg;
      }
    }
    return new Image();
  };

  const throwBlock = (turn: Turn, isVertical = false, isMiss: boolean) => {
    if (!turn.move) return;

    let speed = 19;
    if (isVertical) {
      speed = 13;
      const randY = 101;

      if (turn.move.type === "gramspecial") {
        if (turn.isPlayerOne) {
          // from top to bottom
          const newBlock: BlockType = {
            id: turn.num.toString(),
            x: 63,
            y: randY,
            vertSpeed: speed,
            horSpeed: 0,
            image: calcMoveImg(turn.move),
            size: turn.move.size,
            noRotate: true,
          };

          setBlocks((prev) => [...prev, newBlock]);
          return newBlock.id;
        } else {
          // top to bottom on the right
          const newBlock: BlockType = {
            id: turn.num.toString(),
            x: 20,
            y: randY,
            size: turn.move.size,
            vertSpeed: speed,
            horSpeed: 0,
            image: calcMoveImg(turn.move),
            noRotate: true,
          };
          setBlocks((prev) => [...prev, newBlock]);
          return newBlock.id;
        }
      } else {
        if (turn.isPlayerOne) {
          // from top to bottom
          const newBlock: BlockType = {
            id: turn.num.toString(),
            x: 20,
            y: randY,
            vertSpeed: speed,
            horSpeed: 0,
            image: calcMoveImg(turn.move),
            size: turn.move.size,
          };

          setBlocks((prev) => [...prev, newBlock]);
          return newBlock.id;
        } else {
          // top to bottom on the right
          const newBlock: BlockType = {
            id: turn.num.toString(),
            x: 63,
            y: randY,
            size: turn.move.size,
            vertSpeed: speed,
            horSpeed: 0,
            image: calcMoveImg(turn.move),
          };
          setBlocks((prev) => [...prev, newBlock]);
          return newBlock.id;
        }
      }
    } else {
      const randY = 73;

      if (turn.move.type === "heavy" || turn.move.type === "gramspecial") {
        speed = 14;
      }

      if (turn.isPlayerOne) {
        // go left to right
        const newBlock: BlockType = {
          id: turn.num.toString(),
          x: 20,
          y: randY,
          vertSpeed: isMiss
            ? calcMissVertSpeed()
            : turn.move.type === "heavy"
            ? 2
            : 2,
          horSpeed: -speed,
          image: calcMoveImg(turn.move),
          size: turn.move.size,
        };

        setBlocks((prev) => [...prev, newBlock]);
        return newBlock.id;
      } else {
        // go right to left
        const newBlock: BlockType = {
          id: turn.num.toString(),
          x: 65,
          y: randY,
          size: turn.move.size,
          vertSpeed: isMiss
            ? calcMissVertSpeed()
            : turn.move.type === "heavy"
            ? 2
            : 2,
          horSpeed: speed,
          image: calcMoveImg(turn.move),
        };
        setBlocks((prev) => [...prev, newBlock]);
        return newBlock.id;
      }
    }
  };

  const handleSetAnimationState = (isP1: boolean, state: StateString) => {
    if (isP1) {
      setPlayerOne((prev) => {
        return {
          name: prev.name,
          card: prev.card,
          winTally: prev.winTally,
          timesEaten: prev.timesEaten,
          isBot: prev.isBot,
          state,
        };
      });
    } else {
      setPlayerTwo((prev) => {
        return {
          name: prev.name,
          card: prev.card,
          timesEaten: prev.timesEaten,
          winTally: prev.winTally,
          isBot: prev.isBot,
          state,
        };
      });
    }
  };

  const handleTakeTurn = (moveType: MoveType) => {
    if (isGameOver || isTakingTurn || !isGameStarted) return;
    setRisingIconLeft("");
    setRisingIconRight("");
    setRisingTextLeft("");
    setRisingTextRight("");
    setIsTakingTurn(true);
    let target = playerOne;
    let source = playerTwo;
    if (isPlayerOneTurn) {
      target = playerTwo;
      source = playerOne;
    }

    let newMove = calcMove(moveType);

    if (newMove) {
      let newTurn: Turn = {
        num: turns.length + 1,
        isPlayerOne: isPlayerOneTurn,
        move: newMove,
        target,
        source,
      };

      const missRoll = getRandomInt(100);
      let critRoll = getRandomInt(100);

      let isMiss = newMove.accuracy - missRoll < 0;
      const attackMod = Math.ceil(newTurn.source.card.attack / ATTACK_WEAKENER);
      const totalChance = critRoll + attackMod;
      const chanceToBeat = getChanceToBeat(source.card.hp);
      let isCrit = totalChance > chanceToBeat;

      // if (moveType === "gramspecial") {
      //   isCrit =  (totalChance / 2) > chanceToBeat;
      // }

      //Vinny can't crit
      if (moveType === "vinnyspecial" || newMove.id === "pepper") {
        isCrit = false;
      }

      if (moveType === "vinnyspecial") {
        handleSetAnimationState(isPlayerOneTurn, StateStrings.Special);
        throwBlock(newTurn, true, isMiss);
      } else if (moveType === "heal") {
        throwBlock(newTurn, true, isMiss);
        handleSetAnimationState(isPlayerOneTurn, StateStrings.Heal);
      } else if (moveType === "gramspecial") {
        handleSetAnimationState(isPlayerOneTurn, StateStrings.Special);
        throwBlock(newTurn, true, isMiss);
      } else if (moveType === "animegramspecial") {
        handleSetAnimationState(isPlayerOneTurn, StateStrings.Special);
        throwBlock(newTurn, true, isMiss);
      } else {
        handleSetAnimationState(isPlayerOneTurn, StateStrings.Throw);
        throwBlock(newTurn, false, isMiss);
      }

      setTimeout(() => {
        const isWinState = calcDamage(newTurn, isMiss, isCrit);
        setTimeout(() => {
          setTurns((prev) => [...prev, newTurn]);

          if (!isWinState) {
            handleSetAnimationState(isPlayerOneTurn, StateStrings.Idle);
          }
          setTimeout(() => {
            if (!isWinState) {
              handleSetAnimationState(!isPlayerOneTurn, StateStrings.Idle);
            }
            setIsTakingTurn(false);
          }, 800);
        }, 500);
      }, 700);
    }
  };

  const cleanUpGame = () => {
    playClick();
    setShowGameOverModal(false);
    setLog(getStartingLog());
    setPlayerOne({ ...player1, winTally: playerOne.winTally });
    setPlayerTwo({ ...player2, winTally: playerTwo.winTally });
    setIsGameOver(false);
    setHasSeenModal(false);
    setBlocks([]);
    setRisingIconLeft("");
    setRisingIconRight("");
    setRisingTextLeft("");
    setRisingTextRight("");
    setTurns([]);
    setIsGameStarted(true);
  };

  const handleNewGame = () => {
    cleanUpGame();
    onRestartGame();
  };

  // const handleGoBackToSelect = () => {
  //   cleanUpGame();
  //   onFinished();
  // }

  const handleUseAbility = (isP1: boolean) => {
    let abilityUser = playerOne;
    if (!isP1) {
      abilityUser = playerTwo;
    }

    const abilityId = abilityUser.card.abilityId;

    switch (abilityId) {
      case 1:
        handleTakeTurn("vinnyspecial");
        break;
      case 2:
        handleTakeTurn("gramspecial");
        break;
      case 3:
        handleTakeTurn("animegramspecial");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isTakingTurn || !isGameStarted) return;

    let currentPlayer = playerTwo;
    let targetPlayer = playerOne;
    if (isPlayerOneTurn) {
      currentPlayer = playerOne;
      targetPlayer = playerTwo;
    }

    // BOT TURN LOGIC
    if (currentPlayer.isBot) {
      let randTime = getRandomInt(750);
      if (turns.length === 0) {
        randTime = randTime + 800;
      }

      setTimeout(() => {
        const rand = getRandomInt(100);

        //Vinny ability
        if (
          rand > 5 &&
          currentPlayer.card.name === "Vinny" &&
          currentPlayer.card.abilityId !== 0
        ) {
          handleUseAbility(isPlayerOneTurn);
          return;
        }

        if (
          rand > 75 &&
          currentPlayer.card.name === "Gram" &&
          currentPlayer.card.abilityId !== 0 &&
          currentPlayer.card.hp < 35
        ) {
          handleTakeTurn("gramspecial");
          return;
        }

        if (
          rand > 25 &&
          currentPlayer.card.name === "Gram" &&
          currentPlayer.card.abilityId !== 0 &&
          currentPlayer.card.hp < 25
        ) {
          handleTakeTurn("gramspecial");
          return;
        }

        if (
          rand > 10 &&
          currentPlayer.card.name === "Gram" &&
          currentPlayer.card.abilityId !== 0 &&
          currentPlayer.card.hp < 17
        ) {
          handleTakeTurn("gramspecial");
          return;
        }

        if (
          rand > 70 &&
          currentPlayer.card.hp < 45 &&
          currentPlayer.timesEaten < 1
        ) {
          handleTakeTurn("heal");
          return;
        }

        //Random extra heal maybe
        if (
          rand > 90 &&
          currentPlayer.card.hp < 58 &&
          currentPlayer.timesEaten < 3
        ) {
          handleTakeTurn("heal");
          return;
        }

        // if (currentPlayer.card.hp < 35 && rand > 10) {
        //   handleTakeTurn("light");
        // }

        // if (rand > 25 && currentPlayer.card.hp < 25) {
        //   if (rand > 30) {
        //     handleTakeTurn("light");
        //     return;
        //   }
        // }

        if (targetPlayer.card.hp < 22) {
          handleTakeTurn("light");
          return;
        }

        // more heavies when high health
        if (targetPlayer.card.hp > 35) {
          if (rand > 68) {
            handleTakeTurn("light");
            return;
          } else {
            handleTakeTurn("heavy");
            return;
          }
        }

        // More lights when low health
        if (rand > 34) {
          handleTakeTurn("light");
          return;
        }

        handleTakeTurn("heavy");
        return;
      }, 500 + randTime);
    }
  }, [turns, isTakingTurn]);

  const handleDestroyBlock = (blockId: string) => {
    const index = blocks.findIndex((block) => block.id === blockId);
    if (index > -1) {
      const newBlocks = [...blocks];
      newBlocks.splice(index, 1);
      setBlocks(newBlocks);
    }
  };

  const generateBlocks = () => {
    return blocks.map((block: BlockType, i) => {
      return (
        <TCGBlock
          id={block.id}
          x={block.x}
          y={block.y}
          horSpeed={block.horSpeed}
          vertSpeed={block.vertSpeed}
          image={block.image}
          onDestroy={() => handleDestroyBlock(block.id)}
          key={`${i}-block`}
          noRotate={block.noRotate}
          size={block.size}
        ></TCGBlock>
      );
    });
  };

  const generateP1 = () => {
    return (
      <PlayerDisplay
        card={playerOne.card}
        onClickLightAttack={() => handleTakeTurn("light")}
        onClickHeavyAttack={() => handleTakeTurn("heavy")}
        onClickHeal={() => handleTakeTurn("heal")}
        isActive={isPlayerOneTurn && !isGameOver}
        isBot={playerOne.isBot}
        state={playerOne.state}
        onUseAbility={() => handleUseAbility(true)}
        risingText={risingTextLeft}
        winTally={playerOne.winTally}
        timesEaten={playerOne.timesEaten}
        risingIcon={risingIconLeft}
      ></PlayerDisplay>
    );
  };

  const generateP2 = () => {
    return (
      <PlayerDisplay
        card={playerTwo.card}
        onClickLightAttack={() => handleTakeTurn("light")}
        onClickHeavyAttack={() => handleTakeTurn("heavy")}
        onClickHeal={() => handleTakeTurn("heal")}
        isFacingLeft
        isBot={playerTwo.isBot}
        state={playerTwo.state}
        onUseAbility={() => handleUseAbility(false)}
        risingText={risingTextRight}
        risingIcon={risingIconRight}
        winTally={playerTwo.winTally}
        timesEaten={playerTwo.timesEaten}
        isActive={!isPlayerOneTurn && !isGameOver}
      ></PlayerDisplay>
    );
  };

  const generateShowModalButton = () => {
    return (
      <div
        style={{
          display: "grid",
          justifyItems: "center",
          alignItems: "center",
          height: "75vh",
        }}
      >
        <Button
          variant="primary"
          style={{ fontSize: "2rem", padding: "12px 36px" }}
          onClick={() => setShowGameOverModal(true)}
        >
          End the Game!
        </Button>
      </div>
    );
  };

  const generateEndGameModal = () => {
    return (
      <Modal
        isVisible={showGameOverModal}
        onClose={() => setShowGameOverModal(false)}
      >
        {isGameStarted && playerOne.card.hp === 0 ? (
          <h1 className="victory-text">
            {playerTwo.card.name} won!!!
            {playerTwo.winTally > 1 &&
              `${playerTwo.winTally.toString()} times!!`}{" "}
          </h1>
        ) : (
          <h1 className="victory-text">
            {playerOne.card.name} won!!!
            {playerOne.winTally > 1 &&
              `${playerOne.winTally.toString()} times!!`}
          </h1>
        )}
        <Button
          eventName={"Gram TCG Rematch Button"}
          variant="primary"
          onClick={() => handleNewGame()}
          style={{ fontSize: "2rem", padding: "12px 48px", margin: "0 64px" }}
        >
          Rematch
        </Button>
        <MFVLink />
      </Modal>
    );
  };

  return (
    <div style={{ display: "grid", justifyContent: "center" }}>
      <div className="main-vertical-grid">
        <StyleDiv bg={background.src}>
          <div className="game-grid">
            {playerOne ? generateP1() : <div></div>}
            <h1 className="versus-text">VS</h1>
            {playerTwo ? generateP2() : <div></div>}
          </div>
          <div className="lower-game-grid">
            <div></div>
            <LogContainer log={log}></LogContainer>
          </div>
        </StyleDiv>
        {!showGameOverModal &&
          isGameOver &&
          hasSeenModal &&
          generateShowModalButton()}
      </div>

      {generateBlocks()}
      {generateEndGameModal()}
    </div>
  );
};

export default TradingCardGame;
