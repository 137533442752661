/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from "react";
import { coolClone, getRandomInt, sortByValue } from "../../../utils/Utils";
import Button from "../../Small/Button";
import DropGramCharacter from "./DropGramCharacter";

import "../../Css/WindowGram.css";
import { DropGramBlockType } from "./DropGramBlock";
import useSound from "use-sound";
import gameBackground from "../../../img/tcg/backgrounds/bg8_roof.jpeg";
import carpetBackground from "../../../img/jumpgram/bgs/Carpet.jpeg";

import vinnyThrow1 from "../../../img/tcg/vinny/Throw/VinnyThrow1.png";
import vinnyThrow2 from "../../../img/tcg/vinny/Throw/VinnyThrow2.png";
import vinnyThrow3 from "../../../img/tcg/vinny/Throw/VinnyThrow3.png";

import paperSound from "../../../sounds/tcg/items/paper.mp3";
import specialSound from "../../../sounds/tcg/items/guitarRiff.mp3";
import loseSound from "../../../sounds/tcg/items/drum.mp3";
import throwSound from "../../../sounds/tcg/items/blanket.mp3";

import Modal from "../../Small/Modal";
import MFVLink from "../TCG/MFVLink";
import {
  WindowBlockData,
  FLOOR_HEIGHT,
  CEILING_HEIGHT,
  DefaultHighScores,
  GRAM_LEFT,
  WindowGramBlocks,
  WindowGramPowerUps,
  MAX_OBJECT_SPEED,
  MIN_TICK_TIME,
  STARTING_NAME,
  STARTING_SCORE,
  STARTING_TIME_LAG,
  TIME_DIVISOR,
  GRAM_MOVE_SPEED,
  LEFT_BORDER,
  RIGHT_BORDER,
} from "./DropGramConstants";
import { HighScore, HighScoreResults } from "../../../utils/Types";
import clickSound from "../../../sounds/click.mp3";
import EndGameMusic from "../../../sounds/dropgram/Lose.mp3";
import { VolumeContext } from "../../../Routes";
import DropGramBlock from "./DropGramBlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { appUrl } from "../../../utils/Constants";

const vinnyThrowImages = [vinnyThrow1, vinnyThrow2, vinnyThrow3];

interface DropGramProps {
  themeSong: HTMLAudioElement;
}

const DropGramPage = ({ themeSong }: DropGramProps) => {
  const globalVolume = useContext(VolumeContext).globalVolume;
  const [playerState, setPlayerState] = useState<"run" | "lose">("run");
  const [showGameOverModal, setShowGameOverModal] = useState<boolean>(false);
  const [charX, setCharX] = useState<number>(GRAM_LEFT);
  const [charY, setCharY] = useState<number>(FLOOR_HEIGHT);
  const [vinnyX, setVinnyX] = useState<number>(RIGHT_BORDER - 30);
  const [blocks, setBlocks] = useState<DropGramBlockType[]>([]);
  const [timeTick, setTimeTick] = useState<number>(0);
  const [isGameOver, setIsGameOver] = useState<boolean>(false);
  const [yourHighScore, setYourHighScore] = useState<HighScore | undefined>(
    undefined
  );
  const [timers, setTimers] = useState<NodeJS.Timeout[]>([]);
  const [highscores, setHighScores] = useState<HighScore[]>(DefaultHighScores);
  const [serverHighscores, setServerhighscores] =
    useState<HighScore[]>(DefaultHighScores);
  // const [hasKeyedUp, setHasKeyedUp] = useState<boolean>(true);
  const [isStandingStill, setIsStandingStill] = useState<boolean>(true);
  const [highScoreName, setUsername] = useState<string>(STARTING_NAME);
  const [playSpecialSound] = useSound(specialSound, { volume: globalVolume });
  const [playLoseSound] = useSound(loseSound, { volume: globalVolume });
  const [playThrowSound] = useSound(throwSound, { volume: globalVolume });
  const [playPaperSound] = useSound(paperSound, { volume: globalVolume });
  const [playClick] = useSound(clickSound, { volume: globalVolume });
  const [playEndGameSong] = useSound(EndGameMusic, { volume: globalVolume });
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isFacingRight, setIsFacingRight] = useState<boolean>(true);

  const [score, setScore] = useState<number>(STARTING_SCORE);
  const [animationIndex, setAnimationIndex] = useState<number>(0);

  const calcVinnyImg = (state: string, animationIndex: number) => {
    switch (state) {
      default:
        return vinnyThrowImages[
          animationIndex >= vinnyThrowImages.length
            ? vinnyThrowImages.length - 1
            : animationIndex
        ];
    }
  };

  const postHighScore = async (hScore: HighScore): Promise<HighScore[]> => {
    const rawResponse = await fetch(`${appUrl}/dropgramscores`, {
      method: "POST",
      body: JSON.stringify({ highscore: hScore }),
    });
    const postResponse = await rawResponse.json();

    if (!postResponse.error && postResponse.highscores) {
      return postResponse.highscores;
    }
    return [];
  };

  const submitHighScore = async (yourScore: HighScore) => {
    if (yourHighScore?.name.length) {
      const res = await postHighScore(yourScore);

      if (res) {
        setIsSubmitted(true);
        setYourHighScore(undefined);
      }
    }
  };

  useEffect(() => {
    const getHighScores = async () => {
      const response = await fetch(`${appUrl}/dropgramscores`);
      const obj: HighScoreResults = await response.json();
      if (obj.highscores) {
        setHighScores(obj.highscores);
        setServerhighscores(obj.highscores);
      }
    };
    if (!isGameOver) {
      getHighScores();
    }
  }, [isGameOver]);

  const clearTimers = () => {
    timers.forEach((timer) => {
      clearTimeout(timer);
    });
    setTimers([]);
  };

  const cleanUpGame = () => {
    themeSong.pause();
    setBlocks([]);
    setYourHighScore(undefined);
    setCharX(GRAM_LEFT);
    setCharY(FLOOR_HEIGHT);
    setScore(STARTING_SCORE);
    clearTimers();
    setTimers([]);
    setPlayerState("run");
    setShowGameOverModal(false);
  };

  const startNewGame = () => {
    playClick();
    setShowGameOverModal(false);
    cleanUpGame();
    setIsGameOver(false);
    setTimeTick(0);
    themeSong.loop = true;
    themeSong.currentTime = 0;
    themeSong.play();
  };

  useEffect(() => {
    themeSong.volume = globalVolume;
  }, [globalVolume, themeSong]);

  useEffect(() => {
    const endGameFunction = async () => {
      if (isGameOver && !showGameOverModal) {
        themeSong.pause();
        playLoseSound();
        clearTimers();
        setBlocks([]);
        setCharY(FLOOR_HEIGHT);
        setPlayerState("lose");

        let isOnTopTen = false;

        //If user places in the top ten
        highscores.forEach((highscore: HighScore) => {
          if (score > highscore.value) {
            isOnTopTen = true;
            return;
          }
        });

        if (isOnTopTen) {
          playSpecialSound();
          //Then show the prompt to enter name and submit
          //Show them on the board

          const time = new Date(Date.now());

          const hScore: HighScore = {
            name: highScoreName,
            value: score,
            time,
            gameLength: timeTick,
            id: `${time.toTimeString()}-${score}`,
          };

          const newHighscores = [...serverHighscores, hScore];
          const sortedScores = newHighscores.sort(sortByValue).slice(0, 7);

          setHighScores(sortedScores);
          setYourHighScore(hScore);
        }

        setTimeout(() => {
          playEndGameSong();
          setShowGameOverModal(true);
        }, 700);
      }
    };

    endGameFunction();
  }, [isGameOver]);

  const summonBlock = (block: WindowBlockData) => {
    const randXValue = getRandomInt(230);
    setVinnyX(randXValue + 30);
    setAnimationIndex(0);
    playThrowSound();

    const id = `${block.type}-${new Date(Date.now()).toString()}`;
    const newBlock: DropGramBlockType = {
      x: randXValue,
      y: CEILING_HEIGHT,
      id: id,
      speed: block.speed,
      image: block.imgSrc,
      leftBounds: block.leftBounds,
      rightBounds: block.rightBounds,
      topBounds: block.topBounds,
      bottomBounds: block.bottomBounds,
      type: block.type,
      onDestroy: () => {},
      updateBlockLocation: () => {},
    };
    setBlocks((prev) => [...prev, newBlock]);
  };

  const summonNewspaper = (block: WindowBlockData) => {
    const randXValue = getRandomInt(230) + 10;
    playThrowSound();

    setVinnyX(randXValue + 40);
    setAnimationIndex(0);
    // setTimeSinceNewspaper(0);
    const id = `${block.name}-${timeTick.toString()}`;
    const newBlock: DropGramBlockType = {
      x: randXValue,
      y: CEILING_HEIGHT,
      id: id,
      speed: block.speed,
      image: block.imgSrc,
      leftBounds: block.leftBounds,
      rightBounds: block.rightBounds,
      topBounds: block.topBounds,
      bottomBounds: block.bottomBounds,
      type: block.type,
      onDestroy: () => {},
      updateBlockLocation: () => {},
    };
    setBlocks((prev) => [...prev, newBlock]);
  };

  const calcTickTime = () => {
    const RAND_BONUS = getRandomInt(500);
    return RAND_BONUS + MIN_TICK_TIME;
  };

  useEffect(() => {
    setTimers([
      ...timers,
      setTimeout(() => {
        setTimeTick((prev) => prev + 1);
        setAnimationIndex((prev) => prev + 1);
      }, calcTickTime()),
    ]);
  }, [timeTick]);

  useEffect(() => {
    if (!isGameOver && timeTick > STARTING_TIME_LAG) {
      //Once the game has started, on a regular interval:
      if (timeTick % 5 === 0) {
        const randHundred = getRandomInt(100);

        let block: WindowBlockData = WindowGramBlocks.dishwasher;

        if (randHundred > 80) {
          block = WindowGramBlocks.chair;
        } else if (randHundred > 70) {
          block = WindowGramBlocks.suitcase;
        } else if (randHundred > 50) {
          block = WindowGramBlocks.toaster;
        } else if (randHundred > 40) {
          block = WindowGramBlocks.couch;
        }

        if (randHundred > 30) {
          summonBlock(block);
        } else {
          summonNewspaper(WindowGramPowerUps.newspaper);
        }
      }
    }
  }, [timeTick, isGameOver]);

  const handleDestroyBlock = (blockId: string) => {
    // const index = blocks.findIndex((block) => block.id === blockId);

    const newBlocks = blocks.filter((block) => {
      return block.id !== blockId;
    });

    setBlocks(newBlocks);

    // if (index > -1 && blocks.length) {
    //   const newBlocks = [...blocks];
    //   newBlocks.splice(index, 1);
    //   setBlocks(newBlocks);
    // }
  };
  const handleMoveLeft = useCallback(() => {
    if (isGameOver) return;
    if (isStandingStill) {
      handleStartMoveAnimation();
    }
    if (!isFacingRight) {
      setIsFacingRight(true);
    }
    if (charX > LEFT_BORDER) {
      setCharX((prev) => prev - GRAM_MOVE_SPEED);
    }
  }, [charX, isFacingRight, isGameOver, isStandingStill]);

  const handleMoveRight = useCallback(() => {
    if (isGameOver) return;
    if (isStandingStill) {
      handleStartMoveAnimation();
    }
    if (isFacingRight) {
      setIsFacingRight(false);
    }
    if (charX < RIGHT_BORDER) {
      setCharX((prev) => prev + GRAM_MOVE_SPEED);
    }
  }, [charX, isFacingRight, isGameOver, isStandingStill]);

  useEffect(() => {
    const handleKeyboard = (e: any) => {
      if (e.key === "a" || e.key === "ArrowLeft") {
        e.stopPropagation();
        e.preventDefault();
        handleMoveLeft();
      }

      if (e.key === "d" || e.key === "ArrowRight") {
        e.stopPropagation();
        e.preventDefault();
        handleMoveRight();
      }
    };
    window.addEventListener("keyup", handleKeyboard);

    return () => {
      window.removeEventListener("keyup", handleKeyboard);
    };
  }, [handleMoveLeft, handleMoveRight, isFacingRight]);

  const handleUpdateBlockY = (
    key: string,
    yValue: number,
    xValue: number,
    leftBounds: number,
    rightBounds: number,
    type: string
  ) => {
    if (type === "block" || type === "powerup") {
      const CHARACTER_WIDTH = 50;
      const blockIsOnRight = xValue > charX - 70;
      const blockIsNotTooFarRight = xValue < charX + CHARACTER_WIDTH;
      const blockIsLowEnough = yValue > CEILING_HEIGHT + 291;
      const blockIsNotTooLow = yValue < FLOOR_HEIGHT + 150;

      if (
        blockIsOnRight &&
        blockIsNotTooFarRight &&
        blockIsLowEnough &&
        blockIsNotTooLow &&
        !isGameOver
      ) {
        // debugger

        if (type === "powerup") {
          collectNewspaper();
        } else {
          setIsGameOver(true);
        }
        playPaperSound();
        handleDestroyBlock(key);
      }
    }
  };

  const collectNewspaper = () => {
    setScore((prev) => prev + 1);
  };

  const userAlreadyExists = (name: string, score: number) => {
    let foundName = false;
    serverHighscores.forEach((serverScore) => {
      if (name.toLowerCase() === serverScore.name.toLowerCase()) {
        if (serverScore.value > score) {
          foundName = true;
        }
        return;
      }
    });
    return foundName;
  };

  const updateUsername = async (value: string) => {
    if (highScoreName.length >= 5) {
      value = value.substring(0, 5);
    }
    setUsername(value.toUpperCase());

    if (yourHighScore) {
      const hScore: HighScore = {
        ...yourHighScore,
        name: value,
      };

      const newHighscores = [...coolClone(serverHighscores), hScore];
      setYourHighScore(hScore);
      const sortedScores = newHighscores.sort(sortByValue).slice(0, 7);
      setHighScores(sortedScores);
    }
  };

  const generateHighScores = () => {
    // if (!yourHighScore) return;
    const yourId = yourHighScore ? yourHighScore.id : "";

    return highscores.map((highscore, i) => {
      const row = (
        <div key={`${i}-key`} className="highscore-row">
          <p className={highscore.id === yourId ? `you-text` : ""}>{`${
            i + 1
          }: ${highscore.name}`}</p>
          <p className="highscore-value">{highscore.value}</p>
        </div>
      );

      return row;
    });
  };

  const calcSpeed = (speed: number): number => {
    let speedVal = speed + Math.ceil(timeTick / TIME_DIVISOR);
    if (speedVal >= MAX_OBJECT_SPEED) {
      speedVal = MAX_OBJECT_SPEED;
    }
    return speedVal;
  };

  const handleStartMoveAnimation = () => {
    setIsStandingStill(false);

    setTimeout(() => {
      setIsStandingStill(true);
    }, 450);
  };

  return (
    <div className="main-container">
      <div style={{ display: "grid" }}>
        <h3 style={{ textAlign: "center" }} className="main-jump-header">
          Drop Gram
        </h3>
        <div className="drop-gram-game-container">
          <div
            className={"drop-gram-background"}
            style={{
              background: `url(${gameBackground})`,
              backgroundSize: "cover",
            }}
          ></div>
          <div
            className={"floor-bg-stationary"}
            style={{
              background: `url(${carpetBackground})`,
            }}
          ></div>
          {/* <div
            className={"stuff-bg-stationary"}
            style={{
              // backgroundSize: "auto 75%",
              background: `url(${stuffBackground})`,
              animationPlayState: isGameOver ? "paused" : "running",
            }}
          ></div> */}
          <div className="top-jump-bar"></div>
          <h3 className="jump-score">{`${score}`}</h3>
          {/* <h3 className="news-score">{`${timeTick}`}</h3> */}
          <div
            className="vinny-throw-container"
            style={{ left: `${vinnyX}px` }}
          >
            <img
              alt="Vinny throwing stuff"
              src={calcVinnyImg("throw", animationIndex)}
            ></img>
          </div>

          <DropGramCharacter
            x={charX}
            y={charY}
            isStandingStill={isStandingStill}
            state={playerState}
            isFacingRight={isFacingRight}
          ></DropGramCharacter>
          {blocks.map((block: DropGramBlockType, i) => {
            return (
              <DropGramBlock
                key={`${block.id}-${i}-key`}
                id={`${block.id}-${i}`}
                x={block.x}
                y={block.y}
                speed={calcSpeed(block.speed)}
                image={block.image}
                leftBounds={block.leftBounds}
                rightBounds={block.rightBounds}
                topBounds={block.topBounds}
                bottomBounds={block.bottomBounds}
                type={block.type}
                updateBlockLocation={(
                  xValue: number,
                  yValue: number,
                  leftBounds: number,
                  rightBounds: number,
                  type: string
                ) =>
                  handleUpdateBlockY(
                    block.id,
                    yValue,
                    xValue,
                    leftBounds,
                    rightBounds,
                    type
                  )
                }
                onDestroy={() => handleDestroyBlock(block.id)}
              ></DropGramBlock>
            );
          })}
        </div>
        <div className="drop-gram-controls">
          <Button
            disabled={isGameOver}
            variant="primary"
            onClick={() => handleMoveLeft()}
          >
            <FontAwesomeIcon icon={faArrowLeftLong}></FontAwesomeIcon>
          </Button>
          <Button
            disabled={isGameOver}
            variant="primary"
            onClick={() => handleMoveRight()}
          >
            <FontAwesomeIcon icon={faArrowRightLong}></FontAwesomeIcon>
          </Button>
        </div>
        <Modal
          isVisible={showGameOverModal}
          onClose={() => {
            setIsGameOver(false);
          }}
        >
          <h1 className="victory-text">{`Top Scores:`}</h1>
          <div className="highscores-grid">{generateHighScores()}</div>
          {yourHighScore && (
            <div style={{ display: "grid", gridGap: "4px" }}>
              {/* <h1 className="victory-text">{`Your Name: `}</h1> */}
              <div className="highscore-row">
                <input
                  className="gram-input"
                  value={highScoreName}
                  onChange={(e) => updateUsername(e.target.value)}
                ></input>
                <p className="highscore-value">{score}</p>
              </div>
              <Button
                eventName={"Drop Gram Submit Score Button"}
                variant="primary"
                disabled={
                  highScoreName.length < 3 ||
                  highScoreName === "YOU" ||
                  userAlreadyExists(highScoreName, yourHighScore.value)
                }
                onClick={() => {
                  submitHighScore(yourHighScore);
                }}
              >
                Submit Score
              </Button>
            </div>
          )}
          {!yourHighScore && !isSubmitted && (
            <div className="highscore-row">
              <p className="you-text">{`......You:`}</p>
              <p className="highscore-value">{score}</p>
            </div>
          )}
          {!yourHighScore && isSubmitted && (
            <h1 className="victory-text">{`Thanks!`}</h1>
          )}
          <Button
            eventName={"Drop Gram Play Again Button"}
            variant="primary"
            onClick={() => {
              startNewGame();
            }}
          >
            Play Again
          </Button>
          <MFVLink />
        </Modal>
      </div>
    </div>
  );
};

export default DropGramPage;
