import "../Css/Button.css";

type ButtonVariant = "primary" | "secondary" | "green" | "orange";

interface ButtonProps {
  onClick: () => void;
  style?: any;
  disabled?: boolean;
  children: any;
  variant: ButtonVariant;
  eventName?: string | null;
}

const Button = ({
  onClick,
  disabled,
  children,
  variant,
  style,
  eventName = null
}: ButtonProps) => {
  const calcBgColor = () => {
    switch (variant) {
      case "primary":
        return "#3496ff";
      case "secondary":
        return "#98c4f0";
      case "green":
        return "green";
      case "orange":
        return "#f09368";
      default:
        return "#3496ff'";
    }
  };

  const calcStyle = () => {
    return {
      ...style,
      backgroundColor: calcBgColor(),
      cursor: disabled && "auto",
      opacity: disabled && 0.45,
      zIndex: 5,
    };
  };
  return (
    <button
      data-umami-event={eventName}
      disabled={disabled}
      style={calcStyle()}
      onClick={onClick}
      className="gramButton"
    >
      {children}
    </button>
  );
};

export default Button;
