import { skipStoriesLocalStorage } from "../../../utils/Utils";
import "../../Css/TCGPage.css";
import Button from "../../Small/Button";
import click from "../../../sounds/click.mp3";
import useSound from "use-sound";
import { useContext, useEffect, useState } from "react";
import { CardResults, Player, Story } from "./utils/TCGTypes";
import TradingCardGame from "./TradingCardGame";
import StoryDisplay from "./StoryDisplay";
import {
  calcRandomBg,
  defaultPlayer1,
  defaultPlayer2,
  generateStory,
  gramCard,
  vinnyCard,
} from "./utils/TCGUtils";
import note1 from "../../../sounds/tcg/stories/note1.mp3";
import note4 from "../../../sounds/tcg/stories/note4.mp3";
import {
  GramHeavyMoves,
  GramLightMoves,
  HealMoves,
  HeavyMoves,
  LightMoves,
  VinnyHeavyMoves,
  VinnyLightMoves,
  gramSpecialMove,
  vinnySpecialMove,
} from "./utils/TCGMoves";
import { appUrl } from "../../../utils/Constants";
import { VolumeContext } from "../../../Routes";

import instaimg from "../../../img/instabig.png";
import youtubeimg from "../../../img/youtube-big.png";
import AnimatedGuy from "./utils/AnimatedGuy";
import LoadingBanana from "../../Small/LoadingBanana";

let search = window.location.search;
let params = new URLSearchParams(search);

const skipStories: string = skipStoriesLocalStorage;

const TCGLandingPage = () => {
  const globalVolume = useContext(VolumeContext).globalVolume;
  const [playerOne, setPlayerOne] = useState<Player | undefined>(undefined);
  const [playerTwo, setPlayerTwo] = useState<Player | undefined>(undefined);
  const [isLoadingCard, setIsLoadingCard] = useState<boolean>(false);
  const [gameState, setGameState] = useState<string>("help");
  const [isScanningP1, setIsScanningP1] = useState<boolean>(false);
  const [bgImage, setBgImage] = useState<HTMLImageElement>();
  const [vinnyCardImg, setVinnyCardImg] = useState<HTMLImageElement>();
  const [gramCardImg, setGramCardImg] = useState<HTMLImageElement>();
  const [generatedStory, setGeneratedStory] = useState<Story | undefined>();
  const [storyImages, setStoryImages] = useState<HTMLImageElement[]>([]);
  const [shouldReload] = useState<number>(0);
  const [isError, setIsError] = useState<boolean>(false);
  const [playClick] = useSound(click, { volume: globalVolume });
  const [playNote1] = useSound(note1, { volume: globalVolume });
  const [playNote4] = useSound(note4, { volume: globalVolume });

  // const [skipStories, setSkipStories] = useState<boolean>(true);
  const [moveImages, setMoveImages] = useState<HTMLImageElement[]>([]);

  const getCard = async (
    cardId: string,
    isPlayerOne: boolean,
    isBot = false
  ) => {
    setIsLoadingCard(true);

    try {
      const url = `${appUrl}/cards?id=${cardId}`;
      const results = await fetch(url);
      const resultsObject: CardResults = await results.json();
      const resultsCards = resultsObject.cards;

      if (resultsObject.error) {
        throw resultsObject.error;
      }

      if (!resultsObject.cards) {
        throw new Error("No cards found with this id");
      }


      if (isPlayerOne) {
        const newPlayer1 = defaultPlayer1();
        newPlayer1.card = resultsCards[0];
        newPlayer1.isBot = isBot;
        setPlayerOne(newPlayer1);
      } else {
        const newPlayer2 = defaultPlayer2();
        newPlayer2.card = resultsCards[0];
        newPlayer2.isBot = isBot;
        setPlayerTwo(newPlayer2);
      }
    } catch (e) {
      setIsError(true);
      // TODO: Real toast maybe
    } finally {
      setIsLoadingCard(false);
    }
  };

  useEffect(() => {
    const getCardAsync = async (id: string, isP1: boolean, isBot?: boolean) => {
      await getCard(id, isP1, isBot);
    };

    const queryParamId = params.get("id");

    if (
      queryParamId?.length === 24 &&
      playerOne === undefined &&
      !isLoadingCard
    ) {
      getCardAsync(queryParamId, true, false);
    } else {
      setIsError(true);
    }

    return () => {
      setIsLoadingCard(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartGame = () => {
    if (!playerOne) {
      addBot(true);
    }
    if (!playerTwo) {
      addBot(false);
    }
    if (globalVolume > 0) {
      playClick();
      playNote1();
    }

    if (skipStories === "true") {
      setGameState("play");
    } else {
      setGameState("story");
    }
  };

  const getGramCPU = async (isP1: boolean) => {
    await getCard("000247661b96a8f9bad92023", isP1, true);
  };

  const getVinnyCPU = async (isP1: boolean) => {
    await getCard("00155adbab31eeaa11142023", isP1, true);
  };

  const handleRestartGame = async () => {
    playNote4();
    setGameState("play");
  };

  const handleScannedCode = async (result: string, isP1: boolean) => {
    const queryString = result.split("?")[1];
    const queryId = queryString.slice(3);
    if (queryId.trim().length === 24) {
      await getCard(queryId, isP1);
      setIsScanningP1(false);
    }
  };

  const addBot = async (isP1: boolean) => {
    playClick();
    if (isP1) {
      setIsScanningP1(false);
      if (playerTwo && playerTwo.card.name === "Vinny") {
        await getGramCPU(true);
      } else {
        await getVinnyCPU(true);
      }
    } else {
      if (playerOne && playerOne.card.name === "Vinny") {
        await getGramCPU(false);
      } else {
        await getVinnyCPU(false);
      }
    }
  };

  const getCardImg = (name: string) => {
    if (!gramCardImg || !vinnyCardImg) return;
    switch (name) {
      case "Vinny":
        return vinnyCardImg.src;
      case "Gram":
        return gramCardImg.src;
      default:
        return vinnyCard;
    }
  };

  // LOAD EVERYTHING
  useEffect(() => {
    const story = generateStory();

    const images = story.frames.map((frame) => {
      const imgStr = frame.img;
      const img = new Image();
      img.src = imgStr;
      return img;
    });
    setGeneratedStory(story);
    setStoryImages(images);

    const gramCardImg = gramCard;
    const img3 = new Image();
    img3.src = gramCardImg;
    setGramCardImg(img3);

    const vinnyCardImg = vinnyCard;
    const img4 = new Image();
    img4.src = vinnyCardImg;
    setVinnyCardImg(img4);

    const moves = HeavyMoves.concat(
      LightMoves,
      VinnyHeavyMoves,
      GramHeavyMoves,
      VinnyLightMoves,
      GramLightMoves,
      HealMoves,
      gramSpecialMove(),
      vinnySpecialMove()
    );

    const moveImgs = moves.map((move) => {
      const imgStr = move.imgSrc;
      const img = new Image();
      img.src = imgStr;
      return img;
    });
    setMoveImages(moveImgs);

    const bgImgStr = calcRandomBg(story.backgrounds);
    const bgImg = new Image();
    bgImg.src = bgImgStr;
    setBgImage(bgImg);
  }, [shouldReload]);

  const handleReady = () => {
    handleStartGame();
  };

  const generateIntoSplashScreen = () => {
    return (
      // {loading ? <LoadingBanana></LoadingBanana>}

      <div className="main-container">
        <div className="mainContentHeight">
          <div className="center-grid">
            {isLoadingCard ? (
              <LoadingBanana></LoadingBanana>
            ) : (
              <div className="help-container">
                <div className="youtube-link-container">
                  <a
                    style={{
                      display: "grid",
                      alignItems: "center",
                      alignContent: "center",
                      gap: "0px",
                      gridTemplateColumns: "32px auto",
                      height: '76px'
                    }}
                    href="https://www.youtube.com/channel/UCWAuhU21divh947XCNU4kFQ"
                    target="_blank"
                    rel="noreferrer noopener"
                    data-umami-event="Youtube Link on TCG Landing Page"
                  >
                    <img
                      src={youtubeimg}
                      alt={"Subscribe on Youtube"}
                      height="48px"
                      width="68px"
                      style={{
                        justifySelf: "start",
                        padding: "8px",
                        margin: "4px",
                      }}
                    ></img>
                    SUBSCRIBE
                  </a>
                </div>
                <div className="instagram-link-container">
                  <a
                    style={{
                      display: "grid",
                      alignItems: "center",
                      alignContent: "center",
                      gridTemplateColumns: "auto",
                      height: '64px'
                    }}
                    href="https://www.instagram.com/vinnyviti/"
                    target="_blank"
                    rel="noreferrer noopener"
                    data-umami-event="Instagram Link on TCG Landing Page"
                  >
                    <img
                      src={instaimg}
                      alt={"Follow on Instagram"}
                      height="84px"
                      style={{
                        justifySelf: "start",
                        position: 'absolute',
                        paddingBottom: '4px',
                        alignSelf: "center",
                        filter: 'drop-shadow(3px 1px 0 rgb(71, 71, 71)) drop-shadow(-2px -1px 0 rgb(71, 71, 71))'
                      }}
                    ></img>
                    <div style={{ justifySelf: "center", marginLeft: '30px' }}>FOLLOW GRAM</div>
                  </a>
                </div>  
                <div
                  className="bottom-section-absolute"
                  style={{ alignItems: "center" }}
                >
                  <>
                    <Button
                      eventName={"Gram TCG Start Button"}
                      variant="primary"
                      disabled={playerOne?.card === undefined}
                      onClick={() => handleReady()}
                      style={{
                        fontSize: "2.3rem",
                        width: "100%",
                        margin: "24px 4 px",
                        padding: "12px 0"
                      }}
                    >
                      PLAY THE GAME!
                    </Button>
                    {playerOne?.card && (
                      <AnimatedGuy
                        card={playerOne?.card}
                        onClickLightAttack={() => {}}
                        onClickHeavyAttack={() => {}}
                        onClickHeal={() => {}}
                        isBot={false}
                        state={"special"}
                        onUseAbility={() => {}}
                        winTally={0}
                        timesEaten={0}
                        isActive={false}
                        hideHealthBar
                      ></AnimatedGuy>
                    )}
                  </>
                </div>
                {/* <MFVLink></MFVLink> */}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const generatePage = () => {
    switch (gameState) {
      case "help":
        return generateIntoSplashScreen();
      case "story":
        if (generatedStory && storyImages) {
          return (
            <StoryDisplay
              story={generatedStory}
              images={storyImages}
              onFinish={() => setGameState("play")}
            ></StoryDisplay>
          );
        } else {
          return <div></div>;
        }
      case "play":
        if (playerOne && playerTwo && bgImage) {
          return (
            <div style={{ display: "grid", justifyContent: "center" }}>
              <TradingCardGame
                moveImages={moveImages}
                player1={playerOne}
                player2={playerTwo}
                background={bgImage}
                onRestartGame={handleRestartGame}
              />
            </div>
          );
        } else {
          return <div></div>;
        }
      default:
        return <div></div>;
    }
  };

  return generatePage();
};

export default TCGLandingPage;
