import { HighScore } from "../../../utils/Types";


// Item Images
import dishwasherImg from "../../../img/tcg/items/dishwasher.png";
import sockImg from "../../../img/tcg/items/sock.png";
import toasterImg from "../../../img/tcg/items/toaster.png";




//Heal images



//Sounds
import bongHitSound from "../../../sounds/tcg/items/bongHit.mp3";
import trashCanSound from "../../../sounds/tcg/items/trashCan.mp3";
import sockSound from "../../../sounds/tcg/items/sock.mp3";



export interface MoveData {
    id: string;
    name: string;
    imgSrc: string;
    soundSrc: string;
    speed: number;
    damage: number;
    type: string;
}

export interface Heal {
    id: string;
    name: string;
    value: number;
    hotLength?: number;
    castTime: number;
    manaValue: number;
}
export interface DropMove {
    name: string;
    value: number;
    dotLength?: number;
    imgSrc: string;

}

export const GRAM_STARTING_LEFT = 180;
export const GRAM_STARTING_TOP = 110;

export const VINNY_STARTING_LEFT = 60;
export const VINNY_STARTING_TOP = 130;

export const FLOOR_HEIGHT = 158;
export const CEILING_HEIGHT = 0;
export const LEFT_BORDER = 0;
export const RIGHT_BORDER = 250;
export const HEALTH_BAR_TOP = "20px";

export const BLOCK_X = 212;
export const MIN_TICK_TIME = 1000;
export const MAX_OBJECT_SPEED = 93;
export const STARTING_TIME_LAG = 15;
export const STARTING_NAME = "YOU";
export const STARTING_SCORE = 0;
export const FALLING_SPEED = 26;
export const MAX_HEALTH = 100;

export const DefaultHighScores: HighScore[] = [
    {
        name: "SHWAN",
        value: 66,
        time: new Date(),
        gameLength: 100,
        id: `SHWAN-${new Date().toTimeString()}-${6}`
    },
    {
        name: "DOG",
        value: 1,
        time: new Date(),
        gameLength: 100,
        id: `DOG-${new Date().toTimeString()}-${12}`
    },
]


export const CoffeeGramMoves: { [key: string]: MoveData } = {
    "sock": {
        id: "sock",
        name: "Smelly Sock",
        imgSrc: sockImg,
        soundSrc: sockSound,
        speed: FALLING_SPEED,
        damage: 10,
        type: "Instant Damage"
    },
    "toaster": {
        id: "toaster",
        name: "Toaster",
        imgSrc: toasterImg,
        soundSrc: bongHitSound,
        speed: FALLING_SPEED,
        damage: 15,
        type: "Instant Damage"
    },
    "dishwasher": {
        id: "dishwasher",
        name: "Dishwasher",
        imgSrc: dishwasherImg,
        soundSrc: trashCanSound,
        speed: FALLING_SPEED,
        damage: 30,
        type: "Instant Damage"
    },
    // "tv": {
    //     id: "TV",
    //     name: "TV",
    //     imgSrc: tvImg,
    //     soundSrc: trashCanSound,
    //     speed: FALLING_SPEED,
    //     damage: 40,
    //     type: "Instant Damage"
    // },
}


export const GLOBAL_COOLDOWN = 500;

export const CoffeeGramHeals: { [key: string]: Heal } = {
    "lightRoast": {
        id: "light-roast",
        name: "Light Roast",
        value: 30,
        castTime: 1500,
        manaValue: 20,
    },
    "heavyRoast": {
        id: "heavy-roast",
        name: "Dark Roast",
        value: 60,
        castTime: 3500,
        manaValue: 10,
    },
    "decaf": {
        id: "decaf",
        name: "Decaf",
        value: 15,
        hotLength: 6,
        castTime: 5,
        manaValue: 30,
    },
}