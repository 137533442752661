import { useContext, useEffect, useState } from "react";
import "./Css/HighFive.css";
import hand from "../img/handSmall.png";
import thumbsup from "../img/GramThumbSmall.png";
import useSound from "use-sound";
import hiFive from "../sounds/five.mp3";
import swish from "../sounds/swish.mp3";
import laugh from "../sounds/gramlaugh2.mp3";
import { devMode, firstTimeLocalStorage, getRandomInt } from "../utils/Utils";
import { GramPhrases } from "../utils/Phrases";
import { appUrl } from "../utils/Constants";
import { VolumeContext } from "../Routes";
import { useIdleTimer } from "react-idle-timer";

export interface LikeResults {
  likes: number;
  message: string;
  error: boolean;
}

const HighFive = () => {
  const globalVolume = useContext(VolumeContext).globalVolume;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isThumbsUp, setIsThumbsUp] = useState<boolean>(false);
  const [showSpeech, setShowSpeech] = useState<boolean>(false);
  const [speech, setSpeech] = useState<string>("");
  const [lastSpeech, setLastSpeech] = useState<string>("");
  const [amountOfLikes, setAmountOfLikes] = useState<number>(0);
  const [amountOfSecretLikes, setAmountOfSecretLikes] = useState<number>(0);
  const [remainingComments, setRemainingComments] =
    useState<string[]>(GramPhrases);

  const onIdle = () => {
    setIsExpanded(true);
    setShowSpeech(true);
    setSpeech("What are you doing?");
  };

  const onActive = () => {
    setSpeech("Watch more funny videos!")

    setTimeout(() => {
      setIsExpanded(false);
      setShowSpeech(false);
    }, 1400);
  };

  useIdleTimer({
    onIdle,
    onActive,
    timeout: 19000,
    throttle: 500,
  });

  const [playHiFive] = useSound(hiFive, { volume: globalVolume });
  const [playSwish] = useSound(swish, { volume: globalVolume });
  const [playLaugh] = useSound(laugh, { volume: globalVolume });

  const generateNewSpeech = () => {
    const filteredComments = remainingComments.filter(
      (comment) => comment !== lastSpeech
    );

    if (filteredComments.length > 0) {
      const randomNumber = getRandomInt(filteredComments.length);
      const speechText = filteredComments[randomNumber];
      setSpeech(speechText);
      setLastSpeech(speechText);
      setRemainingComments(filteredComments);
    } else {
      setRemainingComments(GramPhrases);
      const freshFilteredComments = GramPhrases.filter(
        (comment) => comment !== lastSpeech
      );
      const randomNumber = getRandomInt(freshFilteredComments.length);
      const speechText = freshFilteredComments[randomNumber];
      setSpeech(speechText);
      setLastSpeech(speechText);
    }
    setShowSpeech(true);

    const randonNumber2 = getRandomInt(400) + 900;
    setTimeout(function () {
      setShowSpeech(false);
      setTimeout(function () {
        setSpeech("");
      }, randonNumber2);
    }, randonNumber2 + 300);
  };

  useEffect(() => {
    const getHighFives = async () => {
      const response = await fetch(`${appUrl}/gramlikes`);
      const obj: LikeResults = await response.json();
      if (obj.likes) {
        setAmountOfLikes(Number(obj.likes));
      }
    };

    getHighFives();
  }, []);

  useEffect(() => {
    if (firstTimeLocalStorage === "true") {
      const speechText = "Hi! I'm Gram. Tap me!";
      setSpeech(speechText);
      setShowSpeech(true);
      setIsExpanded(true);
    }
  }, []);

  const postHighFives = async (amount: number) => {
    const rawResponse = await fetch(`${appUrl}/gramlikes`, {
      method: 'POST',
      body: JSON.stringify({amount: amount})
    });
    await rawResponse.json();
  }

  const likeTheMonkey = async () => {
    if (!isThumbsUp && isExpanded) {
      playHiFive();
      setAmountOfLikes((prev) => prev + 1);
      setAmountOfSecretLikes((prev) => prev + 1);
      setIsThumbsUp(true);
      generateNewSpeech();

      setTimeout(function () {
        setIsExpanded(false);
        setTimeout(function () {
          setIsThumbsUp(false);
        }, 500);
      }, 1900);

      if (devMode === "false") {
        await postHighFives(1);
      }
    }
  };

  const clickHand = () => {
    setIsExpanded(true);
    playSwish();
  };

  useEffect(() => {
    if (amountOfSecretLikes > 3) {
      playLaugh();
      setAmountOfSecretLikes(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountOfSecretLikes]);

  const highFiveClassName = isExpanded ? "high-five-big" : "high-five-small";
  const speechBubbleClassName = showSpeech
    ? "speech-bubble"
    : "speech-bubble-hidden";

  return (
    <div className="high-five-container">
      <div
        data-umami-event="Gram High Five"
        data-umami-name={amountOfLikes}
        className={highFiveClassName}
        onClick={() => isExpanded && likeTheMonkey()}
      >
        <img
          height="80px"
          src={thumbsup}
          className="gram-thumbsup"
          alt="Watch more funny videos!"
          style={{ opacity: isThumbsUp ? "1" : "0" }}
        ></img>
        <img
          src={hand}
          height="220px"
          className="gram-hand"
          alt="High five the Gorilla!"
          style={{ opacity: isExpanded && !isThumbsUp ? "1" : "0" }}
        ></img>
        {isThumbsUp && <div className="likes-bg"></div>}
        {isThumbsUp && <div className="likes-text">{amountOfLikes}</div>}
        {isThumbsUp && <div className="likes-label">Gram Total</div>}
        <div onClick={() => !isExpanded && clickHand()}>
          <img
            src={hand}
            className="gram-hand"
            alt="High five the Gorilla!"
            style={{ opacity: !isExpanded && !isThumbsUp ? "1" : "0" }}
          ></img>
        </div>
        <div className="speech-bubble-container">
          <div className={speechBubbleClassName}>{speech}</div>
        </div>
      </div>
    </div>
  );
};

export default HighFive;
