import { useState } from "react";
import "../../Css/JumpGram.css";
import introScreenBg from "../../../img/tictacgram/Cover.jpeg";
import DropGramPage from "./TicTacGramPage";
import SplashScreen from "../JumpGram/SplashScreen";

// const themeSong = new Audio(BGMusic);

const TicTacGramLandingPage = () => {
  const [isGameStarted, setIsGameStarted] = useState<boolean>(false);

  return isGameStarted ? (
    <DropGramPage></DropGramPage>
  ) : (
    <SplashScreen
      name="Tic Tac Gram"
      img={introScreenBg}
      startGame={() => setIsGameStarted(true)}
    >
      <p>- Place your gram anywhere</p>
      <p>- Line up 3 Grams to win!</p>
    </SplashScreen>
  );
};

export default TicTacGramLandingPage;
