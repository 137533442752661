import { ReactNode } from "react";
import { getRandomInt } from "../../../../utils/Utils";
import {
  EndPhrases,
  LogStrings,
  StateStrings,
  Stories,
} from "./TCGConstants";
import { CardModel, Log, LogVariant, Move, Player } from "./TCGTypes";
import vinnyCard from "../../../../img/tcg/cards/001.jpeg";
import gramCard from "../../../../img/tcg/cards/002.jpeg";
import GramIdle1 from "../../../../img/tcg/gram/Idle/GramIdle1.png";
import GramIdle2 from "../../../../img/tcg/gram/Idle/GramIdle2.png";
import GramIdle3 from "../../../../img/tcg/gram/Idle/GramIdle3.png";
import GramIdle4 from "../../../../img/tcg/gram/Idle/GramIdle4.png";

import gramThrow3 from "../../../../img/tcg/gram/Throw/GramThrow3.png";
import gramThrow4 from "../../../../img/tcg/gram/Throw/GramThrow4.png";

import gramSpecial1 from "../../../../img/tcg/gram/Special/GramSpecial1.png";
import gramSpecial2 from "../../../../img/tcg/gram/Special/GramSpecial2.png";
import gramSpecial3 from "../../../../img/tcg/gram/Special/GramSpecial3.png";
import gramSpecial4 from "../../../../img/tcg/gram/Special/GramSpecial4.png";

import gramHurt1 from "../../../../img/tcg/gram/Hurt/GramHurt1.png";
import gramHurt2 from "../../../../img/tcg/gram/Hurt/GramHurt2.png";
import gramHurt3 from "../../../../img/tcg/gram/Hurt/GramHurt3.png";

import gramLose1 from "../../../../img/tcg/gram/Lose/GramLose1.png";
import gramLose2 from "../../../../img/tcg/gram/Lose/GramLose2.png";
import gramLose3 from "../../../../img/tcg/gram/Lose/GramLose3.png";
import gramLose4 from "../../../../img/tcg/gram/Lose/GramLose4.png";
import gramLose5 from "../../../../img/tcg/gram/Lose/GramLose5.png";
import gramLose6 from "../../../../img/tcg/gram/Lose/GramLose6.png";

import gramWin1 from "../../../../img/tcg/gram/Win/GramWin1.png";
import gramWin2 from "../../../../img/tcg/gram/Win/GramWin2.png";
import gramWin3 from "../../../../img/tcg/gram/Win/GramWin3.png";
import gramWin4 from "../../../../img/tcg/gram/Win/GramWin4.png";

import vinnyIdle1 from "../../../../img/tcg/vinny/Idle/VinnyIdle1.png";
import vinnyIdle2 from "../../../../img/tcg/vinny/Idle/VinnyIdle2.png";
import vinnyIdle3 from "../../../../img/tcg/vinny/Idle/VinnyIdle3.png";
import vinnyIdle4 from "../../../../img/tcg/vinny/Idle/VinnyIdle4.png";

import vinnyHurt1 from "../../../../img/tcg/vinny/Hurt/VinnyHurt1.png";
import vinnyHurt2 from "../../../../img/tcg/vinny/Hurt/VinnyHurt2.png";
import vinnyHurt4 from "../../../../img/tcg/vinny/Hurt/VinnyHurt4.png";

import vinnyHeal1 from "../../../../img/tcg/vinny/Heal/VinnyHeal1.png";
import vinnyHeal2 from "../../../../img/tcg/vinny/Heal/VinnyHeal2.png";
import vinnyHeal3 from "../../../../img/tcg/vinny/Heal/VinnyHeal3.png";

import vinnyThrow1 from "../../../../img/tcg/vinny/Throw/VinnyThrow1.png";
import vinnyThrow2 from "../../../../img/tcg/vinny/Throw/VinnyThrow2.png";
import vinnyThrow3 from "../../../../img/tcg/vinny/Throw/VinnyThrow3.png";

import vinnySpecial1 from "../../../../img/tcg/vinny/Special/VinnySpecial1.png";
import vinnySpecial2 from "../../../../img/tcg/vinny/Special/VinnySpecial2.png";
import vinnySpecial3 from "../../../../img/tcg/vinny/Special/VinnySpecial3.png";

import vinnyLose1 from "../../../../img/tcg/vinny/Lose/VinnyLose1.png";
import vinnyLose2 from "../../../../img/tcg/vinny/Lose/VinnyLose2.png";
import vinnyLose4 from "../../../../img/tcg/vinny/Lose/VinnyLose4.png";

import vinnyWin1 from "../../../../img/tcg/vinny/Win/VinnyWin1.png";
import vinnyWin2 from "../../../../img/tcg/vinny/Win/VinnyWin2.png";
import vinnyWin3 from "../../../../img/tcg/vinny/Win/VinnyWin3.png";


import gramHeal1 from "../../../../img/tcg/gram/Heal/GramHeal1.png";
import gramHeal2 from "../../../../img/tcg/gram/Heal/GramHeal2.png";
import gramHeal3 from "../../../../img/tcg/gram/Heal/GramHeal3.png";
import gramHeal4 from "../../../../img/tcg/gram/Heal/GramHeal4.png";
import gramHeal5 from "../../../../img/tcg/gram/Heal/GramHeal5.png";



// Anime Gram Graphics
import animeGramHeal1 from "../../../../img/tcg/animeGram/Heal/heal1.png";
import animeGramHeal2 from "../../../../img/tcg/animeGram/Heal/heal2.png";
import animeGramHeal3 from "../../../../img/tcg/animeGram/Heal/heal3.png";
import animeGramHeal4 from "../../../../img/tcg/animeGram/Heal/heal4.png";
import animeGramHeal5 from "../../../../img/tcg/animeGram/Heal/heal5.png";


import animeGramWin1 from "../../../../img/tcg/animeGram/Win/win1.png";
import animeGramWin2 from "../../../../img/tcg/animeGram/Win/win2.png";
import animeGramWin3 from "../../../../img/tcg/animeGram/Win/win3.png";
import animeGramWin4 from "../../../../img/tcg/animeGram/Win/win4.png";
import animeGramWin5 from "../../../../img/tcg/animeGram/Win/win5.png";


import animeGramLose1 from "../../../../img/tcg/animeGram/Lose/lose1.png";
import animeGramLose2 from "../../../../img/tcg/animeGram/Lose/lose2.png";
import animeGramLose3 from "../../../../img/tcg/animeGram/Lose/lose3.png";
import animeGramLose4 from "../../../../img/tcg/animeGram/Lose/lose4.png";

import animeGramSpecial1 from "../../../../img/tcg/animeGram/Special/Special1.png";
import animeGramSpecial2 from "../../../../img/tcg/animeGram/Special/Special2.png";
import animeGramSpecial3 from "../../../../img/tcg/animeGram/Special/Special3.png";
import animeGramSpecial4 from "../../../../img/tcg/animeGram/Special/Special4.png";


import animeGramIdle1 from "../../../../img/tcg/animeGram/Idle/Idle_1.png";
import animeGramIdle2 from "../../../../img/tcg/animeGram/Idle/Idle_2.png";
import animeGramIdle3 from "../../../../img/tcg/animeGram/Idle/Idle_3.png";
import animeGramIdle4 from "../../../../img/tcg/animeGram/Idle/Idle_4.png";
import animeGramIdle5 from "../../../../img/tcg/animeGram/Idle/Idle_5.png";


import animeGramThrow1 from "../../../../img/tcg/animeGram/Throw/Attack_1.png";
import animeGramThrow2 from "../../../../img/tcg/animeGram/Throw/Attack_2.png";
import animeGramThrow3 from "../../../../img/tcg/animeGram/Throw/Attack_3.png";
import animeGramThrow4 from "../../../../img/tcg/animeGram/Throw/Attack_4.png";

import animeGramHurt1 from "../../../../img/tcg/animeGram/Hurt/Hurt_1.png";
import animeGramHurt2 from "../../../../img/tcg/animeGram/Hurt/Hurt_2.png";
import animeGramHurt3 from "../../../../img/tcg/animeGram/Hurt/Hurt_3.png";


export const getWeightedMoves = (card: CardModel, moveList: Move[]): Move[] => {
  const weightedArray: Move[] = [];

  moveList.forEach((move: Move) => {
    let probability = move.probability;

    for (let i = 0; i < probability; i++) {
      weightedArray.push(move);
    }
  });

  return weightedArray;
};

export const logMessage = (
  message: ReactNode,
  onSetLog: (newLog: Log) => void,
  variant: LogVariant,
  timeWait = 0
) => {
  const newLog: Log = {
    value: message,
    variant,
  };

  setTimeout(() => {
    onSetLog(newLog);
  }, timeWait);
};

export const calcStartingPlayer = (p1: Player) => {
  if (p1.card.name === "Vinny") {
    return true;
  }

  return false;
};

export const getStartingLog = (): Log[] => {
  return [
    {
      value: "Game Started",
      variant: LogStrings.None,
    },
  ];
};

export const getChanceToBeat = (hp: number) => {
  let hpNum = hp * 2;
  if (hp > 120) {
    hpNum = 120;
  }

  //The higher it is, the less crits are in the game
  const critNum = Math.ceil((hpNum + 44) * 0.48) + 2;
  // debugger
  return critNum;
};

export const getCritChance = (hp: number) => {
  let critChance = 100 - getChanceToBeat(hp);
  if (critChance < 1) {
    critChance = 1;
  }
  return critChance;
};

export const getCritChanceText = (hp: number): string => {
  const chance = getCritChance(hp);

  if (chance > 60) {
    return "Super";
  } else if (chance > 40) {
    return "High";
  } else if (chance > 20) {
    return "Medium";
  } else return "Low";
};

export const calcRandomBg = (backgrounds: string[]) => {
  const bgs = [...backgrounds];
  const rand = getRandomInt(bgs.length);
  return bgs[rand];
};

export const getGramSpecialVal = () => {
  let randInt = getRandomInt(3) + 6;
  return randInt;
};

export const getVinnySpecialValue = () => {
  return -(getRandomInt(18) + 99);
};

export const calcLogStringTypeFromCard = (card: CardModel): LogVariant => {
  switch (card.name) {
    case "Vinny":
      return LogStrings.Vinny;
    case "Gram":
      return LogStrings.Gram;
    default:
      return LogStrings.None;
  }
};

export const calcMarkFromCard = (card: CardModel): string => {
  switch (card.name) {
    case "Vinny":
      return "vinny-mark";
    case "Gram":
      return "gram-mark";
    default:
      return "vinny-mark";
  }
};

export const calcEndPhrase = () => {
  let endPhrases = [...EndPhrases];
  const rand = getRandomInt(endPhrases.length);
  return endPhrases[rand];
};

export const defaultVinnyCard = (): CardModel => {
  const cardOne = {
    id: "GV-002",
    name: "Vinny",
    attack: 1,
    hp: 1,
    abilityId: 1,
    description:
      "Vinny likes to make funny videos. Gains extra health and power.",
    timesEaten: 0,
  };

  return cardOne;
};

export const defaultGramCard = (): CardModel => {
  const cardTwo = {
    id: "GV-002",
    name: "Gram",
    attack: 99,
    hp: 99,
    abilityId: 2,
    description: "Gram likes to shred the guitar. Can do massive damage.",
    timesEaten: 0,
  };

  return cardTwo;
};

export { gramCard, vinnyCard };

export const defaultPlayer1 = (): Player => {
  const pOne: Player = {
    name: "Player 1",
    card: defaultVinnyCard(),
    state: "idle",
    isBot: false,
    winTally: 0,
    timesEaten: 0,
  };

  return pOne;
};

export const defaultPlayer2 = (): Player => {
  const pOne: Player = {
    name: "Player 2",
    card: defaultGramCard(),
    state: StateStrings.Idle,
    isBot: false,
    winTally: 0,
    timesEaten: 0,
  };

  return pOne;
};

export const generateStory = () => {
  const rand = getRandomInt(Stories.length);
  return Stories[rand];
};

const vinnyIdleImages = [
  vinnyIdle1,
  vinnyIdle2,
  vinnyIdle3,
  vinnyIdle4,
  vinnyIdle3,
  vinnyIdle2,
  vinnyIdle1,
];

const vinnyThrowImages = [vinnyThrow1, vinnyThrow2, vinnyThrow3];

const vinnySpecialImages = [vinnySpecial1, vinnySpecial2, vinnySpecial3];

const vinnyLoseImages = [vinnyLose1, vinnyLose2, vinnyLose4];

const vinnyWinImages = [vinnyWin1, vinnyWin2, vinnyWin3];

const vinnyHealImages = [vinnyHeal1, vinnyHeal2, vinnyHeal3];

const vinnyHurtImages = [vinnyHurt1, vinnyHurt2, vinnyHurt4];

// Gram image arrays
const gramIdleImages = [
  GramIdle1,
  GramIdle2,
  GramIdle3,
  GramIdle4,
  GramIdle3,
  GramIdle2,
  GramIdle1,
];

const gramSpecialImages = [
  gramSpecial1,
  gramSpecial2,
  gramSpecial3,
  gramSpecial4,
  gramSpecial3,
  gramSpecial2,
  gramSpecial1,
];

const gramHurtImages = [
  gramHurt1,
  gramHurt2,
  gramHurt3,
  // gramHurt4,
  gramHurt3,
  gramHurt2,
  gramHurt1,
];

const gramWinImages = [
  // gramWin5,
  gramWin3,
  gramWin1,
  gramWin2,
  gramWin4,
  // gramWin6,
];

const gramHealImages = [
  gramHeal1,
  gramHeal2,
  gramHeal3,
  gramHeal4,
  gramHeal5,
  gramHeal3,
  gramHeal2,
  gramHeal1,
];

export const gramLoseImages = [
  gramLose1,
  gramLose2,
  gramLose3,
  gramLose4,
  gramLose5,
  gramLose6,
];


//Anime gram images
export const animeGramHealImages = [
  animeGramHeal1,
  animeGramHeal2,
  animeGramHeal3,
  animeGramHeal4,
  animeGramHeal5,
  animeGramHeal4,
  animeGramHeal3,
  animeGramHeal2,
  animeGramHeal1,
]

export const animeGramIdleImages = [
  animeGramIdle1,
  animeGramIdle2,
  animeGramIdle3,
  animeGramIdle4,
  animeGramIdle5,
  animeGramIdle4,
  animeGramIdle3,
  animeGramIdle2,
  animeGramIdle1,
]

export const animeGramHurtImages = [
  animeGramHurt2,
  animeGramHurt3,
  animeGramHurt2,
  animeGramHurt1,
]

export const animeGramThrowImages = [
  animeGramThrow1,
  animeGramThrow2,
  animeGramThrow3,
  // animeGramThrow4,
]

export const animeGramWinImages = [
  animeGramWin1,
  animeGramWin2,
  animeGramWin3,
  animeGramWin4,
  animeGramWin5,
]

export const animeGramLoseImages = [
  animeGramLose1,
  animeGramLose2,
  animeGramLose3,
  animeGramLose4,
]


export const animeGramSpecialImages = [
  animeGramSpecial1,
  animeGramSpecial2,
  animeGramSpecial3,
  animeGramSpecial4,
  animeGramSpecial3,
  animeGramSpecial2,
  animeGramSpecial1,
]



const gramThrowImages = [gramThrow3, gramThrow4];

export const calcVinnyImg = (state: string, animationIndex: number) => {
  switch (state) {
    case StateStrings.Idle:
      return vinnyIdleImages[animationIndex % vinnyIdleImages.length];
    case StateStrings.Throw:
      return vinnyThrowImages[
        animationIndex >= vinnyThrowImages.length
          ? vinnyThrowImages.length - 1
          : animationIndex
      ];
    case StateStrings.Hurt:
      return vinnyHurtImages[
        animationIndex >= vinnyHurtImages.length
          ? vinnyHurtImages.length - 1
          : animationIndex
      ];
    case StateStrings.Win:
      return vinnyWinImages[
        animationIndex >= vinnyWinImages.length
          ? vinnyWinImages.length - 1
          : animationIndex
      ];
    case StateStrings.Lose:
      return vinnyLoseImages[
        animationIndex >= vinnyLoseImages.length
          ? vinnyLoseImages.length - 1
          : animationIndex
      ];
    case StateStrings.Heal:
      return vinnyHealImages[
        animationIndex >= vinnyHealImages.length
          ? vinnyHealImages.length - 1
          : animationIndex
      ];
    case StateStrings.Special:
      return vinnySpecialImages[animationIndex % vinnySpecialImages.length];
    }
};

export const calcGramImg = (state: string, animationIndex: number) => {
  switch (state) {
    case StateStrings.Idle:
      return gramIdleImages[animationIndex % gramIdleImages.length];
    case StateStrings.Throw:
      return gramThrowImages[
        animationIndex >= gramThrowImages.length
          ? gramThrowImages.length - 1
          : animationIndex
      ];
    case StateStrings.Hurt:
      return gramHurtImages[
        animationIndex >= gramHurtImages.length
          ? gramHurtImages.length - 1
          : animationIndex
      ];
    case StateStrings.Win:
      return gramWinImages[
        animationIndex >= gramWinImages.length
          ? gramWinImages.length - 1
          : animationIndex
      ];
    case StateStrings.Lose:
      return gramLoseImages[
        animationIndex >= gramLoseImages.length
          ? gramLoseImages.length - 1
          : animationIndex
      ];
    case StateStrings.Heal:
      return gramHealImages[
        animationIndex >= gramHealImages.length
          ? gramHealImages.length - 1
          : animationIndex
      ];
    case StateStrings.Special:
      return gramSpecialImages[animationIndex % gramSpecialImages.length];
  }
};


export const calcAnimeGramImg = (state: string, animationIndex: number) => {
  switch (state) {
    case StateStrings.Idle:
      return animeGramIdleImages[animationIndex % animeGramIdleImages.length];
    case StateStrings.Throw:
      return animeGramThrowImages[
        animationIndex >= animeGramThrowImages.length
          ? animeGramThrowImages.length - 1
          : animationIndex
      ];
    case StateStrings.Hurt:
      return animeGramHurtImages[
        animationIndex >= animeGramHurtImages.length
          ? animeGramHurtImages.length - 1
          : animationIndex
      ];
    case StateStrings.Win:
      return animeGramWinImages[
        animationIndex >= animeGramWinImages.length
          ? animeGramWinImages.length - 1
          : animationIndex
      ];
    case StateStrings.Lose:
      return animeGramLoseImages[
        animationIndex >= animeGramLoseImages.length
          ? animeGramLoseImages.length - 1
          : animationIndex
      ];
    case StateStrings.Heal:
      return animeGramHealImages[
        animationIndex >= animeGramHealImages.length
          ? animeGramHealImages.length - 1
          : animationIndex
      ];
    case StateStrings.Special:
      return animeGramSpecialImages[animationIndex % animeGramSpecialImages.length];
  }
};
