import { useEffect, useState } from 'react';
import '../../../Css/tcg/RisingText.css'
import critImg from '../../../../img/tcg/critmiss/Crit.png'
import missImg from '../../../../img/tcg/critmiss/Miss.png'
import extraImg from '../../../../img/tcg/critmiss/Extra.png'
import stillAliveImg from '../../../../img/tcg/critmiss/StillAlive.png'

export interface RisingTextProps {
    value: string | undefined;
    isIcon?: boolean;
}

const RisingText = ({ value, isIcon = false }: RisingTextProps) => {
  const [animationName, setAnimationName] = useState<string>('slideUp');

  useEffect(() => {
    setAnimationName('slideUp');
  }, [value, isIcon])


  useEffect(() => {
    if (animationName === "slideUp") {
      setTimeout(() => {
        setTimeout(() => {
          setAnimationName('none');
        }, 1000)
      }, 150)
    }
  }, [animationName])

  const calcIcon = (imgIcon: string | undefined) => {
    switch (imgIcon) {
      case "crit":
        return critImg;
      case "miss":
        return missImg;
      case "extra":
        return extraImg;
      case "stillAlive":
        return stillAliveImg;
      default:
        return "";
    }
  }

  const calcColor = () => {
    if (value && value.includes("+")) {
      return 'rgb(0, 228, 107)';
    } else {
      return 'rgba(236, 55, 55, 0.936)'
    }
  }

  return (
    <div className='rising-text' style={{ animationName, display: value?.length ? 'inherit' : 'none' }}>
      {isIcon ? 
        <img src={calcIcon(value)} alt={'Monkey Icon'} style={{ 
          width: '90px',
          paddingTop: '34px',
          paddingLeft: '72px',
          zIndex: 1,  
        }}>
        </img> : 
        <h1 style={{ color: calcColor() }}>
          {value}
        </h1>
      }
    </div>
  );
}

export default RisingText;
